import { LayoutGrid } from "lucide-react";

import { Button } from "@/components/ui/button";

type Props = {
	onClick: () => void;
};

export const TableGridViewSwitcher = ({ onClick }: Props) => {
	return (
		<Button id="table-grid-switcher" onClick={onClick} variant="ghost" size="icon">
			<LayoutGrid color="#696666" />
		</Button>
	);
};

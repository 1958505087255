import { GridItem } from "@/components/requests/GridItem";
import { RowItem } from "@/components/requests/RowItem";
import { type UserRequest } from "@/types/UserRequest";

type Props = {
	data: UserRequest[] | undefined;
	viewType: "row" | "grid";
};

export const Requests = ({ data, viewType }: Props) => {
	return (
		<div className="w-full">
			{data && data.length > 0 && (
				<div
					className={
						viewType === "grid"
							? "grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
							: "grid grid-cols-1 gap-4"
					}
				>
					{viewType === "grid" &&
						data.map((request, index) => {
							return (
								<GridItem key={`req-grid-${request.id}`} request={request} index={index + 1} />
							);
						})}
					{viewType === "row" &&
						data.map((request, index) => {
							return <RowItem key={`req-row-${request.id}`} request={request} index={index + 1} />;
						})}
				</div>
			)}
			{(!data || data.length < 1) && (
				<div className="rounded-sm bg-slate-100 p-6 text-center">No requests</div>
			)}
		</div>
	);
};

import { apiSlice } from "@/app/api/apiSlice";

export const suggestionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPublicSuggestions: builder.query({
			query: () => `/api/suggestions/public`,
		}),
		getPublishedSuggestions: builder.query({
			query: () => `/api/suggestions/published`,
			providesTags: ["ManagerSuggestions"],
		}),
		addSuggestion: builder.mutation({
			query: ({ data }) => ({
				url: `/api/suggestions`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["ManagerSuggestions"]),
		}),
		upvoteSuggestion: builder.mutation({
			query: ({ suggestionId }) => ({
				url: `/api/suggestions/${suggestionId}/upvote`,
				method: "POST",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["ManagerSuggestions"]),
		}),
		downvoteSuggestion: builder.mutation({
			query: ({ suggestionId }) => ({
				url: `/api/suggestions/${suggestionId}/downvote`,
				method: "POST",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["ManagerSuggestions"]),
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetPublicSuggestionsQuery,
	useGetPublishedSuggestionsQuery,
	useAddSuggestionMutation,
	useUpvoteSuggestionMutation,
	useDownvoteSuggestionMutation,
} = suggestionsApiSlice;

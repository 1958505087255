import { useGetMagicLinksQuery } from "@/app/api/slices/admin/magicLinksApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { MagicLinkCreateDialog } from "@/components/dialogs/MagicLinkCreateDialog";
import { MagicLinksTable } from "@/components/tables/MagicLinksTable";

export const MagicLinksPage = () => {
	const { data: response, isLoading, isFetching } = useGetMagicLinksQuery({});

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<WhiteBox>
			<MagicLinkCreateDialog />
			<div className="mt-4">
				<MagicLinksTable data={response?.data} />
			</div>
		</WhiteBox>
	);
};

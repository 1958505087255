import {
	useDownvoteSuggestionMutation,
	useUpvoteSuggestionMutation,
} from "@/app/api/slices/manager/managerSuggestionsApiSlice";
import { SuggestionDownvoteButton } from "@/components/SuggestionDownvoteButton";
import { SuggestionUpvoteButton } from "@/components/SuggestionUpvoteButton";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	suggestionId: number;
};

export const SuggestionVoteButtons = ({ suggestionId }: Props) => {
	const { toast } = useToast();
	const [sendUpvoteMutation, { isLoading: isSendingUpvote }] = useUpvoteSuggestionMutation();
	const [sendDownvoteMutation, { isLoading: isSendingDownvote }] = useDownvoteSuggestionMutation();

	const handleUpvote = async () => {
		await sendUpvoteMutation({ suggestionId, type: "up" })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Successfully upvoted suggestion.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	const handleDownvote = async () => {
		await sendDownvoteMutation({ suggestionId, type: "down" })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "destructive",
					title: "Successfully downvoted suggestion.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<div className="flex justify-center gap-2">
			<SuggestionUpvoteButton
				onClick={handleUpvote}
				isLoading={isSendingUpvote}
				disabled={isSendingDownvote}
			/>
			<SuggestionDownvoteButton
				onClick={handleDownvote}
				isLoading={isSendingDownvote}
				disabled={isSendingUpvote}
			/>
		</div>
	);
};

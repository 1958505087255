import { Button } from "@/components/ui/button";
import { Check, Loader } from "lucide-react";

type Props = {
	onClick: () => void;
	isLoading: boolean;
	disabled: boolean;
};

export const SuggestionUpvoteButton = ({ onClick, isLoading, disabled }: Props) => {
	return (
		<Button
			onClick={onClick}
			variant="outline"
			size="icon"
			style={{ background: "#4CAF50" }}
			disabled={disabled}
		>
			{isLoading ? (
				<Loader color="#fff" className="animate-spin duration-2000" />
			) : (
				<Check color="#fff" />
			)}
		</Button>
	);
};

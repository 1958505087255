import { useAddSuggestionMutation } from "@/app/api/slices/manager/managerSuggestionsApiSlice";
import { SuggestionCreateForm } from "@/components/forms/SuggestionCreateForm";
import { type FormType } from "@/components/forms/SuggestionCreateForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogOverlay,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { BadgeCheck } from "lucide-react";
import { useState } from "react";

export const AddSuggestionDialog = () => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addSuggestionMutation, { isLoading }] = useAddSuggestionMutation();

	const [openNotification, setOpenNotification] = useState(false);

	const handleAddSuggestion = async (data: FormType) => {
		await addSuggestionMutation({ data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added suggestion. It'll be visible once approved as public.",
				});
				setOpenNotification(true);
				setTimeout(() => {
					setOpenNotification(false);
				}, 3500);
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<>
			<Dialog open={open} onOpenChange={setOpen}>
				<DialogTrigger asChild>
					<Button id="add-suggestion-button" className="uppercase">
						Add suggestion
					</Button>
				</DialogTrigger>
				<DialogContent className="sm:max-w-[500px] md:max-w-[750px]">
					<DialogHeader>
						<DialogTitle>Add Suggestion</DialogTitle>
					</DialogHeader>
					<SuggestionCreateForm onSubmit={handleAddSuggestion} isLoading={isLoading} />
				</DialogContent>
			</Dialog>
			<Dialog open={openNotification} onOpenChange={setOpenNotification}>
				<DialogOverlay className="bg-green-500/40" />
				<DialogContent className="flex items-start justify-start sm:max-w-[500px]">
					<BadgeCheck color="green" />
					<div>
						<DialogHeader>
							<DialogTitle>Successfully added suggestion.</DialogTitle>
						</DialogHeader>
						<div className="mt-2">It&apos;ll be visible once approved as public.</div>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

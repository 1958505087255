import { apiSlice } from "@/app/api/apiSlice";

export const userProfileApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCurrentUserProfile: builder.query({
			query: () => `/api/profile`,
		}),
		updatePassword: builder.mutation({
			query: ({ currentPassword, password, confirmPassword }) => ({
				url: "/api/profile/change-password",
				method: "POST",
				body: { currentPassword, password, confirmPassword },
			}),
		}),
		updateEmailAndPhone: builder.mutation({
			query: ({ email, phone }) => ({
				url: "/api/profile",
				method: "PUT",
				body: { email, phone },
			}),
		}),
		requestAccountRemove: builder.mutation({
			query: () => ({
				url: "/api/profile/request-remove",
				method: "POST",
			}),
		}),
	}),

	overrideExisting: true,
});

export const {
	useGetCurrentUserProfileQuery,
	useUpdatePasswordMutation,
	useUpdateEmailAndPhoneMutation,
	useRequestAccountRemoveMutation,
} = userProfileApiSlice;

import { apiSlice } from "@/app/api/apiSlice";

// tags: AvailablePositions, PositionData
export const positionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDepartmentPositions: builder.query({
			query: ({
				vessel_id,
				department_id,
			}: {
				vessel_id: undefined | numOrStr;
				department_id: undefined | numOrStr;
			}) => `/api/vessels/${vessel_id}/departments/${department_id}/positions`,
			providesTags: (_result, _error, { vessel_id, department_id }) => [
				{ type: "AvailablePositions", id: `${vessel_id}${department_id}` },
			],
		}),
		getPositionData: builder.query({
			query: ({
				vessel_id,
				department_id,
				position_id,
				contract_id,
				page,
				rotations_count,
			}: {
				vessel_id: undefined | numOrStr;
				department_id: undefined | numOrStr;
				position_id: undefined | numOrStr;
				contract_id: undefined | numOrStr;
				page: undefined | number;
				rotations_count: undefined | number;
			}) =>
				`/api/vessels/${vessel_id}/departments/${department_id}/positions/${position_id}/contracts/${contract_id}?page=${page}&rotations_count=${rotations_count}`,
			providesTags: (
				_result,
				_error,
				{ department_id, position_id, contract_id, page, rotations_count },
			) => [
				{
					type: "PositionData",
					id: `${department_id}${position_id}${contract_id}${page}${rotations_count}`,
				}
			],
		}),
		getCrewMemberStatistics: builder.query({
			query: ({
				vessel_id,
				user_id,
				startDate,
				endDate,
			}: {
				vessel_id: undefined | numOrStr;
				user_id: undefined | numOrStr;
				startDate: undefined | numOrStr;
				endDate: undefined | numOrStr;
			}) =>
				`/api/vessels/${vessel_id}/users/${user_id}/statistics?start_date=${startDate}&end_date=${endDate}`,
			providesTags: (_result, _error, { user_id }) => [
				{ type: "CrewMemberStatistics", id: user_id },
				"LeaveBalance"
			],
		}),

		editCrewMemberCalendar: builder.mutation({
			query: ({
				vessel_id,
				department_id,
				position_id,
				contract_id,
				data,
			}: EditCrewMemberCalendarArgs) => ({
				url: `/api/vessels/${vessel_id}/departments/${department_id}/positions/${position_id}/contracts/${contract_id}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"PositionData",
							"RotationCalendar",
							"RotationContracts",
							"Users",
							"MyVesselInfo",
							"LeaveBalance",
						],
		}),
		editChangeDay: builder.mutation({
			query: ({ vessel_id, department_id, position_id, data }: EditChangeDayArgs) => ({
				url: `/api/vessels/${vessel_id}/departments/${department_id}/positions/${position_id}/change-day`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error
					? []
					: [
							"PositionData",
							"RotationCalendar",
							"RotationContracts",
							"Users",
							"MyVesselInfo",
							"LeaveBalance",
						],
		}),
	}),
	overrideExisting: true,
});

type numOrStr = number | string;
interface EditCrewMemberCalendarArgs {
	vessel_id: undefined | numOrStr;
	department_id: undefined | numOrStr;
	position_id: undefined | numOrStr;
	contract_id: undefined | numOrStr;
	data: {
		id: undefined | numOrStr;
		start_date: string;
		end_date: string;
		type: string;
		user_id: string;
	};
}

interface EditChangeDayArgs {
	vessel_id: undefined | numOrStr;
	department_id: undefined | numOrStr;
	position_id: undefined | numOrStr;
	data: {
		id: undefined | numOrStr;
		from: string;
		to: string;
	};
}

export const {
	useGetDepartmentPositionsQuery,
	useGetPositionDataQuery,
	useGetCrewMemberStatisticsQuery,
	useEditCrewMemberCalendarMutation,
	useEditChangeDayMutation,
} = positionsApiSlice;

import { type ColumnDef } from "@tanstack/react-table";

import { ApproveSuggestionDialog } from "@/components/dialogs/ApproveSuggestionDialog";
import { ArchiveSuggestionDialog } from "@/components/dialogs/ArchiveSuggestionDialog";
import { DeclineSuggestionDialog } from "@/components/dialogs/DeclineSuggestionDialog";
import { RemoveSuggestionDialog } from "@/components/dialogs/RemoveSuggestionDialog";
import { RestoreSuggestionDialog } from "@/components/dialogs/RestoreSuggestionDialog";
import {
	type Suggestion,
	type SuggestionReporter,
	type SuggestionVotes,
} from "@/types/Suggestions";
import { type Vessel } from "@/types/Vessel";
import { formatDate } from "@/utils/formatDate";

export const columns: ColumnDef<Suggestion>[] = [
	{
		accessorKey: "vessel",
		header: "Vessel",
		cell: ({ row }) => {
			const vessel: Vessel = row.getValue("vessel");

			return <div>{vessel.name}</div>;
		},
	},
	{
		accessorKey: "manager",
		header: "Manager",
		cell: ({ row }) => {
			const manager: SuggestionReporter = row.getValue("manager");
			const firstName = manager.firstName;
			const lastName = manager.lastName;

			return <div>{firstName + " " + lastName}</div>;
		},
	},
	{
		accessorKey: "title",
		header: "Title",
		cell: ({ row }) => <div>{row.getValue("title")}</div>,
	},
	{
		accessorKey: "description",
		header: "Description",
		cell: ({ row }) => <div>{row.getValue("description")}</div>,
	},
	{
		accessorKey: "status",
		header: "Status",
		cell: ({ row }) => <div>{row.getValue("status")}</div>,
	},
	{
		accessorKey: "visibility",
		header: "Visibility",
		cell: ({ row }) => <div>{row.getValue("visibility") || "-"}</div>,
	},
	{
		accessorKey: "votes",
		header: "Total Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.total || "-"}</div>;
		},
	},
	{
		accessorKey: "votes",
		header: "Up Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.up || "-"}</div>;
		},
	},
	{
		accessorKey: "votes",
		header: "Down Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.down || "-"}</div>;
		},
	},
	{
		accessorKey: "createdAt",
		header: "Created At",
		cell: ({ row }) => <div>{formatDate(row.getValue("createdAt"))}</div>,
	},
	{
		id: "actions",
		header: () => <div>Actions</div>,
		cell: ({ row }) => {
			const status = row.original.status;
			const manager = row.original.manager;
			const title = row.original.title;
			const description = row.original.description;
			const suggestionId = row.original.id;

			return (
				<div className="flex justify-center gap-2">
					{status === "pending" && (
						<>
							<ApproveSuggestionDialog
								suggestionId={suggestionId}
								reporter={manager}
								suggestion={{ title, description }}
							/>
							<DeclineSuggestionDialog
								suggestionId={suggestionId}
								reporter={manager}
								suggestion={{ title, description }}
							/>
						</>
					)}
					{(status === "approved" || status === "declined") && (
						<ArchiveSuggestionDialog
							suggestionId={suggestionId}
							reporter={manager}
							suggestion={{ title, description }}
						/>
					)}
					{status === "archived" && (
						<>
							<RestoreSuggestionDialog
								suggestionId={suggestionId}
								reporter={manager}
								suggestion={{ title, description }}
							/>
							<RemoveSuggestionDialog
								suggestionId={suggestionId}
								reporter={manager}
								suggestion={{ title, description }}
							/>
						</>
					)}
				</div>
			);
		},
	},
];

import { EditCrewMemberDialog } from "@/components/dialogs/EditCrewMemberDialog";
import { RemoveCrewMemberDialog } from "@/components/dialogs/RemoveCrewMemberDialog";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/useAuth";
import { type User } from "@/types/User";
import { Calendar, File, PersonStanding } from "lucide-react";
import { Link } from "react-router-dom";

type Props = {
	crewMember: User;
};

export const GridItem = ({ crewMember }: Props) => {
	const {
		id,
		firstName,
		lastName,
		email,
		phone,
		rotation,
		vessel,
		department,
		position,
		homeAirport,
		isOnboard,
		isDemoAccount,
	} = crewMember;

	const auth = useAuth();
	const currentUserId = auth?.user?.id as number;
	const isOwnAccount = currentUserId === id;

	return (
		<div>
			<h3 className="text-xl font-semibold">{`${firstName} ${lastName}`}</h3>
			<div className="mb-2 border-t border-black"></div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Contact</span>
				<div className="flex flex-col lowercase">
					<a className="truncate" href={`mailto:${email}`}>
						{email}
					</a>
					<a className="truncate" href={`tel:+${phone}`}>
						{phone}
					</a>
				</div>
			</div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Rotation</span>
				<span>{rotation}</span>
			</div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Department</span>
				<span>{department.name}</span>
			</div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Position</span>
				<span>{position.name}</span>
			</div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Home Airport</span>
				<span>{homeAirport}</span>
			</div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Onboard</span>
				<div>
					<div className={`inline-block rounded-sm ${isOnboard ? "bg-green-600" : "bg-red-600"}`}>
						<PersonStanding color="#fff" />
					</div>
				</div>
			</div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Partner</span>
				<span>TO-DO</span>
			</div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Actions</span>
				<div className="flex gap-2">
					<EditCrewMemberDialog userId={id} user={crewMember} />

					<Button variant="outline" size="icon" asChild style={{ background: "#FB8500" }}>
						<Link to={`/crew-members/${id}/rotation-calendar`}>
							<Calendar color="#fff" />
						</Link>
					</Button>

					<RemoveCrewMemberDialog userId={id} disabled={isDemoAccount || isOwnAccount} />

					<Button variant="outline" size="icon" asChild style={{ background: "#8ECAE6" }}>
						<Link to={`/vessel/${vessel.id}/crew-members/${id}/documents`}>
							<File color="#fff" />
						</Link>
					</Button>
				</div>
			</div>
		</div>
	);
};

import { CreditCard, GraduationCap, Lightbulb, Link, Settings, Ship } from "lucide-react";

export const adminMenu = [
	{
		id: "menu-vessels",
		title: "Vessels",
		subtitle: null,
		icon: <Ship />,
		link: "/vessels",
	},
	{
		id: "menu-subscriptions",
		title: "Payments",
		subtitle: null,
		icon: <CreditCard />,
		link: "/subscriptions",
	},
	{
		id: "menu-settings",
		title: "Settings",
		subtitle: null,
		icon: <Settings />,
		link: "/settings",
	},
	{
		id: "menu-knowledge-base",
		title: "Knowledge Base",
		subtitle: null,
		icon: <GraduationCap />,
		link: "/knowledge-base",
	},
	{
		id: "menu-suggestions",
		title: "Suggestions",
		subtitle: null,
		icon: <Lightbulb />,
		link: "/suggestions",
	},
	{
		id: "menu-magic-links",
		title: "DEMO Magic Links",
		subtitle: null,
		icon: <Link />,
		link: "/magic-links",
	},
];

import Joyride, { ACTIONS, type CallBackProps, type Step } from "react-joyride";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { useGetCrewMembersQuery } from "@/app/api/slices/crewMembersApiSlice";
import { useJoyride } from "@/contexts/JoyrideContext";
import { crewMembersListingSteps } from "@/data/joyride/crew-members-listing";
import { knowledgeBaseAddSteps, knowledgeBaseSteps } from "@/data/joyride/knowledge-base";
import { menuSteps } from "@/data/joyride/menu";
import { myVesselSteps } from "@/data/joyride/my-vessel";
import { notificationsSteps } from "@/data/joyride/notifications";
import { positionsSteps } from "@/data/joyride/positions";
import { rotationCreatorSteps } from "@/data/joyride/rotation-creator";
import { suggestionsSteps } from "@/data/joyride/suggestions";
import { userDocsSteps } from "@/data/joyride/user-docs";
import { userProfileSteps } from "@/data/joyride/user-profile";
import { useAuth } from "@/hooks/useAuth";

const steps = [
	// 0 - MAIN
	{
		target: "body",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Welcome in MyRotat tour guide</h1>
				<p>We will walk you through everything step by step.</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	...menuSteps,
	...myVesselSteps,
	...positionsSteps,
	...crewMembersListingSteps,
	...rotationCreatorSteps,
	...userDocsSteps,
	...userProfileSteps,
	...knowledgeBaseSteps,
	...knowledgeBaseAddSteps,
	...suggestionsSteps,
	...notificationsSteps,
];

export const joyride_redirect_1_index = 1 + menuSteps.length + myVesselSteps.length;
export const joyride_redirect_2_index = joyride_redirect_1_index + positionsSteps.length;
export const joyride_redirect_3_index = joyride_redirect_2_index + crewMembersListingSteps.length;
export const joyride_redirect_4_index = joyride_redirect_3_index + rotationCreatorSteps.length;
export const joyride_redirect_5_index = joyride_redirect_4_index + userDocsSteps.length;
export const joyride_redirect_6_index = joyride_redirect_5_index + userProfileSteps.length;
export const joyride_redirect_7_index = joyride_redirect_6_index + knowledgeBaseSteps.length;
export const joyride_redirect_8_index = joyride_redirect_7_index + knowledgeBaseAddSteps.length;
export const joyride_redirect_9_index = joyride_redirect_8_index + suggestionsSteps.length;

export const JoyrideWrapper = () => {
	const { run, startJoyride, stopJoyride, stepIndex, setStepIndex, setIsJoyrideActive } =
		useJoyride();
	const navigate = useNavigate();
	const location = useLocation();

	// AUTH NEEDED FOR STEP 25 REDIRECT
	const auth = useAuth();
	const params = useParams();
	const vesselId = auth?.user?.vessel?.id || params.vesselId;

	const { data: crewMembersResponse } = useGetCrewMembersQuery({ vesselId });

	const defaultOptions = {
		arrowColor: "rgba(251, 133, 0, 1)",
		backgroundColor: "#fff",
		beaconSize: 36,
		overlayColor: "rgba(0, 0, 0, 0.5)",
		primaryColor: "rgba(251, 133, 0, 1)",
		spotlightShadow: "0 0 15px rgba(251, 133, 0, 0.5)",
		textColor: "#333",
		width: "auto",
		zIndex: 1500,
	};

	const callback = (data: CallBackProps) => {
		const { action, index, status, type } = data;

		if (run && index === 0 && location.pathname !== "/") {
			stopJoyride();
			navigate("/");
			setTimeout(() => {
				startJoyride();
			}, 300);
		}

		if (status === "skipped" || status === "finished" || action === "close") {
			stopJoyride();
			setStepIndex(0);
		} else if (type === "step:after" || type === "error:target_not_found") {
			const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
			console.log("nextStepIndex", nextStepIndex);

			setStepIndex(nextStepIndex);

			// prev steps + notifications steps
			if (nextStepIndex === joyride_redirect_1_index) {
				stopJoyride();
				navigate("/positions");
			}

			// init step + menu steps + my vessel steps
			if (nextStepIndex === joyride_redirect_2_index) {
				stopJoyride();
				navigate("/crew-members");
			}

			// prev steps + crew members listing steps
			if (nextStepIndex === joyride_redirect_3_index) {
				stopJoyride();
				navigate(`/crew-members/${crewMembersResponse.data[0].id}/rotation-calendar`);
			}

			// prev steps + rotation creator steps
			if (nextStepIndex === joyride_redirect_4_index) {
				stopJoyride();
				navigate(`/crew-members/${crewMembersResponse.data[0].id}/documents`);
			}

			// prev steps + user docs steps
			if (nextStepIndex === joyride_redirect_5_index) {
				stopJoyride();
				navigate("/settings");
			}

			// prev steps + user profile steps
			if (nextStepIndex === joyride_redirect_6_index) {
				stopJoyride();
				navigate("/knowledge-base");
			}

			// prev steps + knowledge base steps
			if (nextStepIndex === joyride_redirect_7_index) {
				stopJoyride();
				navigate("/knowledge-base/add");
			}

			// prev steps + knowledge base add steps
			if (nextStepIndex === joyride_redirect_8_index) {
				stopJoyride();
				navigate("/suggestions");
			}

			// prev steps + suggestions steps
			if (nextStepIndex === joyride_redirect_9_index) {
				stopJoyride();
				navigate("/notifications");
			}

			// console.log("myVesselSteps", {
			// 	joyride_redirect_1_index,
			// 	joyride_redirect_2_index,
			// 	joyride_redirect_3_index,
			// 	joyride_redirect_4_index,
			// 	joyride_redirect_5_index,
			// 	joyride_redirect_6_index,
			// 	joyride_redirect_7_index,
			// 	joyride_redirect_8_index,
			// 	joyride_redirect_9_index,
			// });
		}

		if (type === "tour:end") {
			setIsJoyrideActive(false);
		}
	};

	return (
		<>
			<Outlet />
			<Joyride
				callback={callback}
				steps={steps as Step[]}
				run={run}
				stepIndex={stepIndex}
				continuous
				scrollToFirstStep
				disableOverlayClose
				hideBackButton
				disableCloseOnEsc
				// hideCloseButton
				locale={{
					last: "Finish",
				}}
				styles={{
					options: defaultOptions,
					tooltipContent: {
						padding: "10px 10px 0 10px",
					},
					spotlight: {
						border: `2px solid rgba(251, 133, 0, 1)`,
						// boxShadow: "0px 0px 20px 10px rgba(251, 133, 0, 0.4)",
					},
					tooltip: {
						border: `2px solid rgba(251, 133, 0, 1)`,
					},
					buttonBack: {
						marginLeft: 0,
						marginRight: 5,
					},
				}}
			/>
		</>
	);
};

import { type ColumnDef } from "@tanstack/react-table";
import { Pen } from "lucide-react";

import { RemoveKnowledgeBaseArticleDialog } from "@/components/dialogs/RemoveKnowledgeBaseArticleDialog";
import { Button } from "@/components/ui/button";
import { type Article } from "@/types/KnowledgeBase";
import { Link } from "react-router-dom";

export const columns: ColumnDef<Article>[] = [
	{
		accessorKey: "title",
		header: "Article Title",
		cell: ({ row }) => <div>{row.getValue("title")}</div>,
	},
	{
		accessorKey: "section",
		header: "Section",
		cell: ({ row }) => {
			const section: { name: string; id: number } = row.getValue("section");
			return <div>{section.name}</div>;
		},
	},
	{
		accessorKey: "isPublished",
		header: "Is Published?",
		cell: ({ row }) => {
			const isPublished = row.original.isPublished ? "Yes" : "No";

			return <div>{isPublished}</div>;
		},
	},
	{
		accessorKey: "description",
		header: "Description",
		cell: ({ row }) => (
			<div>
				<span className="block max-w-56 truncate">{row.getValue("description")}</span>
			</div>
		),
	},
	{
		id: "actions",
		header: "Actions",
		cell: ({ row }) => {
			const articleId = row.original.id;

			return (
				<div className="flex justify-center gap-2">
					<Button variant="outline" size="icon" style={{ background: "#2055A3" }} asChild>
						<Link to={`/knowledge-base/edit/${articleId}`}>
							<Pen color="#fff" />
						</Link>
					</Button>
					<RemoveKnowledgeBaseArticleDialog articleId={articleId} />
				</div>
			);
		},
	},
];

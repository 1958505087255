import { Button } from "@/components/ui/button";
import { Check, X } from "lucide-react";

export const suggestionsSteps = [
	// 63 - SUGGESTIONS
	{
		target: "#manager-suggestions-page",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Welcome to our Suggestions Page!</h1>
				<p>Here, you can share your innovative ideas or improvements.</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 64 - SUGGESTIONS
	{
		target: "#add-suggestion-button",
		content: (
			<div>
				<p>Press this button to add new suggestion.</p>
				<p>We will review it and contact you asap.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 65 - SUGGESTIONS
	{
		target: "#suggestions-actions-header",
		content: (
			<div>
				<p>
					As a manager you can vote for public suggestion, but remember you have only one vote for
					each.
				</p>
				<div className="mt-2 flex items-center gap-2">
					<Button
						variant="outline"
						size="icon"
						style={{ background: "#4CAF50" }}
						className="cursor-default"
					>
						<Check color="#fff" />
					</Button>
					<span> &mdash; Upvote</span>
				</div>
				<div className="mt-2 flex items-center gap-2">
					<Button
						variant="outline"
						size="icon"
						style={{ background: "#F44336" }}
						className="cursor-default"
					>
						<X color="#fff" />
					</Button>
					<span> &mdash; Downvote</span>
				</div>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 66 - SUGGESTIONS - NOTIFICATIONS BUTTON
	{
		target: "#notifications-button",
		content: (
			<div>
				<p>You can click here to see notifications.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
];

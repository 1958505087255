import { ChevronsUpDown } from "lucide-react";
import { useState } from "react";

import { EditKnowledgeBaseSectionDialog } from "@/components/dialogs/EditKnowledgeBaseSectionDialog";
import { RemoveKnowledgeBaseSectionDialog } from "@/components/dialogs/RemoveKnowledgeBaseSectionDialog";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { type OpenStates } from "@/types/AccordionOpenStates";
import { type Section } from "@/types/KnowledgeBase";

type Props = { sections: Section[] };

export const KnowledgeBaseSectionsAccordion = ({ sections }: Props) => {
	const [openStates, setOpenStates] = useState<OpenStates>({});

	const toggleOpenState = (sectionId: number) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[sectionId]: !prevStates[sectionId],
		}));
	};

	return (
		<div className="flex flex-col gap-3">
			{sections.map((section) => {
				return (
					<Collapsible
						key={section.id}
						open={openStates[section.id] || false}
						onOpenChange={() => toggleOpenState(section.id)}
						className="space-y-2"
					>
						<div className="flex w-full items-center justify-between space-x-4">
							{section.articles.length > 0 ? (
								<CollapsibleTrigger asChild>
									<Button
										variant="ghost"
										size="sm"
										className="flex w-full items-center justify-between"
										style={{ width: "calc(100% - 100px)" }}
									>
										<h4 className="w-[88%] truncate text-left text-sm font-semibold">
											{section.name}
										</h4>
										<ChevronsUpDown className="h-4 w-4" />
										<span className="sr-only">Toggle</span>
									</Button>
								</CollapsibleTrigger>
							) : (
								<div
									className="flex items-center justify-between px-3"
									style={{ width: "calc(100% - 100px)" }}
								>
									<h4 className="truncate text-sm font-semibold">{section.name}</h4>
								</div>
							)}
							<div className="flex items-center gap-1">
								<EditKnowledgeBaseSectionDialog section={section} />
								<RemoveKnowledgeBaseSectionDialog sectionId={section.id} />
							</div>
						</div>
						<CollapsibleContent className="space-y-2">
							{section.articles.length > 0 &&
								section.articles.map((article) => {
									return (
										<div
											key={article.id}
											className="flex items-center justify-between rounded-md border px-4 py-3 font-mono text-sm"
										>
											<span className="truncate">{article.title}</span>
										</div>
									);
								})}
						</CollapsibleContent>
					</Collapsible>
				);
			})}
		</div>
	);
};

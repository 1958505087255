import { ChevronsUpDown } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

import rotationCreatorChangeDayType from "@/assets/joyride/rotation-creator-change-day-type-v2.png";

export const rotationCreatorSteps = [
	// 37 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Welcome to the rotation generator.</h1>
				<p>Create schedule for you crew member with few simple mouse clicks.</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 38 - ROTATION CREATOR
	{
		target: "#rotation-creator-add-contract-button",
		content: (
			<div>
				<p>Click the spotlight area to create a new contract.</p>
				<p>If the button is disabled simply go to the next step.</p>
			</div>
		),
		disableBeacon: true,
		disableOverlayClose: true,
		hideCloseButton: true,
		// hideFooter: true,
		spotlightClicks: true,
		placement: "auto",
		styles: {
			tooltipContent: {
				padding: "10px",
			},
			spotlight: {
				boxShadow: "0px 0px 20px 10px rgba(251, 133, 0, 0.4)",
			},
		},
	},
	// 39 - ROTATION CREATOR
	{
		target: "#rotation-creator-start-date-picker",
		content: (
			<div>
				<p>Choose start day.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 40 - ROTATION CREATOR
	{
		target: "#rotation-creator-manual-end-date-checkbox",
		content: (
			<div>
				<p>
					Tick the box if you want to generate rotation for the year ahead. Calendar will be
					extended automatically.
				</p>
				<p>If not, leave the box and schedule will be generated for a specific time frame.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 41 - ROTATION CREATOR
	{
		target: "#rotation-creator-rotation-select",
		content: (
			<div>
				<p>Choose rotation type.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 42 - ROTATION CREATOR
	{
		target: "#rotation-creator-partner-select",
		content: (
			<div>
				<p>If crew member has rotational partner, you can choose it here.</p>
				<p>Calendars will be generated for him as well.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 43 - ROTATION CREATOR
	{
		target: "#rotation-creator-save-button",
		content: (
			<div>
				<p>Click save to save settings.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 44 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<p>
					Once rotation is added you&apos;ll be able to expand the calendar to see all weeks in the
					rotation.
				</p>
				<div className="mt-2 rounded-lg bg-gray-300 p-2">
					<Collapsible open={false} className="space-y-2">
						<div className={`flex items-center justify-between space-x-4 rounded-md bg-white`}>
							<CollapsibleTrigger asChild>
								<Button
									variant="ghost"
									size="sm"
									className="flex w-full cursor-default items-center justify-center p-0"
								>
									<ChevronsUpDown className="h-4 w-4" />
									<span className="sr-only">Toggle</span>
								</Button>
							</CollapsibleTrigger>
						</div>
						<CollapsibleContent className="space-y-2"></CollapsibleContent>
					</Collapsible>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 45 - ROTATION CREATOR
	{
		target: "#rotation-creator-rotation-stats",
		content: (
			<div>
				<p>Here you&apos;ll see statistics for generated rotation.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 46 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<p>If you would like to edit the schedule of the selected crew member press this button.</p>
				<div className="my-2">
					<Button className="w-64 cursor-default">Overwrite leave plan</Button>
				</div>
				<p>This option is here to avoid unwanted or accidental changes.</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 47 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<p>
					Go to the next/previous rotation, come back to the current rotation, number of the
					rotation on one view.
				</p>
				<div className="mt-4 flex flex-col items-start space-y-2">
					<div>
						<Button>Previous</Button> &mdash; Go to the previous rotation
					</div>
					<div>
						<Button>Show Current Rotation</Button> &mdash; Go to the current rotation
					</div>
					<div>
						<Button>Next</Button> &mdash; Go to the next rotation
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 48 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<p>You will find type of the rotation and number of weeks here.</p>
				<div className="mt-4 flex flex-col items-center gap-1">
					<div className="col-span-2 w-64 rounded-md border py-2 text-center">Rotation</div>
					<div className="col-span-2 flex w-64 items-center justify-center rounded-md border py-2">
						Onboard (1/6)
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 49 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<p>You&apos;ll be able to click on the day to change it&apos;s kind.</p>
				<div className="mt-4 flex justify-center">
					<div className="flex cursor-default items-center justify-center rounded-md border bg-blue-200 px-5 py-3 font-medium">
						24
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 50 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<p>Once clicked on a day, you&apos;ll see dialog like this.</p>
				<p>Here you can change the day type, choose a time frame and add related documents.</p>
				<div className="flex justify-center py-4">
					<img className="rounded-lg" src={rotationCreatorChangeDayType} alt="" />
				</div>
				<p className="text-left">
					<b>End Date</b> - choose this date if you want to pick more then one day
				</p>
				<p className="text-left">
					<b>Day Kind</b> - choose day type from the dropdown list
				</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 51 - ROTATION CREATOR
	{
		target: "#rotation-calendar-page",
		content: (
			<div>
				<p>Colour code for specific days.</p>
				<div className="mt-4">
					<div className="flex grow justify-center">
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-blue-200"></div>
							<label className="text-nowrap text-xs ">Onboard</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-gray-300"></div>
							<label className="text-nowrap text-xs ">Travel</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-green-300"></div>
							<label className="text-nowrap text-xs ">Change</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-pink-400"></div>
							<label className="text-nowrap text-xs ">Rotational Leave</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-yellow-200"></div>
							<label className="text-nowrap text-xs ">Other day</label>
						</div>
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
];

import { apiSlice } from "@/app/api/apiSlice";

export const departmentsAndPositionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getDepartmentsAndPositions: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/departments`,
			providesTags: ["DepartmentsAndPositions"],
		}),
		addDepartment: builder.mutation({
			query: ({ vesselId, name }) => ({
				url: `/api/vessels/${vesselId}/departments`,
				method: "POST",
				body: { name },
			}),
			invalidatesTags: ["DepartmentsAndPositions", "MyVesselInfo"],
		}),
		updateDepartment: builder.mutation({
			query: ({ vesselId, name, departmentId }) => ({
				url: `/api/vessels/${vesselId}/departments/${departmentId}`,
				method: "PUT",
				body: { name },
			}),
			invalidatesTags: ["DepartmentsAndPositions", "MyVesselInfo"],
		}),
		removeDepartment: builder.mutation({
			query: ({ vesselId, departmentId }) => ({
				url: `/api/vessels/${vesselId}/departments/${departmentId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["DepartmentsAndPositions", "MyVesselInfo"],
		}),
		addPosition: builder.mutation({
			query: ({ vesselId, name, minimum, departmentId }) => ({
				url: `/api/vessels/${vesselId}/departments/${departmentId}/positions`,
				method: "POST",
				body: { name, minimum },
			}),
			invalidatesTags: ["DepartmentsAndPositions", "MyVesselInfo"],
		}),
		updatePosition: builder.mutation({
			query: ({ vesselId, departmentId, name, minimum, positionId }) => ({
				url: `/api/vessels/${vesselId}/departments/${departmentId}/positions/${positionId}`,
				method: "PUT",
				body: { name, minimum },
			}),
			invalidatesTags: ["DepartmentsAndPositions", "MyVesselInfo"],
		}),
		removePosition: builder.mutation({
			query: ({ vesselId, departmentId, positionId }) => ({
				url: `/api/vessels/${vesselId}/departments/${departmentId}/positions/${positionId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["DepartmentsAndPositions", "MyVesselInfo"],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetDepartmentsAndPositionsQuery,
	useAddDepartmentMutation,
	useUpdateDepartmentMutation,
	useRemoveDepartmentMutation,
	useAddPositionMutation,
	useUpdatePositionMutation,
	useRemovePositionMutation,
} = departmentsAndPositionsApiSlice;

export const notificationsSteps = [
	// 67 - NOTIFICATIONS
	{
		target: "#notifications-page",
		content: (
			<div>
				<p>
					Notification system design as a „job list” to streamline the process and reduce back and
					forth emails.
				</p>
			</div>
		),
		disableBeacon: true,
		placement: "center",
	},
];

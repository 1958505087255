import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogClose
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ButtonLoader } from "@/components/ButtonLoader";


type Props = {
	openWarningDialog: boolean; 
	setOpenWarningDialog: React.Dispatch<React.SetStateAction<boolean>>;
	isEditingRotation: boolean;
	saveRotationHandler: () => void;
};

export const RotationOverwriteDialog = ({ 
	openWarningDialog, 
	setOpenWarningDialog,
	isEditingRotation,
	saveRotationHandler
}: Props) => {
	return (
		<Dialog open={openWarningDialog} onOpenChange={setOpenWarningDialog}>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Are you absolutely sure?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. This will <b>permanently overwrite</b> contracts for all related users.
					</DialogDescription>
				</DialogHeader>
				<div className="flex justify-between">
					<DialogClose asChild>
						<Button type="button" variant="secondary" disabled={isEditingRotation}>
							Cancel
						</Button>
					</DialogClose>
					<Button onClick={ saveRotationHandler } disabled={isEditingRotation}>
						<ButtonLoader isLoading={isEditingRotation}/>
						Save
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

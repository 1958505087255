import { CrewMembersDataControls } from "@/components/CrewMembersDataControls";
import { type User } from "@/types/User";

import { useState } from "react";
import { GridItem } from "./GridItem";

type Props = {
	data: User[];
};

export const CrewMembersGrid = ({ data }: Props) => {
	const [visibleData, setVisibleData] = useState(data);

	return (
		<div className="w-full">
			<CrewMembersDataControls data={data} setVisibleData={setVisibleData} />
			<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{visibleData.map((crewMember) => {
					return <GridItem key={crewMember.id} crewMember={crewMember} />;
				})}
			</div>
		</div>
	);
};

import { Ship } from "lucide-react";
import { useParams } from "react-router-dom";

import { useGetVesselQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { WhiteBox } from "@/components/WhiteBox";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/hooks/useAuth";

export const VesselInfoBar = () => {
	const auth = useAuth();
	const { vesselId } = useParams();

	const vessel = auth?.user?.vessel;
	const {
		data: response,
		isLoading,
		isFetching,
	} = useGetVesselQuery({ vesselId }, { skip: !vesselId });

	if (vessel) {
		return (
			<WhiteBox className="mb-4 flex items-center gap-4 py-2">
				<div className="flex h-8 w-8 items-center">
					<Ship />
				</div>
				<div className="flex flex-wrap items-center gap-x-4 gap-y-1 text-sm sm:flex-nowrap sm:text-base">
					<div>
						<span className="font-semibold">Vessel Name:</span> {vessel.name}
					</div>
					<div>
						<span className="font-semibold">Vessel IMO:</span> {vessel.imo}
					</div>
				</div>
			</WhiteBox>
		);
	}

	return (
		<WhiteBox className="mb-4 flex items-center gap-4 py-2">
			<div className="flex h-8 w-8 items-center">
				<Ship />
			</div>
			{isLoading || isFetching ? (
				<div className="flex flex-wrap items-center gap-x-4 gap-y-1 sm:flex-nowrap">
					<Skeleton className="h-4 w-[180px]" />
					<Skeleton className="h-4 w-[180px]" />
				</div>
			) : (
				<div className="flex flex-wrap items-center gap-x-4 gap-y-1 text-sm sm:flex-nowrap sm:text-base">
					<div>
						<span className="font-semibold">Vessel Name:</span> {response?.data?.name || "-"}
					</div>
					<div>
						<span className="font-semibold">Vessel IMO:</span> {response?.data?.imo || "-"}
					</div>
				</div>
			)}
		</WhiteBox>
	);
};

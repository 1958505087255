import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { type FormProps } from "@/types/Form";

import { ButtonLoader } from "@/components/ButtonLoader";
import { Checkbox } from "@/components/ui/checkbox";
import { formSchema, type FormType } from "./formSchema";

export const SetPasswordForm = ({ onSubmit, isLoading }: FormProps<FormType>) => {
	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			password: "",
			confirmPassword: "",
			regulationsAcceptance: undefined,
		},
	});
	const { handleSubmit, control } = form;

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="password"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Input type="password" placeholder="New password" {...field} />
									</FormControl>
									<FormMessage messageType="text" />
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="confirmPassword"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Input type="password" placeholder="Confirm new password" {...field} />
									</FormControl>
									<FormMessage messageType="text" />
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="regulationsAcceptance"
							render={({ field }) => (
								<FormItem className="flex flex-row items-start space-x-3 space-y-0">
									<FormControl>
										<Checkbox checked={field.value} onCheckedChange={field.onChange} />
									</FormControl>
									<FormLabel className="font-normal">
										By checking this box, I agree to the{" "}
										<a
											className="underline"
											href="https://myrotat.com/terms-of-service/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Terms of Service
										</a>{" "}
										and{" "}
										<a
											className="underline"
											href="https://myrotat.com/privacy-policy/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Privacy Policy
										</a>
										.
									</FormLabel>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex justify-center">
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Set Password
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};

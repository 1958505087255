import { type ColumnDef } from "@tanstack/react-table";

import { EditSubscriptionTierDialog } from "@/components/dialogs/EditSubscriptionTierDialog";
import { RemoveSubscriptionTierDialog } from "@/components/dialogs/RemoveSubscriptionTierDialog";
import { type SubscriptionTier } from "@/types/Subscription";

export const columns: ColumnDef<SubscriptionTier>[] = [
	{
		accessorKey: "type",
		header: "Name",
		cell: ({ row }) => <div className="capitalize">{row.getValue("type")}</div>,
	},
	{
		accessorKey: "amount",
		header: "Amount to pay",
		cell: ({ row }) => <div>${row.getValue("amount")}</div>,
	},
	{
		accessorKey: "period",
		header: "Period",
		cell: ({ row }) => <div className="capitalize">{row.getValue("period")}</div>,
	},
	{
		accessorKey: "usersLimit",
		header: "Crew members limit",
		cell: ({ row }) => <div>{row.getValue("usersLimit")}</div>,
	},
	{
		id: "actions",
		header: () => <div>Actions</div>,
		cell: ({ row }) => {
			const tierId = row.original.id;

			return (
				<div className="flex justify-center gap-2">
					<EditSubscriptionTierDialog tierId={tierId} tier={{ ...row.original }} />
					<RemoveSubscriptionTierDialog tierId={tierId} />
				</div>
			);
		},
	},
];

import * as z from "zod";

import { positionNameZodRule } from "@/lib/zod";

export const formSchema = z.object({
	name: positionNameZodRule,
	minimum: z.coerce.number().int().positive(),
});

export type FormType = z.infer<typeof formSchema>;

import { useState } from "react";

import { useCreateMagicLinkMutation } from "@/app/api/slices/admin/magicLinksApiSlice";
import { MagicLinksCreateForm } from "@/components/forms/MagicLinksCreateForm";
import { type FormType } from "@/components/forms/MagicLinksCreateForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const MagicLinkCreateDialog = () => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [createMagicLinkMutation, { isLoading }] = useCreateMagicLinkMutation();

	const handleCreateMagicLink = async (data: FormType) => {
		await createMagicLinkMutation({ data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully created magic link.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button className="uppercase">Create magic link</Button>
			</DialogTrigger>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Create magic link</DialogTitle>
				</DialogHeader>
				<MagicLinksCreateForm onSubmit={handleCreateMagicLink} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

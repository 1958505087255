import { useGetManagersQuery } from "@/app/api/slices/admin/managersApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { ManagersTable } from "@/components/tables/ManagersTable";
import { useParams } from "react-router-dom";

export const ManagersListingPage = () => {
	const { vesselId } = useParams();
	const { data: response, isLoading, isFetching } = useGetManagersQuery({ vesselId });

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<div className="mt-4">
			<WhiteBox>
				<ManagersTable data={response?.data || []} />
			</WhiteBox>
		</div>
	);
};

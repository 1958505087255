type Props = {
    type: 'rotation' | 'positions';
    number_of_rotations: number | null | undefined;
    number_of_travel_days: number | null | undefined;
    number_of_days_onboard: number | null | undefined;
    number_of_days_leave: number | null | undefined;
    rotationPeriodType: string;
    leaveBalaceType: number | null | undefined;
    leave_balance_1_onboard: number | null | undefined;
    leave_balance_1_offboard: number | null | undefined;
    leave_balance_2: number | null | undefined;
    leaveAllowance: number | null | undefined;
    leaveAllowanceBalance: number | null | undefined;
};

export const Statistics = ({
    type,
    number_of_rotations,
    number_of_travel_days,
    number_of_days_onboard,
    number_of_days_leave,
    rotationPeriodType,
    leaveBalaceType,
    leave_balance_1_onboard,
    leave_balance_1_offboard,
    leave_balance_2,
    leaveAllowance,
    leaveAllowanceBalance,
}: Props) => {
	return (
        <div>
            <div className="text-sm flex justify-between">
                <span>{ type === 'rotation' ? 'Estimated number of rotations: ' : 'Number of rotations: '}</span>
                <span>{ number_of_rotations ?? '-'}</span>
            </div>
            <div className="text-sm flex justify-between">
                <span>{ type === 'rotation' ? 'Estimated number of travel days: ' : 'Number of travel days: '}</span>
                <span>{ number_of_travel_days ?? '-'}</span>
            </div>
            <div className="text-sm flex justify-between">
                <span>{ type === 'rotation' ? 'Estimated number of days (onboard): ' : 'Number of days onboard: '}</span>
                <span>{ number_of_days_onboard ?? '-'}</span>
            </div>
            <div className="text-sm flex justify-between">
                <span>{ type === 'rotation' ? 'Estimated number of days (rest): ' : 'Number of days offboard: '}</span>
                <span>{ number_of_days_leave ?? '-'}</span>
            </div>

            { ( ["months", "weeks"].includes(rotationPeriodType) && ((leaveBalaceType === 1) || (leaveBalaceType === 3))) && (
                <>
                    <div className="text-sm flex justify-between">
                        <span>Onboard days balance: </span>
                        <span className="text-sm">{
                            ((leave_balance_1_onboard !== null) || (leave_balance_1_onboard !== undefined)) 
                            ? leave_balance_1_onboard 
                            : "-" }
                        </span>
                    </div>
                    <div className="text-sm flex justify-between">
                        <span>Leave days balance: </span>
                        <span className="text-sm">{
                            ((leave_balance_1_offboard !== null) || (leave_balance_1_offboard !== undefined)) 
                            ? leave_balance_1_offboard 
                            : "-" }
                        </span>
                    </div>
                </>
            )}

            { ( ["months", "weeks"].includes(rotationPeriodType) && ((leaveBalaceType === 2) || (leaveBalaceType === 3))) && (
                <div className="text-sm flex justify-between">
                    <span>Leave days balance*: </span>
                    <span className="text-sm">{
                        ((leave_balance_2 !== null) || (leave_balance_2 !== undefined)) 
                        ? leave_balance_2 
                        : "-" }
                    </span>
                </div>
            )}

            { ( ["leave_allowance"].includes(rotationPeriodType) && ((leaveBalaceType === 2) || (leaveBalaceType === 3))) && (
                <div className="text-sm flex justify-between">
                    <span>Leave allowance balance:</span>
                    <span className="text-sm">
                        { leaveAllowanceBalance ?? "-" }{ leaveAllowance ? ` / ${leaveAllowance}` : "" }
                    </span>
                </div>
            )}
        </div>
	);
};

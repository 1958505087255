export const knowledgeBaseSteps = [
	// 58 - KNOWLEDGE BASE
	{
		target: "#knowledge-base-page",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Build knowledge base for your yacht</h1>
				<p>Familiarize your crew with existing rules by writing useful articles.</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 59 - KNOWLEDGE BASE
	{
		target: "#knowledge-base-switcher",
		content: (
			<div>
				<p>Switcher.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 60 - KNOWLEDGE BASE
	{
		target: "#knowledge-base-sections-box",
		content: (
			<div>
				<p>Section box.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 61 - KNOWLEDGE BASE
	{
		target: "#knowledge-base-articles-box",
		content: (
			<div>
				<p>Articles box.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
];

export const knowledgeBaseAddSteps = [
	// 62 - KNOWLEDGE BASE
	{
		target: "#add-article-box",
		content: (
			<div>
				<p>Add article form.</p>
				<p>
					Crew member will see all articles in his mobile app. Some of them will be mandatory to
					read.
				</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
];

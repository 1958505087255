import { Pen, Trash2 } from "lucide-react";

import { Button } from "@/components/ui/button";

export const userDocsSteps = [
	// 52 - USER DOCS
	{
		target: "#crew-member-documents-page",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Welcome to the document manager.</h1>
				<p>
					This page is designed to allow managers to upload and manage documents essential for their
					team members.
				</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 53 - USER DOCS
	{
		target: "#add-documents-button",
		content: (
			<div>
				<p>You can add travel docs and instructions here.</p>
				<p>Crew member will get it in his mobile app.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 54 - USER DOCS
	{
		target: "#crew-member-documents-page",
		content: (
			<div>
				<p>Once documents are added you can remove or edit them.</p>
				<div className="mt-2 flex items-center gap-2">
					<Button
						variant="outline"
						size="icon"
						style={{ background: "#F44336" }}
						className="cursor-default"
					>
						<Trash2 color="#fff" />
					</Button>
					<span> &mdash; Remove</span>
				</div>
				<div className="mt-2 flex items-center gap-2">
					<Button
						variant="outline"
						size="icon"
						style={{ background: "#2055A3" }}
						className="cursor-default"
					>
						<Pen color="#fff" />
					</Button>
					<span> &mdash; Edit</span>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
];

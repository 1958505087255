import * as Tooltip from '@radix-ui/react-tooltip';
import { format } from "date-fns";
import { getClassFromType, getDayType, DayType } from "@/utils/calendarUtils";
import { type RotationDatesInfoInterface, type CrewDataInterface } from "@/types/Calendar";
import RotatLogo from "@/assets/logo-small.svg";
import { MonthChange } from '../calendar/Cell/MonthChange';

interface FormattedDocuments {
	[key: string]: {
		title: string;
		id: string | number;
	}[];
}

type Props = { 
	documents: { [key: string | number]: FormattedDocuments } | undefined;
	date: Date; 
	weekYear: number; clickHandler: ({startDate}:{startDate: Date}) => void;
	rotationsInfo: {[key: number]: {onboard: RotationDatesInfoInterface, offboard: RotationDatesInfoInterface}};
	crewData: CrewDataInterface[];
	className?: string;
	setHoveredCell: (cell: string | undefined) => void;
	onClickDocument: (id: string | number) => void;
};

export const Cell = ({onClickDocument, documents, setHoveredCell, className="", rotationsInfo, crewData, date, clickHandler }: Props) => {
	const formattedDate = date ? format(date, "y-MM-dd") : "-";
	
	const getInitials = (name: string) => {
		if(!name) return "";
		const names = name.split(" ");
		const initials = names.map((name) => name[0]).join("");
		return initials;
	}

	const getRotationData = (rotationId: number) => {
		if(!rotationsInfo) return;
		const rotation = rotationsInfo?.[rotationId];
		if(!rotation) return;

		return {
			onboard: `${rotation?.onboard?.start_date} - ${rotation?.onboard?.end_date}`,
			offboard: `${rotation?.offboard?.start_date} - ${rotation?.offboard?.end_date}`
		};
	}

	const day = date.toLocaleString("default", { day: "numeric" });

	return (
		<Tooltip.Provider>
			<Tooltip.Root>
				<Tooltip.Trigger asChild>
					<div className={`ctrl__cell-day relative cursor-pointer flex flex-col items-center justify-between rounded-md border border-gray-300 font-medium text-xs lg:text-sm ${className}`}
						onClick={() => clickHandler({startDate: date})}
						onMouseEnter={() => setHoveredCell(format(date, "y-MM-dd"))}
      					onMouseLeave={() => setHoveredCell(undefined)}
						data-cell-date={format(date, "y-MM-dd")}
					>
						<div className="relative flex w-full justify-center">

							<div className="py-1 flex justify-center items-center w-full relative overflow-hidden rounded-t-md">
								{crewData?.some((crewMember) => ((crewMember.type === DayType.Change) || (crewMember.type === DayType.TravelAndChange))) && (
									<div className="text-sm">
										<img src={RotatLogo} alt="Rotat Logo" className="w-4 h-4 inline-block mr-1"/>
									</div>
								)}

								<span>{day}</span>

								{
									crewData?.map((crewMember) => {
										if(!crewMember.crew_id || crewMember.type === 'undefined') return null;
										return (<>
											{ (documents && documents[crewMember.crew_id] && documents[crewMember.crew_id][formattedDate]) && (
												<div className="top-0 left-0 right-0 absolute h-2 bg-red-500 rotate-[45deg] translate-x-2/4"></div>
											)}
											</>
										);
									})
								}
							</div>

							<MonthChange day={day} />
						</div>

						<div className="relative w-full font-medium overflow-hidden rounded-b-md" >
							{
								crewData?.map((crewMember) => {
									if(!crewMember.crew_id || crewMember.type === 'undefined') return null;
									return (
										<div key={crewMember.crew_id} className={`pb-1 min-h-4 w-full border-t border-gray-300 ${getClassFromType(crewMember.type)}`}>
											<div className="text-xs text-center">{getInitials(crewMember.crew_name)}</div>
										</div>
									);
								})
							}
						</div>
					</div>

				</Tooltip.Trigger>
				<Tooltip.Portal>
					<Tooltip.Content className="TooltipContent z-10" sideOffset={5}>

						<div className="flex flex-col">
							<div className="text-xs md:text-sm font-bold">{ formattedDate }</div>

							{
								crewData?.map((crewMember) => {
									if(!crewMember.crew_id) return null;
									return (
										<>
											<hr className="my-1"/>
											<div className="text-sm">{ crewMember.crew_name }</div>

											<div className="flex items-end">
												{(crewMember.type === DayType.Change) || (crewMember.type === DayType.TravelAndChange) && (
													<div className="text-sm font-medium">
														<img src={RotatLogo} alt="Rotat Logo" className="w-5 h-5 inline-block mr-1"/>
													</div>
												)}
												<div className="text-sm font-medium">{ getDayType(crewMember.type) }</div>
											</div>

											<div className="text-sm flex flex-col">
												<div>Onboard: { getRotationData(crewMember.rotation_id)?.onboard }</div>
												<div>Offboard: { getRotationData(crewMember.rotation_id)?.offboard }</div>
											</div>
											
											{ (documents && documents[crewMember.crew_id] && documents[crewMember.crew_id][formattedDate]) && (
												<div className="mt-2">
													<div className="text-xs lg:text-sm font-medium capitalize">Related documents: </div>
											
													{documents[crewMember.crew_id][formattedDate].map((doc: {id: string | number, title: string}) => {
														return (
															<div key={doc.id}>
																<p onClick={() => onClickDocument(doc.id)} className="text-xs lg:text-sm text-primary underline cursor-pointer">{doc.title}</p>
															</div>
														);
													})}
											
												</div>
											) }

										</>
									);
								})
							}
						</div>
					</Tooltip.Content>
				</Tooltip.Portal>
      		</Tooltip.Root>
    	</Tooltip.Provider>
	);
};

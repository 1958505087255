import { Check } from "lucide-react";
import { useState } from "react";

import { useApproveSuggestionMutation } from "@/app/api/slices/admin/adminSuggestionsApiSlice";
import { SuggestionApproveForm } from "@/components/forms/SuggestionApproveForm";
import { type FormType } from "@/components/forms/SuggestionApproveForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { type SuggestionReporter } from "@/types/Suggestions";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	suggestionId: number;
	reporter: SuggestionReporter;
	suggestion: { title: string; description: string };
};

export const ApproveSuggestionDialog = ({ suggestionId, reporter, suggestion }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [approveSuggestionMutation, { isLoading }] = useApproveSuggestionMutation();

	const handleApproveSuggestion = async (data: FormType) => {
		await approveSuggestionMutation({ suggestionId, data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully approved suggestion.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#4CAF50" }}>
					<Check color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Approve Suggestion</DialogTitle>
				</DialogHeader>
				<div>
					<div className="mb-2 flex flex-col space-y-1.5 border-b pb-4">
						<div className="flex flex-col space-y-2">
							<div className="grid grid-cols-1 items-center gap-1">
								<div className="whitespace-nowrap font-semibold">Manager</div>
								<div>{reporter.firstName + " " + reporter.lastName}</div>
							</div>
							<div className="grid grid-cols-1 items-center gap-1">
								<div className="whitespace-nowrap font-semibold">Title</div>
								<div>{suggestion.title}</div>
							</div>
							<div className="grid grid-cols-1 items-center gap-1">
								<div className="whitespace-nowrap font-semibold">Description</div>
								<div>{suggestion.description}</div>
							</div>
						</div>
					</div>
				</div>
				<SuggestionApproveForm onSubmit={handleApproveSuggestion} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

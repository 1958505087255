import * as z from "zod";

import { docDescriptionZodRule, docTitleZodRule } from "@/lib/zod";

export const formSchema = z.object({
	title: docTitleZodRule,
	description: docDescriptionZodRule,
	files: z.any().refine((file) => file && file?.length > 0, "At least one file is required"),
});

export type FormType = z.infer<typeof formSchema>;

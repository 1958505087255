import { useParams } from "react-router-dom";

import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { DepartmentsAndPositionsAccordion } from "@/components/accordions/DepartmentsAndPositionsAccordion";
import { AddDepartmentDialog } from "@/components/dialogs/AddDepartmentDialog";

// import { data } from "@/data/dummy/departments-and-positions.data";

export const VesselDepartmentsAndPositionsPage = () => {
	const { vesselId } = useParams();
	const {
		data: response,
		isLoading,
		isFetching,
	} = useGetDepartmentsAndPositionsQuery({ vesselId });

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<WhiteBox className="mt-4">
			<AddDepartmentDialog />
			<DepartmentsAndPositionsAccordion data={response.data} />
		</WhiteBox>
	);
};

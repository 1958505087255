import { Menu } from "lucide-react";
import { type MouseEventHandler } from "react";

import { JoyrideStartButton } from "@/components/JoyrideStartButton";
import { Logo } from "@/components/Logo";
import { NotificationsBell } from "@/components/NotificationsBell";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/useAuth";

type Props = { onExpandedChange: MouseEventHandler<HTMLButtonElement> };

export const TopBar = ({ onExpandedChange }: Props) => {
	const auth = useAuth();
	const role = auth?.user?.role.name;

	return (
		<div className="flex h-14 items-center justify-between bg-topBar px-4">
			<div className="flex h-full items-center gap-4">
				<Button size="icon" onClick={onExpandedChange}>
					<Menu />
				</Button>
				<Logo className="h-full py-2" />
			</div>
			{role === "manager" && (
				<div className="flex items-center gap-2">
					<JoyrideStartButton />
					<NotificationsBell />
				</div>
			)}
		</div>
	);
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { type FormProps } from "@/types/Form";

import { ButtonLoader } from "@/components/ButtonLoader";
import { formSchema, type FormType } from "./formSchema";

export const ForgotPasswordForm = ({ onSubmit, isLoading }: FormProps<FormType>) => {
	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
		},
	});
	const { handleSubmit, control } = form;

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Input type="email" placeholder="Email" {...field} />
									</FormControl>
									<FormMessage messageType="text" />
								</FormItem>
							)}
						/>
					</div>
					<div className="space-y-1"></div>
					<div className="flex justify-center">
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Request reset link
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};

import { apiSlice } from "@/app/api/apiSlice";

export const notificationsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUserRequests: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/user-requests`,
			providesTags: ["UserRequests"],
		}),
		updateUserRequest: builder.mutation({
			query: ({ vesselId, requestId, data }) => ({
				url: `/api/vessels/${vesselId}/user-requests/${requestId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["UserRequests"]),
		}),
		hideUserRequest: builder.mutation({
			query: ({ vesselId, requestId }) => ({
				url: `/api/vessels/${vesselId}/user-requests/${requestId}/hide`,
				method: "PUT",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["UserRequests"]),
		}),
	}),
	overrideExisting: true,
});

export const { useGetUserRequestsQuery, useUpdateUserRequestMutation, useHideUserRequestMutation } =
	notificationsApiSlice;

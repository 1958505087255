import * as z from "zod";

export const formSchema = z.object({
	type: z.string().min(3, { message: "Tier name must be at least 3 characters long" }),
	amount: z.coerce.number().min(1, { message: "Amount must be a positive number" }),
	period: z.enum(["day", "week", "month", "year"], {
		required_error: "Period is required",
	}),
	usersLimit: z.coerce.number().min(1, { message: "Crew members limit must be a positive number" }),
});

export type FormType = z.infer<typeof formSchema>;

import { Pen } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useUpdateCrewMemberDocumentMutation } from "@/app/api/slices/documentsApiSlice";
import { type FormType } from "@/components/forms/CrewMemberDocumentsAddForm/fromSchema";
import { CrewMemberDocumentsUpdateForm } from "@/components/forms/CrewMemberDocumentsUpdateForm";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { type Document, type DocumentFile } from "@/types/Document";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	document: Document;
};

export const EditCrewMemberDocumentsDialog = ({ document }: Props) => {
	const auth = useAuth();
	const params = useParams();
	const vesselId = auth?.user?.vessel?.id || params.vesselId;

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateCrewMemberDocumentMutation, { isLoading }] = useUpdateCrewMemberDocumentMutation();

	const handleUpdateCrewMemberDocument = async (data: FormType) => {
		const formData = new FormData();
		formData.append("_method", "PUT");

		for (const item in data) {
			// @ts-expect-error to-do: fix this
			const value = data[item];
			if (item === "newFiles" && (value instanceof FileList || value instanceof Array)) {
				Array.from(value).forEach((file, index) => {
					formData.append(`${item}[${index}]`, file);
				});
			} else if (item === "filesToKeep" && value instanceof Array) {
				value.forEach((f: DocumentFile, index) => {
					formData.append(`${item}[${index}]`, `${f.id}`);
				});
			} else {
				formData.append(item, value);
			}
		}

		await updateCrewMemberDocumentMutation({
			vesselId,
			userId: params.userId,
			documentId: document.id,
			data: formData,
		})
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated document.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#2055A3" }}>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px] md:max-w-[750px]">
				<DialogHeader>
					<DialogTitle>Edit Document</DialogTitle>
				</DialogHeader>
				<CrewMemberDocumentsUpdateForm
					onSubmit={handleUpdateCrewMemberDocument}
					isLoading={isLoading}
					defaultValues={{ ...document, filesToKeep: document.files, newFiles: [] }}
				/>
			</DialogContent>
		</Dialog>
	);
};

import { apiSlice } from "@/app/api/apiSlice";

export const subscriptionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptionTiers: builder.query({
			query: () => `/api/subscriptions/tiers`,
			providesTags: ["Subscriptions"],
		}),
		addSubscriptionTier: builder.mutation({
			query: ({ data }) => ({
				url: `/api/subscriptions/tiers`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Subscriptions"]),
		}),
		updateSubscriptionTier: builder.mutation({
			query: ({ data, tierId }) => ({
				url: `/api/subscriptions/tiers/${tierId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Subscriptions"]),
		}),
		removeSubscriptionTier: builder.mutation({
			query: ({ tierId }) => ({
				url: `/api/subscriptions/tiers/${tierId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Subscriptions"]),
		}),
		assignSubscriptionTierToVessel: builder.mutation({
			query: ({ vesselId, subscriptionTierId }) => ({
				url: `/api/subscriptions/assign`,
				method: "POST",
				body: { vesselId, subscriptionTierId },
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Vessels"]),
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetSubscriptionTiersQuery,
	useAddSubscriptionTierMutation,
	useUpdateSubscriptionTierMutation,
	useRemoveSubscriptionTierMutation,
	useAssignSubscriptionTierToVesselMutation,
} = subscriptionsApiSlice;

import { Loader } from "lucide-react";

type Props = {
	isLoading: boolean;
};

export const ButtonLoader = ({ isLoading }: Props) => {
	if (!isLoading) return null;

	return <Loader className="mr-2 h-4 w-4 animate-spin duration-2000 " />;
};

import { Loader, LogOut } from "lucide-react";
import OneSignal from "react-onesignal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { apiSlice } from "@/app/api/apiSlice";
import { useLogoutMutation } from "@/app/api/slices/authApiSlice";
import { authSlice } from "@/app/slices/authSlice";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const LogoutButton = () => {
	const dispatch = useDispatch();
	const auth = useAuth();
	const userEmail = auth?.user?.email;
	const { toast } = useToast();
	const navigate = useNavigate();
	const [logoutMutation, { isLoading }] = useLogoutMutation();

	const handleLogout = async () => {
		await logoutMutation({})
			.unwrap()
			.then(() => {
				dispatch(authSlice.actions.logout());
				dispatch(apiSlice.util.resetApiState());
				OneSignal.logout();
				OneSignal.User.removeEmail(userEmail as string);
				navigate("/login");
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Button
			onClick={handleLogout}
			variant="ghost"
			className={`flex items-center justify-start gap-3`}
		>
			<div className="ml-1">
				{isLoading ? <Loader className="animate-spin duration-2000" /> : <LogOut />}
			</div>
			<div className="flex flex-col items-start overflow-hidden transition-all duration-300">
				Logout
			</div>
		</Button>
	);
};

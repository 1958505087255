import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { type FormProps } from "@/types/Form";

import { ButtonLoader } from "@/components/ButtonLoader";
import { DepartmentAndPositionsDependentSelects } from "@/components/forms/DepartmentAndPositionsDependentSelects";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { formSchema, type FormType } from "./formSchema";

type Props = FormProps<FormType> & {
	hardcodedRole?: "manager";
};

export const UserUpsertForm = ({
	hardcodedRole,
	onSubmit,
	isLoading,
	defaultValues = {
		firstName: "",
		lastName: "",
		role: "",
		email: "",
		phone: "",
		nationality: "",
		homeAirport: "",
		department: "",
		position: "",
	},
}: Props) => {
	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: hardcodedRole ? { ...defaultValues, role: hardcodedRole } : defaultValues,
	});
	const { handleSubmit, control, setValue } = form;

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="firstName"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">First Name</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="lastName"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Last Name</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className={`flex flex-col space-y-1.5 ${hardcodedRole ? "hidden" : ""}`}>
						<FormField
							control={control}
							name="role"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Role</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Select
												onValueChange={field.onChange}
												defaultValue={hardcodedRole ? hardcodedRole : field.value}
											>
												<SelectTrigger>
													<SelectValue placeholder="Select Role" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="user">User</SelectItem>
														<SelectItem value="manager">Manager</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Email</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input type="email" placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="phone"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Phone</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input type="tel" placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="nationality"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Nationality</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="homeAirport"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Home airport</FormLabel>
											<FormMessage />
										</div>

										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<DepartmentAndPositionsDependentSelects control={control} setValue={setValue} />
					<div className="flex justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};

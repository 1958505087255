import Pusher from "pusher-js";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
	notificationsApiSlice,
	useGetUserRequestsQuery,
} from "@/app/api/slices/manager/notificationsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { TableGridViewSwitcher } from "@/components/TableGridViewSwitcher";
import { WhiteBox } from "@/components/WhiteBox";
import { Requests } from "@/components/requests";
import { useJoyride } from "@/contexts/JoyrideContext";
import { joyride_redirect_9_index } from "@/hocs/JoyrideWrapper";
import { useAuth } from "@/hooks/useAuth";
import { type UserRequest } from "@/types/UserRequest";

export const NotificationsPage = () => {
	const auth = useAuth();
	const params = useParams();
	const dispatch = useDispatch();
	const vesselId = auth?.user?.vessel?.id || params.vesselId;

	const { data: response, isLoading, isFetching } = useGetUserRequestsQuery({ vesselId });

	const transformedData = useMemo(() => {
		return response?.data.filter((item: UserRequest) => item.isVisibleToManager === 1);
	}, [response?.data]);

	const [currentView, setCurrentView] = useState<"row" | "grid">("grid");

	const pusher = new Pusher("92115caff344068cf1d2", {
		cluster: "eu",
	});

	useEffect(() => {
		dispatch(notificationsApiSlice.util.invalidateTags(["UserRequests"]));
	}, []);

	const channel = pusher.subscribe(`rotat-${auth?.user?.vessel?.id}`);
	channel.bind("notification", function (data: { notificationsAmount: number }) {
		if (transformedData && data.notificationsAmount !== transformedData.length) {
			dispatch(notificationsApiSlice.util.invalidateTags(["UserRequests"]));
		}
	});

	const { run, startJoyride, stepIndex } = useJoyride();
	useEffect(() => {
		if (!run && !isLoading && stepIndex === joyride_redirect_9_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [isLoading]);

	if (isLoading || isFetching)
		return (
			<div id="notifications-page">
				<InLayoutLoader />
			</div>
		);

	const handleViewSwitch = () => {
		setCurrentView(currentView === "row" ? "grid" : "row");
	};

	return (
		<WhiteBox id="notifications-page">
			<div className="mb-4 flex items-center justify-between">
				<h1 className="text-xl font-semibold md:text-2xl">Notifications</h1>
				<TableGridViewSwitcher onClick={handleViewSwitch} />
			</div>
			<Requests data={transformedData} viewType={currentView} />
		</WhiteBox>
	);
};

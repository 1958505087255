import { zodResolver } from "@hookform/resolvers/zod";
import { Download, Trash2 } from "lucide-react";
import { useForm } from "react-hook-form";

import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { type FormProps } from "@/types/Form";

import { type DocumentFile } from "@/types/Document";
import { formSchema, type FormType } from "./formSchema";

export const CrewMemberDocumentsUpdateForm = ({
	onSubmit,
	isLoading,
	defaultValues = {
		title: "",
		description: "",
		filesToKeep: [],
		newFiles: [],
	},
}: FormProps<FormType>) => {
	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const { handleSubmit, control, setValue } = form;

	const newFiles = form.watch("newFiles");
	const handleDeleteFile = (index: number) => {
		if (newFiles) {
			const filesArray = [...newFiles];
			filesArray.splice(index, 1);

			setValue("newFiles", filesArray as unknown as FileList);
		}
	};

	const existingFiles = form.watch("filesToKeep");
	const handleDeleteExistingFile = (index: number) => {
		if (existingFiles) {
			const filesArray = [...existingFiles];
			filesArray.splice(index, 1);

			setValue("filesToKeep", filesArray as unknown as FileList);
		}
	};

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="title"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-3">
										<div className="flex items-center justify-start gap-2 sm:col-span-1">
											<FormLabel className="whitespace-nowrap">Title</FormLabel>
											<FormMessage />
										</div>
										<FormControl className="sm:col-span-2">
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="description"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-3">
										<div className="flex items-center justify-start gap-2 sm:col-span-1">
											<FormLabel className="whitespace-nowrap">Description</FormLabel>
											<FormMessage />
										</div>
										<FormControl className="sm:col-span-2">
											<Textarea {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="filesToKeep"
							render={({ field: { onChange, value } }) => {
								return (
									<>
										<FormItem>
											<div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-3">
												<div className="flex items-center justify-start gap-2">
													<FormLabel className="whitespace-nowrap">Files</FormLabel>
													<FormMessage />
												</div>
												<FormControl className="sm:col-span-2">
													<div className="relative">
														<Input
															className="hidden"
															type="text"
															placeholder=""
															value={value}
															onChange={onChange}
														/>
													</div>
												</FormControl>
											</div>
										</FormItem>
										<div>
											{value &&
												Object.values(value).length > 0 &&
												Object.values(value as DocumentFile[]).map((file, index) => {
													return (
														<div
															key={file.name}
															className="flex max-w-full items-center justify-between gap-2"
														>
															<span className="w-40 truncate sm:w-auto" title={file.name}>
																{file.name}
															</span>
															<div className="flex items-center gap-1">
																<Button
																	variant="outline"
																	size="icon"
																	asChild
																	style={{ background: "#4CAF50", width: "28px", height: "28px" }}
																>
																	<a className="flex items-center justify-center" href={file.url}>
																		<Download color="#fff" size={20} />
																	</a>
																</Button>
																<Button
																	type="button"
																	variant="outline"
																	size="icon"
																	style={{ background: "#F44336", width: "28px", height: "28px" }}
																	onClick={() => handleDeleteExistingFile(index)}
																>
																	<Trash2 color="#fff" size={20} />
																</Button>
															</div>
														</div>
													);
												})}
										</div>
									</>
								);
							}}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="newFiles"
							render={({ field: { onChange, value } }) => {
								return (
									<>
										<FormItem>
											<div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-3">
												<div className="flex items-center justify-start gap-2">
													<FormLabel className="whitespace-nowrap">New Files</FormLabel>
													<FormMessage />
												</div>
												<FormControl className="sm:col-span-2">
													<div className="relative">
														<label>
															<span className="inline-flex h-10 w-full cursor-pointer items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 py-2 text-sm font-semibold text-primary-foreground ring-offset-background transition-colors hover:bg-primary/90">
																Upload files
															</span>
															<Input
																className="hidden"
																type="file"
																placeholder=""
																value={""}
																onChange={(event) => {
																	onChange(
																		value
																			? [
																					...value,
																					...(event.target.files
																						? Array.from(event.target.files)
																						: []),
																				]
																			: event.target.files,
																	);
																}}
																multiple
															/>
														</label>
													</div>
												</FormControl>
											</div>
										</FormItem>
										<div>
											{value &&
												Object.values(value).length > 0 &&
												Object.values(value as File[]).map((file, index) => {
													return (
														<div
															key={file.name}
															className="flex max-w-full items-center justify-between gap-2"
														>
															<span className="w-40 truncate sm:w-auto" title={file.name}>
																{file.name}
															</span>
															<div>
																<Button
																	type="button"
																	variant="outline"
																	size="icon"
																	style={{ background: "#F44336", width: "28px", height: "28px" }}
																	onClick={() => handleDeleteFile(index)}
																>
																	<Trash2 color="#fff" size={20} />
																</Button>
															</div>
														</div>
													);
												})}
										</div>
									</>
								);
							}}
						/>
					</div>
					<div className="flex justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};

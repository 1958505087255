import * as z from "zod";

import { suggestionDescriptionZodRule, suggestionTitleZodRule } from "@/lib/zod";

export const formSchema = z.object({
	visibility: z.enum(["public", "private"], {
		required_error: "Visibility is required",
	}),
	publicTitle: suggestionTitleZodRule,
	publicDescription: suggestionDescriptionZodRule,
});

export type FormType = z.infer<typeof formSchema>;

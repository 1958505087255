import * as z from "zod";

import { suggestionDescriptionZodRule, suggestionTitleZodRule } from "@/lib/zod";

export const formSchema = z.object({
	title: suggestionTitleZodRule,
	description: suggestionDescriptionZodRule,
});

export type FormType = z.infer<typeof formSchema>;

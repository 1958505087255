import { useState } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

// import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTrigger,
} from "@/components/ui/dialog";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";

import { cn } from "@/lib/utils";

interface Props {
    disabled?: boolean;
    placeholder?: string;
    onSelect: (date: Date | undefined) => void;
    selected: Date | undefined;
    defaultMonth?: Date | undefined;
    fromDate?: Date | undefined;
}

function CalendarPicker({
    disabled=false,
    placeholder="Select date",
    onSelect,
    selected,
    defaultMonth,
    fromDate
}: Props) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onSelectHandler = (date: Date | undefined) => {
        onSelect(date);
        setIsPopoverOpen(false);
    };

    return (
		// <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        //     <PopoverTrigger asChild>
        //         <Button
        //             disabled={disabled}
        //             variant={"outline"}
        //             className={cn(
        //                 "w-full justify-start text-left font-normal",
        //                 !selected && "text-muted-foreground",
        //             )}
        //         >
        //             <CalendarIcon className="mr-2 h-4 w-4" />
        //             {selected ? format(selected, "y-MM-dd") : <span>{placeholder}</span>}
        //         </Button>
        //     </PopoverTrigger>
        //     <PopoverContent className="w-auto p-0 z-[51]">
        //         <Calendar mode="single" 
        //             selected={selected} 
        //             onSelect={onSelectHandler} 
        //             initialFocus
        //             defaultMonth={defaultMonth}

        //             fromDate={fromDate}
        //         />
        //     </PopoverContent>
        // </Popover>

        <Dialog open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
            <DialogTrigger asChild>
                <Button
                    disabled={disabled}
                    variant={"outline"}
                    className={cn(
                        "w-full justify-start text-left font-normal",
                        !selected && "text-muted-foreground",
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {selected ? format(selected, "y-MM-dd") : <span>{placeholder}</span>}
                </Button>
            </DialogTrigger>
            <DialogContent className="w-auto p-0 z-[51]">
                <DialogHeader className="mb-4"></DialogHeader>
                <Calendar mode="single" 
                    selected={selected} 
                    onSelect={onSelectHandler} 
                    initialFocus
                    defaultMonth={defaultMonth}

                    fromDate={fromDate}
                />
            </DialogContent>
        </Dialog>
	);
}
CalendarPicker.displayName = "CalendarPicker";

export { CalendarPicker };

import { ChevronsUpDown } from "lucide-react";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import {
	CollapsibleContent,
	CollapsibleTrigger,
	Collapsible as ShadCollapsible,
} from "@/components/ui/collapsible";

type Props = { children: React.ReactNode };

export const Collapsible = ({ children }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className="col-span-12 rounded-md border border-gray-300 text-center">
			<ShadCollapsible open={isOpen} onOpenChange={setIsOpen} className="space-y-2">
				<div className={`rounded-md flex items-center justify-between space-x-4 ${isOpen ? "bg-zinc-200" : ""}`}>
					<CollapsibleTrigger asChild>
						<Button
							variant="ghost"
							size="sm"
							className="flex w-full items-center justify-center p-0"
						>
							<ChevronsUpDown className="h-4 w-4" />
							<span className="sr-only">Toggle</span>
						</Button>
					</CollapsibleTrigger>
				</div>
				<CollapsibleContent className="space-y-2">
					<div className="mt-2 grid grid-cols-12 gap-2 px-2 pb-2">{children}</div>
				</CollapsibleContent>
			</ShadCollapsible>
		</div>
	);
};

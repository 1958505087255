/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useWatch, type Control } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useGetDepartmentsAndPositionsQuery } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/hooks/useAuth";

type Props = {
	control: Control<any>;
	setValue: any;
};

type Department = {
	id: number;
	name: string;
	positions: Position[];
};

type Position = {
	id: number;
	name: string;
};

export const DepartmentAndPositionsDependentSelects = ({ control }: Props) => {
	const auth = useAuth();
	const params = useParams();
	const vesselId = auth?.user?.vessel?.id || params.vesselId;

	const { data: departmentsResponse, isLoading } = useGetDepartmentsAndPositionsQuery({ vesselId });

	const departmentValue = useWatch({
		control,
		name: "department",
	});

	const [departmentOptions, setDepartmentOptions] = useState<Department[]>([]);
	const [positionOptions, setPositionOptions] = useState<Position[]>([]);

	useEffect(() => {
		if (departmentsResponse?.data) {
			// filter out departments without any positions
			const filteredDepartments = departmentsResponse.data.filter(
				(d: Department) => d.positions.length > 0,
			);
			setDepartmentOptions(filteredDepartments);
		}
	}, [departmentsResponse]);

	useEffect(() => {
		if (departmentOptions.length > 0 && departmentValue) {
			const department = departmentOptions.find((d) => String(d.id) === departmentValue);

			if (department) {
				setPositionOptions(department.positions);
			}
		}
	}, [departmentOptions, departmentValue]);

	// useEffect(() => {
	// 	setValue("department", "0");
	// }, [departmentOptions]);

	return (
		<>
			<div className="flex flex-col space-y-1.5">
				<FormField
					control={control}
					name="department"
					render={({ field }) => {
						return (
							<FormItem>
								<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
									<div className="flex items-center justify-start gap-2">
										<FormLabel className="whitespace-nowrap">Department</FormLabel>
										<FormMessage />
									</div>
									<FormControl>
										{isLoading ? (
											<Skeleton className="h-10 w-full rounded-md" />
										) : (
											<Select onValueChange={field.onChange} defaultValue={field.value}>
												<SelectTrigger>
													<SelectValue placeholder="Select Department" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														{departmentOptions.map((department) => (
															<SelectItem key={department.name} value={String(department.id)}>
																{department.name}
															</SelectItem>
														))}
													</SelectGroup>
												</SelectContent>
											</Select>
										)}
									</FormControl>
								</div>
							</FormItem>
						);
					}}
				/>
			</div>
			<div className="flex flex-col space-y-1.5">
				<FormField
					control={control}
					name="position"
					render={({ field }) => (
						<FormItem>
							<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
								<div className="flex items-center justify-start gap-2">
									<FormLabel className="whitespace-nowrap">Position</FormLabel>
									<FormMessage />
								</div>

								<FormControl>
									{isLoading ? (
										<Skeleton className="h-10 w-full rounded-md" />
									) : (
										<Select
											onValueChange={field.onChange}
											defaultValue={field.value}
											disabled={!departmentValue}
										>
											<SelectTrigger>
												<SelectValue placeholder="Select Position" />
											</SelectTrigger>
											<SelectContent>
												<SelectGroup>
													{positionOptions.map((position) => (
														<SelectItem key={position.name} value={String(position.id)}>
															{position.name}
														</SelectItem>
													))}
												</SelectGroup>
											</SelectContent>
										</Select>
									)}
								</FormControl>
							</div>
						</FormItem>
					)}
				/>
			</div>
		</>
	);
};

import { type ColumnDef } from "@tanstack/react-table";

import { type ManagerSuggestion, type SuggestionVotes } from "@/types/Suggestions";

export const columns: ColumnDef<ManagerSuggestion>[] = [
	{
		accessorKey: "title",
		header: "Title",
		cell: ({ row }) => <div>{row.getValue("title")}</div>,
	},
	{
		accessorKey: "description",
		header: "Description",
		cell: ({ row }) => <div>{row.getValue("description")}</div>,
	},
	{
		accessorKey: "status",
		header: "Status",
		cell: ({ row }) => <div>{row.getValue("status")}</div>,
	},
	{
		accessorKey: "votes",
		header: "Total Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.total || "-"}</div>;
		},
	},
	{
		accessorKey: "votes",
		header: "Up Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.up || "-"}</div>;
		},
	},
	{
		accessorKey: "votes",
		header: "Down Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.down || "-"}</div>;
		},
	},
	// {
	// 	id: "actions",
	// 	header: () => <div>Actions</div>,
	// 	cell: ({ row }) => {
	// 		const suggestionId = row.original.id;

	// 		return <SuggestionVoteButtons suggestionId={suggestionId} />;
	// 	},
	// },
];

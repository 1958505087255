import { apiSlice } from "@/app/api/apiSlice";

export const suggestionsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSuggestions: builder.query({
			query: () => `/api/suggestions`,
			providesTags: ["AdminSuggestions"],
		}),
		approveSuggestion: builder.mutation({
			query: ({ suggestionId, data }) => ({
				url: `/api/suggestions/${suggestionId}/approve`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["AdminSuggestions"]),
		}),
		declineSuggestion: builder.mutation({
			query: ({ suggestionId }) => ({
				url: `/api/suggestions/${suggestionId}/decline`,
				method: "PUT",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["AdminSuggestions"]),
		}),
		archiveSuggestion: builder.mutation({
			query: ({ suggestionId }) => ({
				url: `/api/suggestions/${suggestionId}/archive`,
				method: "PUT",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["AdminSuggestions"]),
		}),
		restoreSuggestion: builder.mutation({
			query: ({ suggestionId }) => ({
				url: `/api/suggestions/${suggestionId}/restore`,
				method: "PUT",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["AdminSuggestions"]),
		}),
		removeSuggestion: builder.mutation({
			query: ({ suggestionId }) => ({
				url: `/api/suggestions/${suggestionId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["AdminSuggestions"]),
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetSuggestionsQuery,
	useApproveSuggestionMutation,
	useDeclineSuggestionMutation,
	useArchiveSuggestionMutation,
	useRestoreSuggestionMutation,
	useRemoveSuggestionMutation,
} = suggestionsApiSlice;

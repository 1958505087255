import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { type FormProps } from "@/types/Form";

import { ButtonLoader } from "@/components/ButtonLoader";
import { formSchema, type FormType } from "./formSchema";

export const VesselUpsertForm = ({
	onSubmit,
	isLoading,
	defaultValues = {
		name: "",
		imo: "",
		leave_balance_type: 0,
	},
}: FormProps<FormType>) => {
	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const { handleSubmit, control } = form;

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Vessel name</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="imo"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Vessel IMO</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="leave_balance_type"
							render={({ field }) => {
								return (
									<FormItem>
										<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
											<div className="flex items-center justify-start gap-2">
												<FormLabel className="whitespace-nowrap">Leave Balance Type</FormLabel>
												<FormMessage />
											</div>
											<FormControl>
												<Select onValueChange={field.onChange} defaultValue={String(field.value)}>
													<SelectTrigger>
														<SelectValue placeholder="Select Type" />
													</SelectTrigger>
													<SelectContent>
														<SelectGroup>
															<SelectItem value={String(1)}>Type 1</SelectItem>
															<SelectItem value={String(2)}>Type 2</SelectItem>
															<SelectItem value={String(3)}>Both</SelectItem>
														</SelectGroup>
													</SelectContent>
												</Select>
											</FormControl>
										</div>
									</FormItem>
								);
							}}
						/>
					</div>
					<div className="flex justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};

import { ChevronsUpDown } from "lucide-react";
import { useEffect, useState, type Dispatch, type SetStateAction } from "react";

import { KnowledgeBaseReaderTriggerButton } from "@/components/KnowledgeBaseReaderTriggerButton";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { type OpenStates } from "@/types/AccordionOpenStates";
import { type Section } from "@/types/KnowledgeBase";

type Props = {
	triggerArticleChange: Dispatch<SetStateAction<number | null>>;
	sections: Section[];
	visibleArticleId: number | null;
};

export const KnowledgeBaseReaderAccordion = ({
	triggerArticleChange,
	sections,
	visibleArticleId,
}: Props) => {
	const [openStates, setOpenStates] = useState<OpenStates>({});

	useEffect(() => {
		if (sections.length > 0 && Object.keys(openStates).length === 0) {
			const sectionWithActiveArticle = sections.find((section) =>
				section.articles.some((article) => article.id === visibleArticleId),
			);
			if (sectionWithActiveArticle) {
				setOpenStates({ [sectionWithActiveArticle.id]: true });
			} else {
				setOpenStates({ [sections[0].id]: true });
			}
		}
	}, [openStates, sections, visibleArticleId]);

	const toggleOpenState = (sectionId: number) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[sectionId]: !prevStates[sectionId],
		}));
	};

	return (
		<div className="flex flex-col gap-3">
			{sections.map((section, _index) => {
				return (
					<Collapsible
						key={section.id}
						open={openStates[section.id] || false}
						onOpenChange={() => toggleOpenState(section.id)}
						className="space-y-2"
					>
						<div className="flex items-center justify-between space-x-4">
							{section.articles.length > 0 ? (
								<CollapsibleTrigger asChild>
									<Button
										variant="ghost"
										size="sm"
										className="flex w-full items-center justify-between"
									>
										<h4 className="w-[88%] truncate text-left text-sm font-semibold">
											{section.name}
										</h4>
										<ChevronsUpDown className="h-4 w-4" />
										<span className="sr-only">Toggle</span>
									</Button>
								</CollapsibleTrigger>
							) : (
								<div className="flex w-full items-center justify-between px-3">
									<h4 className="truncate text-sm font-semibold">{section.name}</h4>
								</div>
							)}
						</div>
						<CollapsibleContent className="space-y-2">
							{section.articles.length > 0 &&
								section.articles.map((article) => {
									return (
										<div
											key={article.id}
											className="flex items-center justify-between rounded-md border font-mono text-sm"
										>
											<KnowledgeBaseReaderTriggerButton
												triggerArticleChange={triggerArticleChange}
												articleId={article.id}
												visibleArticleId={visibleArticleId}
												title={article.title}
											/>
										</div>
									);
								})}
						</CollapsibleContent>
					</Collapsible>
				);
			})}
		</div>
	);
};

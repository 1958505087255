import { Button } from "@/components/ui/button";

type Props = {
	triggerArticleChange: (articleId: number) => void;
	articleId: number;
	title: string;
	visibleArticleId: number | null;
};

export const KnowledgeBaseReaderTriggerButton = ({
	triggerArticleChange,
	articleId,
	title,
	visibleArticleId,
}: Props) => {
	return (
		<Button
			variant="ghost"
			className={`h-auto w-full px-4 py-3 font-normal ${
				visibleArticleId === articleId ? "bg-ground/40" : ""
			}`}
			onClick={() => {
				triggerArticleChange(articleId);
			}}
		>
			<span className="truncate">{title}</span>
		</Button>
	);
};

import { Pen } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useUpdateDepartmentMutation } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { DepartmentUpsertForm } from "@/components/forms/DepartmentUpsertForm";
import { type FormType } from "@/components/forms/DepartmentUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	department: { id: number; name: string };
};

export const EditDepartmentDialog = ({ department }: Props) => {
	const { vesselId } = useParams();
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateDepartmentMutation, { isLoading }] = useUpdateDepartmentMutation();

	const handleUpdateDepartment = async ({ name }: FormType) => {
		await updateDepartmentMutation({ vesselId, name, departmentId: department.id })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated department.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#2055A3" }}>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Department</DialogTitle>
				</DialogHeader>
				<DepartmentUpsertForm
					onSubmit={handleUpdateDepartment}
					isLoading={isLoading}
					defaultValues={department}
				/>
			</DialogContent>
		</Dialog>
	);
};

import { useAuth } from "@/hooks/useAuth";
import { AdminSuggestionsPage } from "@/pages/admin/AdminSuggestionsPage";
import { ManagerSuggestionsPage } from "@/pages/manager/ManagerSuggestionsPage";

export const SuggestionsPage = () => {
	const auth = useAuth();

	const user = auth?.user;
	const isAdmin = user?.role.name === "admin";

	return isAdmin ? <AdminSuggestionsPage /> : <ManagerSuggestionsPage />;
};

import { VesselInfoBar } from "@/components/VesselInfoBar";
import { Outlet } from "react-router-dom";

export const AdminVesselPagesLayout = () => {
	return (
		<>
			<VesselInfoBar />
			<Outlet />
		</>
	);
};

import { DateInfo } from "@/components/calendar/LabelCell/DateInfo";

type Props = { 
	months: [string, string]; 
	years: [number, number] 
};

export const MonthYearCell = ({ months, years }: Props) => {
	const [firstCellMonth, lastCellMonth] = months;
	const [firstCellYear, lastCellYear] = years;

	return (
		<div className="ctrl_month-cell py-1 flex flex-col items-center justify-center rounded-md border border-gray-300 col-span-3 text-xs lg:text-sm font-medium">
			<div className="flex items-center">
				<DateInfo start={firstCellMonth} end={lastCellMonth} isMonths={true} />
			</div>
			<span>
				<DateInfo start={firstCellYear} end={lastCellYear} />
			</span>
		</div>
	);
};

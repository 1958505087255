import { useBrandingLogo } from "@/hooks/useBrandingLogo";
import logoHorizontal from "../assets/logo-hotizontal.svg";

type Props = { className?: string };

export const Logo = ({ className = "" }: Props) => {
	const brandingLogoUrl = useBrandingLogo();

	if (brandingLogoUrl) {
		return <img className={className} src={brandingLogoUrl} alt="Logo" />;
	}

	return <img className={className} src={logoHorizontal} alt="Rotat Yachts Logo" />;
};

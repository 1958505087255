import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectConfig } from "@/app/slices/configSlice";

export const useConfig = () => {
	const config = useSelector(selectConfig);

	return useMemo(() => config, [config]);
};

import * as z from "zod";

import {
	kbContentZodRule,
	kbDescriptionZodRule,
	kbSectionZodRule,
	kbTitleZodRule,
} from "@/lib/zod";

export const formSchema = z.object({
	title: kbTitleZodRule,
	description: kbDescriptionZodRule,
	section: kbSectionZodRule,
	isPublished: z.coerce.boolean(),
	content: kbContentZodRule,
});

export type FormType = z.infer<typeof formSchema>;

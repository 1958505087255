import { useConfig } from "@/hooks/useConfig";
import { useEffect, useState } from "react";

export function useBrandingLogo() {
	const { brandingData } = useConfig();

	const [logoUrl, setLogoUrl] = useState<string | null>(null);

	useEffect(() => {
		// Retrieve stored values
		const storedLogoUrl = localStorage.getItem("logo-url");

		// set logo URL from storage
		if (storedLogoUrl) {
			setLogoUrl(storedLogoUrl);
		}

		// set logo URL from config and overwrite storage
		if (brandingData?.logoUrl) {
			localStorage.setItem("logo-url", brandingData.logoUrl);
			setLogoUrl(brandingData.logoUrl);
		}

		// remove stored values if branding data is null
		if (brandingData === null && storedLogoUrl) {
			localStorage.removeItem("logo-url");
			setLogoUrl(null);
		}
	}, [brandingData]);

	return logoUrl;
}

import { zodResolver } from "@hookform/resolvers/zod";
import { SketchPicker } from "react-color";
import { useForm } from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { type FormProps } from "@/types/Form";

import { ButtonLoader } from "@/components/ButtonLoader";
import { handleColorsChange } from "@/lib/react-color";
import { Eye } from "lucide-react";
import { formSchema, type FormType } from "./formSchema";

type Props = FormProps<FormType> & {
	logoUrl: string;
};

export const BrandingForm = ({
	logoUrl,
	onSubmit,
	isLoading,
	defaultValues = {
		meta: "",
		domain: "",
		logo: null,
		backgroundColor: "rgba(0, 0, 0, 1)",
		topBarColor: "rgba(0, 0, 0, 1)",
		buttonColor: "rgba(0, 0, 0, 1)",
	},
}: Props) => {
	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const { handleSubmit, control } = form;

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="meta"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Meta</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="domain"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Domain</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="logo"
							render={({ field: { onChange, value } }) => {
								return (
									<FormItem>
										<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
											<div className="flex items-center justify-start gap-2">
												<FormLabel className="whitespace-nowrap">Logo</FormLabel>
												<FormMessage />
											</div>
											<div className="relative">
												{logoUrl && (
													<Button
														variant="outline"
														size="icon"
														asChild
														style={{ background: "#4CAF50", position: "absolute", left: "-40px" }}
													>
														<a href={logoUrl} target="_blank" rel="noreferrer">
															<Eye color="#fff" />
														</a>
													</Button>
												)}
												<FormControl>
													<Input
														type="file"
														placeholder=""
														// @ts-expect-error to be fixed
														value={value?.fileName}
														onChange={(event) => {
															// @ts-expect-error to be fixed
															onChange(event.target.files[0]);
														}}
													/>
												</FormControl>
											</div>
										</div>
									</FormItem>
								);
							}}
						/>
					</div>
					<h2 className="mt-4 text-xl font-semibold">Color palette</h2>
					<div className="flex gap-6">
						<FormField
							control={control}
							name="backgroundColor"
							render={({ field: { onChange, value } }) => {
								return (
									<FormItem>
										<div className="flex items-center justify-center gap-2">
											<FormLabel className="whitespace-nowrap">Background color</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<SketchPicker
												onChangeComplete={(value) => handleColorsChange(value, onChange)}
												color={value}
											/>
										</FormControl>
									</FormItem>
								);
							}}
						/>
						<FormField
							control={control}
							name="topBarColor"
							render={({ field: { onChange, value } }) => {
								return (
									<FormItem>
										<div className="flex items-center justify-center gap-2">
											<FormLabel className="whitespace-nowrap">Top bar color</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<SketchPicker
												onChangeComplete={(value) => handleColorsChange(value, onChange)}
												color={value}
											/>
										</FormControl>
									</FormItem>
								);
							}}
						/>
						<FormField
							control={control}
							name="buttonColor"
							render={({ field: { onChange, value } }) => {
								return (
									<FormItem>
										<div className="flex items-center justify-center gap-2">
											<FormLabel className="whitespace-nowrap">Button color</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											<SketchPicker
												onChangeComplete={(value) => handleColorsChange(value, onChange)}
												color={value}
											/>
										</FormControl>
									</FormItem>
								);
							}}
						/>
					</div>
					<div className="flex justify-between">
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};

import { Toaster } from "@/components/ui/toaster";

import { Outlet } from "react-router-dom";

export const Layout = () => {
	// const triggerError = () => {
	// 	throw new Error("Test error");
	// };

	return (
		<div className="h-full bg-ground">
			{/* <button className="absolute z-50 bottom-2 right-2" onClick={triggerError}>
				Trigger Error
			</button> */}
			<Outlet />
			<Toaster />
		</div>
	);
};

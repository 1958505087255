import { Pen } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useUpdateManagerMutation } from "@/app/api/slices/admin/managersApiSlice";
import { UserUpsertForm } from "@/components/forms/UserUpsertForm";
import { type FormType } from "@/components/forms/UserUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { type User } from "@/types/User";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	managerId: number;
	manager: User;
};

export const EditManagerDialog = ({ managerId, manager }: Props) => {
	const { vesselId } = useParams();
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateManagerMutation, { isLoading }] = useUpdateManagerMutation();

	const handleUpdateManager = async (data: FormType) => {
		await updateManagerMutation({ vesselId, managerId, data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully updated manager.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#2055A3" }}>
					<Pen color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Edit Manager</DialogTitle>
				</DialogHeader>
				<UserUpsertForm
					onSubmit={handleUpdateManager}
					isLoading={isLoading}
					defaultValues={{
						...manager,
						role: manager.role.name,
						department: String(manager?.department?.id || "0"),
						position: String(manager?.position?.id || "0"),
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};

import { createSlice } from "@reduxjs/toolkit";

import { authApiSlice } from "@/app/api/slices/authApiSlice";
import { type RootState } from "@/app/store";

type User = {
	id: number;
	email: string;
	phone: string;
	name: string;
	role: { id: number; name: string };
	vessel: { id: number; name: string; imo: string, leave_balance_type: number | null };
	isFromDemoAccount?: boolean;
};

type AuthState = {
	user: User | null;
	status: "idle" | "loading" | "succeeded" | "failed";
};

export const authSlice = createSlice({
	name: "auth",
	initialState: { user: null, status: "idle" } as AuthState,
	reducers: {
		logout(state) {
			state.user = null;
			state.status = "idle";
		},
		magicLogin(state, action) {
			state.user = action.payload.data.user;
			state.status = "succeeded";
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(authApiSlice.endpoints.login.matchFulfilled, (state, { payload }) => {
				state.user = payload.data.user;
				state.status = "succeeded";
			})
			.addMatcher(authApiSlice.endpoints.getCurrentUser.matchPending, (state) => {
				state.status = "loading";
			})
			.addMatcher(authApiSlice.endpoints.getCurrentUser.matchFulfilled, (state, { payload }) => {
				state.user = payload.data;
				state.status = "succeeded";
			})
			.addMatcher(authApiSlice.endpoints.getCurrentUser.matchRejected, (state) => {
				state.user = null;
				state.status = "failed";
			});
	},
});

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectAuthStatus = (state: RootState) => state.auth.status;

import * as z from "zod";

export const formSchema = z.object({
	meta: z.string().min(1, {
		message: `Meta is required. If custom meta is not necessary, the default meta value should be used.`,
	}),
	domain: z.string().min(2, {
		message: `Domain is required. If a specific domain is not needed, please use the default application domain.`,
	}),
	logo: z.instanceof(File).nullable(),
	backgroundColor: z.string().min(0),
	topBarColor: z.string().min(0),
	buttonColor: z.string().min(0),
});

export type FormType = z.infer<typeof formSchema>;

import * as z from "zod";

import { passwordZodRule } from "@/lib/zod";

export const formSchema = z
	.object({
		password: passwordZodRule,
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: ["confirmPassword"],
	});

export type FormType = z.infer<typeof formSchema>;

import { useAuth } from "@/hooks/useAuth";
import { createContext, useContext, useEffect, useState, type ReactNode } from "react";

const JoyrideContext = createContext({
	run: false,
	isJoyrideActive: false,
	setIsJoyrideActive: (_state: boolean) => {},
	startJoyride: () => {},
	stopJoyride: () => {},
	resetJoyride: () => {},
	stepIndex: 0,
	setStepIndex: (_index: number) => {},
});

export const JoyrideProvider = ({ children }: { children: ReactNode }) => {
	const auth = useAuth();
	const role = auth?.user?.role.name;

	const [stepIndex, setStepIndex] = useState(0);
	const [run, setRun] = useState(false);
	const [isJoyrideActive, setIsJoyrideActive] = useState(false);

	useEffect(() => {
		if (window.innerWidth > 1279 && localStorage.joyride !== "1" && role === "manager") {
			setRun(true);
			setIsJoyrideActive(true);
			localStorage.joyride = "1";
		} else {
			setRun(false);
		}
	}, [role]);

	const startJoyride = () => {
		if (role !== "manager") return;
		if (!isJoyrideActive) return;
		setRun(true);
	};

	const stopJoyride = () => {
		if (role !== "manager") return;
		setRun(false);
	};

	const resetJoyride = () => {
		if (role !== "manager") return;
		setStepIndex(0);
		setIsJoyrideActive(true);
		setRun(true);
	};

	const value = {
		run,
		isJoyrideActive,
		setIsJoyrideActive,
		startJoyride,
		stopJoyride,
		resetJoyride,
		stepIndex,
		setStepIndex,
	};

	return <JoyrideContext.Provider value={value}>{children}</JoyrideContext.Provider>;
};

export const useJoyride = () => useContext(JoyrideContext);

export const userProfileSteps = [
	// 55 - PROFILE
	{
		target: "#change-password-form",
		content: (
			<div>
				<p>You can change password here.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 56 - PROFILE
	{
		target: "#change-profile-form",
		content: (
			<div>
				<p>You can change email and phone here.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 57 - PROFILE
	{
		target: "#remove-account-button",
		content: (
			<div>
				<p>You can request account remove here.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
];

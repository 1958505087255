import { CalendarFold } from "lucide-react";

type Props = {
    start: string | number;
    end: string | number;
    isMonths?: boolean;
};

export const DateInfo = ({start, end, isMonths=false} : Props) => {
	if (start === end) {
		return start;
	}

	if(isMonths) {
		return (<>
			<CalendarFold className="me-1" height={16} width={16}></CalendarFold><span>{start} / {end}</span>
		</>);
	}

	return (<span>{start} / {end}</span>);
};


import * as z from "zod";

export const formSchema = z.object({
	// isActive: z.string().min(0),
	subscriptionTierId: z.string().min(1, { message: "Please select a subscription tier" }),
	// crewMembersLimit: z.number().min(0),
	// paymentUntil: z.string().min(0),
});

export type FormType = z.infer<typeof formSchema>;

import { Trash2 } from "lucide-react";
import { useState } from "react";

import { useRemoveSubscriptionTierMutation } from "@/app/api/slices/admin/subscriptionsApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = { tierId: number };

export const RemoveSubscriptionTierDialog = ({ tierId }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [removeSubscriptionTierMutation, { isLoading }] = useRemoveSubscriptionTierMutation();

	const handleRemoveSubscriptionTier = async () => {
		await removeSubscriptionTierMutation({ tierId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully removed subscription tier.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#F44336" }}>
					<Trash2 color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Are you absolutely sure?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. This will permanently delete subscription&nbsp;tier.
					</DialogDescription>
				</DialogHeader>
				<div className="flex justify-between">
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							Cancel
						</Button>
					</DialogClose>
					<Button onClick={handleRemoveSubscriptionTier} disabled={isLoading}>
						<ButtonLoader isLoading={isLoading} />
						Continue
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

import { X } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { useUpdateUserRequestMutation } from "@/app/api/slices/manager/notificationsApiSlice";
import { RequestUpdateForm } from "@/components/forms/RequestUpdateForm";
import { type FormType } from "@/components/forms/RequestUpdateForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { type UserRequest } from "@/types/UserRequest";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	request: UserRequest;
};

export const RequestDeclineDialog = ({ request }: Props) => {
	const auth = useAuth();
	const params = useParams();
	const vesselId = auth?.user?.vessel?.id || params.vesselId;

	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [updateUserRequestMutation, { isLoading }] = useUpdateUserRequestMutation();

	const handleDeclineRequest = async ({ note }: FormType) => {
		await updateUserRequestMutation({
			vesselId,
			requestId: request.id,
			data: { ...request, note: note || "", status: "declined" },
		})
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "The request was successfully hidden.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#F44336" }}>
					<X color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Decline Request</DialogTitle>
				</DialogHeader>
				<RequestUpdateForm onSubmit={handleDeclineRequest} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

import { useState } from "react";
import { useParams } from "react-router-dom";

import { useAddPositionMutation } from "@/app/api/slices/admin/departmentsAndPositionsApiSlice";
import { PositionUpsertForm } from "@/components/forms/PositionUpsertForm";
import { type FormType } from "@/components/forms/PositionUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	departmentId: number;
};

export const AddPositionDialog = ({ departmentId }: Props) => {
	const { vesselId } = useParams();
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addPositionMutation, { isLoading }] = useAddPositionMutation();

	const handleAddPosition = async ({ name, minimum }: FormType) => {
		await addPositionMutation({ vesselId, name, minimum, departmentId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added position.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button className="uppercase">Add position</Button>
			</DialogTrigger>
			<DialogContent className="overflow-visible sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Add Position</DialogTitle>
				</DialogHeader>
				<PositionUpsertForm onSubmit={handleAddPosition} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

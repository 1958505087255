import { RequestAcceptDialog } from "@/components/dialogs/RequestAcceptDialog";
import { RequestDeclineDialog } from "@/components/dialogs/RequestDeclineDialog";
import { RequestHideDialog } from "@/components/dialogs/RequestHideDialog";
import { type UserRequest } from "@/types/UserRequest";
import { formatDate } from "@/utils/formatDate";

type Props = {
	request: UserRequest;
	index: number;
};

export const GridItem = ({ request, index }: Props) => {
	const {
		id,
		createdAt,
		description,
		note,
		createdBy: { firstName, lastName },
		status,
	} = request;

	return (
		<div>
			<div className="flex justify-between">
				<h3 className="text-xl font-semibold">Request #{index}</h3>
				<span>{formatDate(createdAt)}</span>
			</div>
			<div className="mb-2 border-t border-black"></div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Created by</span>
				<span className="text-right">
					{firstName} {lastName}
				</span>
			</div>
			<div className="mb-2 border-t border-gray-200"></div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Description</span>
				<span className="text-right">{description}</span>
			</div>
			{note && (
				<>
					<div className="mb-2 border-t border-gray-200"></div>
					<div className="mb-2 grid grid-cols-2">
						<span className="font-semibold">Note</span>
						<span className="text-right">{note}</span>
					</div>
				</>
			)}
			<div className="mb-2 border-t border-gray-200"></div>
			<div className="mb-2 grid grid-cols-2">
				<span className="font-semibold">Actions</span>
				<div className="flex justify-end gap-2">
					{status === "pending" && (
						<>
							<RequestAcceptDialog request={request} />
							<RequestDeclineDialog request={request} />
						</>
					)}
					{(status === "accepted" || status === "declined") && <RequestHideDialog requestId={id} />}
				</div>
			</div>
		</div>
	);
};

export const crewMembersListingSteps = [
	// 25 - CREW MEMBERS LISTING
	{
		target: "#crew-listing-box",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Crew list with details and action buttons</h1>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 26 - CREW MEMBERS LISTING
	{
		target: "#add-crew-member-button",
		content: (
			<div>
				<p>Click the spotlight area to add a new crew member</p>
			</div>
		),
		disableBeacon: true,
		disableOverlayClose: true,
		hideCloseButton: true,
		hideFooter: true,
		spotlightClicks: true,
		placement: "auto",
		styles: {
			tooltipContent: {
				padding: "10px",
			},
			spotlight: {
				boxShadow: "0px 0px 20px 10px rgba(251, 133, 0, 0.4)",
			},
		},
	},
	// 27 - CREW MEMBERS LISTING
	{
		target: "#add-crew-member-dialog",
		content: (
			<div>
				<p>Simple functionality to add new crew member</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 28 - CREW MEMBERS LISTING
	{
		target: "#add-crew-member-rotation-field",
		content: (
			<div>
				<p>Remember to pick the right rotation ☺️</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 29 - CREW MEMBERS LISTING
	{
		target: "#table-grid-switcher",
		content: (
			<div>
				<p>Perfect view and easy to navigate</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 30 - CREW MEMBERS LISTING
	{
		// target: "#crew-member-rotation-head",
		target: "#crew-members-table td:nth-of-type(3)",
		content: (
			<div>
				<p>In this column you can see the next three rotations of your crew member.</p>
				<p>Easy way to check upcoming changes.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 31 - CREW MEMBERS LISTING
	{
		// target: "#crew-member-rotation-partner-head",
		target: "#crew-members-table td:nth-of-type(9)",
		content: (
			<div>
				<p>If your crew member has rotational partner you will see it right here.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 32 - CREW MEMBERS LISTING
	{
		target: ".edit-crew-member-button",
		content: (
			<div>
				<p>Edit.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 33 - CREW MEMBERS LISTING
	{
		target: ".crew-member-rotation-calendar-button",
		content: (
			<div>
				<p>Rotation generator.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 34 - CREW MEMBERS LISTING
	{
		target: ".crew-member-remove-button",
		content: (
			<div>
				<p>Remove crew member.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 35 - CREW MEMBERS LISTING
	{
		target: ".crew-member-docs-button",
		content: (
			<div>
				<p>Add travel docs and instructions.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 36 - CREW MEMBERS LISTING
	{
		target: "#crew-member-listing-filters",
		content: (
			<div>
				<p>Simple way to organize the view and see what you need.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
];

import { apiSlice } from "@/app/api/apiSlice";

export const managersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getManagers: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/users?role=manager`,
			providesTags: ["Managers"],
		}),
		addManager: builder.mutation({
			query: ({ vesselId, data }) => ({
				url: `/api/vessels/${vesselId}/users`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Managers", "Users", "MyVesselInfo"]),
		}),
		updateManager: builder.mutation({
			query: ({ vesselId, managerId, data }) => ({
				url: `/api/vessels/${vesselId}/users/${managerId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: (_result, error) =>
				error ? [] : ["Managers", "Users", "MyVesselInfo", "LeaveBalance"],
		}),
		removeManager: builder.mutation({
			query: ({ vesselId, managerId }) => ({
				url: `/api/vessels/${vesselId}/users/${managerId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["Managers", "Users", "MyVesselInfo"]),
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetManagersQuery,
	useAddManagerMutation,
	useUpdateManagerMutation,
	useRemoveManagerMutation,
} = managersApiSlice;

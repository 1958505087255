import { useState } from "react";
import { Outlet } from "react-router-dom";

import { SideMenu } from "@/components/SideMenu";
import { TopBar } from "@/components/TopBar";
import { useNotificationBar } from "@/contexts/NotificationBarContext";

export const AppLayout = () => {
	const { BarNotifications } = useNotificationBar();
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleExpanded = () => {
		setIsExpanded((isExpanded) => !isExpanded);
	};

	return (
		<div className="flex h-full">
			<SideMenu isExpanded={isExpanded} onExpandedChange={toggleExpanded} />
			<div className="flex max-w-full flex-auto flex-col transition-all duration-300 sm:max-w-[calc(100%-64px)] lg:border-l">
				{BarNotifications}
				<TopBar onExpandedChange={toggleExpanded} />
				<div className="relative grow p-4">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

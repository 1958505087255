import { getMonthNameFromDate } from "@/utils/calendarUtils";
import { addDays } from "date-fns";
import { DateInfo } from "@/components/calendar/LabelCell/DateInfo";

type Props = {
	crewData: { id: number, name: string }[] | undefined;
	firstStartDate: Date;
	daysGenerated: number;
	weekType?: (string | undefined)[];
};

export const LabelCells = ({crewData, firstStartDate, daysGenerated, weekType }: Props) => {
	const firstDayOfWeek = addDays(firstStartDate, daysGenerated);
	const lastDayOfWeek = addDays(firstStartDate, daysGenerated + 6);

	const firstDayOfWeekMonthName = getMonthNameFromDate(firstDayOfWeek);
	const lastDayOfWeekMonthName = getMonthNameFromDate(lastDayOfWeek);
	const firstDayOfWeekYear = firstDayOfWeek.getFullYear();
	const lastDayOfWeekYear = lastDayOfWeek.getFullYear();

	const [firstCellMonth, lastCellMonth] = [firstDayOfWeekMonthName, lastDayOfWeekMonthName];
	const [firstCellYear, lastCellYear] = [firstDayOfWeekYear, lastDayOfWeekYear];
	return (
		<>
			<div className={`flex flex-col items-center justify-center rounded-md border border-gray-300 col-span-2 control__label-cell-first text-xs font-medium`}>
				<div className="flex items-center">
					<DateInfo start={firstCellMonth} end={lastCellMonth} isMonths={true} />
				</div>
				<span>
					<DateInfo start={firstCellYear} end={lastCellYear} />
				</span>
			</div>

			<div className="ctrl_info-cell flex flex-col items-center justify-center rounded-md border border-gray-300 col-span-3 font-medium">
				<div className="flex flex-wrap">
					{crewData?.map((crewMember, index) => (
						<>
							<p key={crewMember.id} className="text-xs">{crewMember.name}</p>
							{
								index === 0 && crewData?.length > 1 && (
									<div className="text-xs px-1">/</div>
								)
							}
						</>
					))}
				</div>

				<div className="flex">
					{weekType?.map((week, index) => {
						if(!week) return null;

						return (
						<>
							<p key={index} className="text-xs">{week}</p>
							{
								index === 0 && weekType?.length > 1 && (
									<div className="text-xs px-1">/</div>
								)
							}
						</>
					)})}
				</div>
			</div>
		</>
	);
};

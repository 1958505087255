import { useDeclineSuggestionMutation } from "@/app/api/slices/admin/adminSuggestionsApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { type SuggestionReporter } from "@/types/Suggestions";
import { handleServerErrors } from "@/utils/handleServerErrors";
import { X } from "lucide-react";
import { useState } from "react";

type Props = {
	suggestionId: number;
	reporter: SuggestionReporter;
	suggestion: { title: string; description: string };
};

export const DeclineSuggestionDialog = ({ suggestionId, reporter, suggestion }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [declineSuggestionMutation, { isLoading }] = useDeclineSuggestionMutation();

	const handleDeclineSuggestion = async () => {
		await declineSuggestionMutation({ suggestionId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully declined suggestion.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#F44336" }}>
					<X color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Are you absolutely sure?</DialogTitle>
					<div>
						<div className="my-2 flex flex-col space-y-1.5 border-b border-t pb-4 pt-2">
							<div className="flex flex-col space-y-2">
								<div className="grid grid-cols-1 items-center gap-1">
									<div className="whitespace-nowrap font-semibold">Manager</div>
									<div>{reporter.firstName + " " + reporter.lastName}</div>
								</div>
								<div className="grid grid-cols-1 items-center gap-1">
									<div className="whitespace-nowrap font-semibold">Title</div>
									<div>{suggestion.title}</div>
								</div>
								<div className="grid grid-cols-1 items-center gap-1">
									<div className="whitespace-nowrap font-semibold">Description</div>
									<div>{suggestion.description}</div>
								</div>
							</div>
						</div>
					</div>
				</DialogHeader>
				<div className="flex justify-between">
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							Cancel
						</Button>
					</DialogClose>
					<Button onClick={handleDeclineSuggestion} disabled={isLoading}>
						<ButtonLoader isLoading={isLoading} />
						Continue
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

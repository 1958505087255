import { apiSlice } from "@/app/api/apiSlice";

export const paymentsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscriptionDetails: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/subscription-info`,
			providesTags: ["UserSubscription"],
		}),
		getStripePortalUrl: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/subscriptions-link`,
		}),
		getStripePaymentUrl: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/payment-link`,
		}),
		successPayment: builder.mutation({
			query: ({ vesselId }) => ({
				url: `/api/vessels/${vesselId}/payment-success`,
				method: "POST",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["UserSubscription"]),
		}),
		cancelPayment: builder.mutation({
			query: ({ vesselId }) => ({
				url: `/api/vessels/${vesselId}/payment-cancel`,
				method: "POST",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["UserSubscription"]),
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetSubscriptionDetailsQuery,
	useGetStripePortalUrlQuery,
	useGetStripePaymentUrlQuery,
	useSuccessPaymentMutation,
	useCancelPaymentMutation,
} = paymentsApiSlice;

import { apiSlice } from "@/app/api/apiSlice";

export const myVesselApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMyVesselInfo: builder.query({
			query: ({ param }) => (param ? `/api/my-vessel?date=${param}` : `/api/my-vessel`),
			providesTags: ["MyVesselInfo", "LeaveBalance"],
		}),
	}),
	overrideExisting: true,
});

export const { useGetMyVesselInfoQuery } = myVesselApiSlice;

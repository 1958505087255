import { useGetVesselsQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { VesselsTable } from "@/components/tables/VesselsTable";

export const VesselsListingPage = () => {
	const { data: response, isLoading, isFetching } = useGetVesselsQuery({});

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<WhiteBox>
			<VesselsTable data={response?.data || []} />
		</WhiteBox>
	);
};

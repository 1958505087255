type Props = {
	stats: {
		total: number;
		onboard: number;
		offboard: number;
	};
};

export const VesselStatistics = ({ stats }: Props) => {
	return (
		<>
			<div className="flex justify-between">
				<span>Number of crew</span>
				<span>{stats.total}</span>
			</div>
			<div className="flex justify-between">
				<span>Number of crew signed on</span>
				<span>{stats.onboard}</span>
			</div>
			<div className="flex justify-between">
				<span>Number of crew signed off</span>
				<span>{stats.offboard}</span>
			</div>
		</>
	);
};

import { TimerOff } from "lucide-react";
import { useState } from "react";

import { useTurnOffMagicLinkMutation } from "@/app/api/slices/admin/magicLinksApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	magicLinkId: number;
};

export const MagicLinkTurnOffDialog = ({ magicLinkId }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [turnOffMagicLinkMutation, { isLoading }] = useTurnOffMagicLinkMutation();

	const handleTurnOffMagicLink = async () => {
		await turnOffMagicLinkMutation({ magicLinkId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "The magic link was successfully turned off.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>
						<DialogTrigger asChild>
							<Button variant="outline" size="icon" style={{ background: "#F44336" }}>
								<TimerOff color="#fff" />
							</Button>
						</DialogTrigger>
					</TooltipTrigger>
					<TooltipContent>
						<p>Turn Off</p>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Are you absolutely sure?</DialogTitle>
					<DialogDescription>
						This action cannot be undone. This will permanently turn off magic link.
					</DialogDescription>
				</DialogHeader>
				<div className="flex justify-between">
					<DialogClose asChild>
						<Button type="button" variant="secondary">
							Cancel
						</Button>
					</DialogClose>
					<Button onClick={handleTurnOffMagicLink} disabled={isLoading}>
						<ButtonLoader isLoading={isLoading} />
						Continue
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

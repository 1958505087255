import { apiSlice } from "@/app/api/apiSlice";

export const vesselBrandingApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVesselBranding: builder.query({
			query: ({ vesselId }) => `/api/vessels/${vesselId}/branding`,
			providesTags: ["VesselBranding"],
		}),
		updateVesselBranding: builder.mutation({
			query: ({ vesselId, data }) => ({
				url: `/api/vessels/${vesselId}/branding`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["VesselBranding"]),
		}),
	}),
	overrideExisting: true,
});

export const { useGetVesselBrandingQuery, useUpdateVesselBrandingMutation } =
	vesselBrandingApiSlice;

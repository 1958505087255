import { apiSlice } from "@/app/api/apiSlice";

export const appConfigApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAppConfig: builder.query({
			query: () => `/api/config`,
			providesTags: ["VesselBranding"],
		}),
	}),
	overrideExisting: true,
});

export const { useGetAppConfigQuery } = appConfigApiSlice;

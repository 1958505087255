import { Link } from "react-router-dom";

import {
	useGetAdminArticlesQuery,
	useGetAdminSectionsQuery,
} from "@/app/api/slices/admin/adminKnowledgeBaseApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { KnowledgeBaseSectionsAccordion } from "@/components/accordions/KnowledgeBaseSectionsAccordion";
import { AddKnowledgeBaseSectionDialog } from "@/components/dialogs/AddKnowledgeBaseSectionDialog";
import { KnowledgeBaseArticlesTable } from "@/components/tables/KnowledgeBaseArticlesTable";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

export const AdminKnowledgeBasePage = () => {
	const {
		data: sectionsResponse,
		isLoading: isLoadingSections,
		isFetching: isFetchingSections,
	} = useGetAdminSectionsQuery({ params: null });
	const {
		data: articlesResponse,
		isLoading: isLoadingArticles,
		isFetching: isFetchingArticles,
	} = useGetAdminArticlesQuery({});

	if (isLoadingSections || isFetchingSections || isLoadingArticles || isFetchingArticles)
		return <InLayoutLoader />;

	return (
		<div className="mt-4 flex flex-wrap gap-4">
			<WhiteBox className="w-full sm:w-80">
				<AddKnowledgeBaseSectionDialog />
				<KnowledgeBaseSectionsAccordion sections={sectionsResponse.data} />
			</WhiteBox>
			<WhiteBox className="max-w-full grow">
				{sectionsResponse?.data?.length > 0 ? (
					<Button className="mb-4 uppercase" asChild>
						<Link to={`/knowledge-base/add`}>Add article</Link>
					</Button>
				) : (
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger className="cursor-default">
								<Button className="mb-4 uppercase" disabled>
									Add article
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>Sections must be created before articles can be added.</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
				<KnowledgeBaseArticlesTable data={articlesResponse.data} />
			</WhiteBox>
		</div>
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Trash2 } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

export const CalendarDocumentsAddForm = (
	{ control, handleDeleteFile, isUploading }: {
		isUploading: boolean;
		control: any,
		handleDeleteFile: (index: number) => void;
}) => {

	return (
		<div className="grid w-full items-center gap-4">
			<div className="flex flex-col space-y-1.5">
				<FormField
					control={control}
					name="title"
					render={({ field }) => (
						<FormItem>
							<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-3">
								<div className="flex items-center justify-start gap-2 sm:col-span-1">
									<FormLabel className="whitespace-nowrap">Title</FormLabel>
									<FormMessage />
								</div>
								<FormControl className="sm:col-span-2">
									<Input placeholder="" {...field} disabled={isUploading}/>
								</FormControl>
							</div>
						</FormItem>
					)}
				/>
			</div>
			<div className="flex flex-col space-y-1.5">
				<FormField
					control={control}
					name="description"
					render={({ field }) => (
						<FormItem>
							<div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-3">
								<div className="flex items-center justify-start gap-2 sm:col-span-1">
									<FormLabel className="whitespace-nowrap">Description</FormLabel>
									<FormMessage />
								</div>
								<FormControl className="sm:col-span-2">
									<Textarea {...field} disabled={isUploading}/>
								</FormControl>
							</div>
						</FormItem>
					)}
				/>
			</div>
			<div className="flex flex-col space-y-1.5">
				<FormField
					control={control}
					name="files"
					render={({ field: { onChange, value } }) => {
						return (
							<>
								<FormItem>
									<div className="grid grid-cols-1 items-start gap-3 sm:grid-cols-3">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Files</FormLabel>
											<FormMessage />
										</div>
										<FormControl className="sm:col-span-2">
											<div className="relative">
												<label>
													<span className="inline-flex h-10 w-full cursor-pointer items-center justify-center whitespace-nowrap rounded-md bg-primary px-4 py-2 text-sm font-semibold text-primary-foreground ring-offset-background transition-colors hover:bg-primary/90">
														Upload files
													</span>
													<Input
														disabled={isUploading}
														className="hidden"
														type="file"
														placeholder=""
														value={""}
														onChange={(event) => {
															onChange(
																value
																	? [
																			...value,
																			...(event.target.files
																				? Array.from(event.target.files)
																				: []),
																		]
																	: event.target.files,
															);
														}}
														multiple
													/>
												</label>
											</div>
										</FormControl>
									</div>
								</FormItem>
								<div>
									{value &&
										Object.values(value).length > 0 &&
										Object.values(value as File[]).map((file, index) => {
											return (
												<div
													key={file.name}
													className="flex max-w-full items-center justify-between gap-2"
												>
													<span className="w-40 truncate sm:w-auto" title={file.name}>
														{file.name}
													</span>
													<div>
														<Button
															disabled={isUploading}
															type="button"
															variant="outline"
															size="icon"
															style={{ background: "#F44336", width: "28px", height: "28px" }}
															onClick={() => handleDeleteFile(index)}
														>
															<Trash2 color="#fff" size={20} />
														</Button>
													</div>
												</div>
											);
										})}
								</div>
							</>
						);
					}}
				/>
			</div>
		</div>
	);
};

import { useNavigate, useParams } from "react-router-dom";

import {
	useGetVesselArticleQuery,
	useUpdateVesselArticleMutation,
} from "@/app/api/slices/manager/managerKnowledgeBaseApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { type FormType } from "@/components/forms/KnowledgeBaseAdminArticleUpsertForm/formSchema";
import { KnowledgeBaseManagerArticleUpsertForm } from "@/components/forms/KnowledgeBaseManagerArticleUpsertForm";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const ManagerEditArticlePage = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const auth = useAuth();
	const vesselId = auth?.user?.vessel.id;
	const { articleId } = useParams();

	const { data: articleResponse, isLoading: isLoadingArticle } = useGetVesselArticleQuery({
		vesselId,
		articleId,
	});

	const [updateArticleMutation, { isLoading: isUpdatingArticle }] =
		useUpdateVesselArticleMutation();

	const handleUpdateArticle = async (data: FormType) => {
		await updateArticleMutation({ articleId, vesselId, data })
			.unwrap()
			.then(() =>
				navigate("/knowledge-base", {
					state: {
						toast: {
							variant: "success",
							title: "Successfully updated article.",
						},
					},
				}),
			)
			.catch((error) => handleServerErrors(error, toast));
	};

	if (isLoadingArticle) return <InLayoutLoader />;

	return (
		<WhiteBox>
			<h1 className="mb-4 text-xl font-semibold md:text-2xl">Edit Article</h1>
			<KnowledgeBaseManagerArticleUpsertForm
				onSubmit={handleUpdateArticle}
				isLoading={isUpdatingArticle}
				defaultValues={{
					...articleResponse.data,
					section: String(articleResponse.data.section.id),
				}}
			/>
		</WhiteBox>
	);
};

import { useParams } from "react-router-dom";

import { useAssignSubscriptionTierToVesselMutation } from "@/app/api/slices/admin/subscriptionsApiSlice";
import { useGetVesselQuery } from "@/app/api/slices/admin/vesselsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { ManagerPaymentDetailsForm } from "@/components/forms/ManagerPaymentDetailsForm";
import { type FormType } from "@/components/forms/ManagerPaymentDetailsForm/formSchema";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const VesselPaymentsPage = () => {
	const { vesselId } = useParams();
	const { toast } = useToast();

	const {
		data: response,
		isLoading,
		isFetching,
	} = useGetVesselQuery({ vesselId }, { skip: !vesselId });

	const [assignSubscriptionTierToVesselMutation, { isLoading: isAssigningSubscriptionTier }] =
		useAssignSubscriptionTierToVesselMutation();

	const handleAddSuggestion = async ({ subscriptionTierId }: FormType) => {
		await assignSubscriptionTierToVesselMutation({ vesselId, subscriptionTierId })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Successfully assigned subscription to vessel.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<div className="mt-4 grid grid-cols-1 gap-4">
			<WhiteBox>
				<h1 className="mb-4 text-xl font-semibold md:text-2xl">Payment Details</h1>
				<ManagerPaymentDetailsForm
					onSubmit={handleAddSuggestion}
					isLoading={isAssigningSubscriptionTier}
					defaultValues={{
						subscriptionTierId: String(response?.data?.subscription?.tier?.id || ""),
					}}
				/>
			</WhiteBox>
			{/* <WhiteBox>
				<h1 className="mb-4 text-xl font-semibold md:text-2xl">Invoices</h1>
				<InvoicesTable />
			</WhiteBox> */}
		</div>
	);
};

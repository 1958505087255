import { useAuth } from "@/hooks/useAuth";
import { AdminAddArticlePage } from "@/pages/admin/AdminAddArticlePage";
import { ManagerAddArticlePage } from "@/pages/manager/ManagerAddArticlePage";

export const AddArticlePage = () => {
	const auth = useAuth();
	const user = auth?.user;
	const isAdmin = user?.role.name === "admin";

	return isAdmin ? <AdminAddArticlePage /> : <ManagerAddArticlePage />;
};

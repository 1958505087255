import { Copy } from "lucide-react";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

type Props = { link: string };

export const CopyButton = ({ link }: Props) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const handleCopy = () => {
		try {
			navigator.clipboard.writeText(link);
			setShowTooltip(true);

			setTimeout(() => {
				setShowTooltip(false);
			}, 1200);
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	return (
		<>
			<TooltipProvider>
				<Tooltip open={showTooltip}>
					<TooltipTrigger asChild>
						<Button
							className="shrink-0"
							onClick={handleCopy}
							variant="outline"
							size="icon"
							style={{ background: "#2055a3", width: "28px", height: "28px" }}
						>
							<Copy color="#fff" size={18} />
						</Button>
					</TooltipTrigger>
					<TooltipContent>Copied</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</>
	);
};

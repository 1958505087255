import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Info } from "lucide-react";
import { cn } from "@/lib/utils";


type Props = {
    label: string;
	error: string | undefined | null;
};

export const LabelWithError = ({
    label, 
    error
}: Props) => {
	return (
        <div className="flex items-center justify-start gap-2 mb-1">
            <label className={cn("text-sm", error ? "text-destructive" : "")}>{label}</label>

            { error && (
                <div
                    className={"inline-block text-sm font-medium text-destructive"}
                >
                    <HoverCard>
                        <HoverCardTrigger>
                            <Info size={16} />
                        </HoverCardTrigger>
                        <HoverCardContent className="text-destructive">{error}</HoverCardContent>
                    </HoverCard>
                </div>
            )}
        </div>	
	);
};

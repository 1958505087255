import * as React from "react";

import { cn } from "@/lib/utils";

type Props = {
	children: React.ReactNode;
	id?: string;
	className?: string;
};

export const WhiteBox = ({ children, id, className = "" }: Props) => {
	return (
		<div id={id} className={cn("rounded-md bg-white p-4", className)}>
			{children}
		</div>
	);
};

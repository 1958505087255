import { ChevronsUpDown } from "lucide-react";
import { useState } from "react";

import { AddPositionDialog } from "@/components/dialogs/AddPositionDialog";
import { EditDepartmentDialog } from "@/components/dialogs/EditDepartmentDialog";
import { EditPositionDialog } from "@/components/dialogs/EditPositionDialog";
import { RemoveDepartmentDialog } from "@/components/dialogs/RemoveDepartmentDialog";
import { RemovePositionDialog } from "@/components/dialogs/RemovePositionDialog";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { type OpenStates } from "@/types/AccordionOpenStates";

type Props = {
	data: {
		id: number;
		name: string;
		positions: {
			id: number;
			name: string;
			minimum: number;
		}[];
	}[];
};

export const DepartmentsAndPositionsAccordion = ({ data }: Props) => {
	const [openStates, setOpenStates] = useState<OpenStates>({});

	const toggleOpenState = (departmentId: number) => {
		setOpenStates((prevStates) => ({
			...prevStates,
			[departmentId]: !prevStates[departmentId],
		}));
	};

	return (
		<div className="flex flex-col gap-3">
			{data.length < 1 && <p className="p-4 text-center">No departments found</p>}
			{data.length > 0 &&
				data.map((department) => {
					return (
						<Collapsible
							key={`department-${department.id}`}
							open={openStates[department.id] || false}
							onOpenChange={() => toggleOpenState(department.id)}
							className="space-y-2"
						>
							<div className="flex items-center justify-between space-x-4">
								{department.positions.length > 0 ? (
									<CollapsibleTrigger asChild>
										<Button
											variant="ghost"
											size="sm"
											className="flex w-full items-center justify-between"
										>
											<h4 className="text-sm font-semibold">{department.name}</h4>
											<ChevronsUpDown className="h-4 w-4" />
											<span className="sr-only">Toggle</span>
										</Button>
									</CollapsibleTrigger>
								) : (
									<div className="flex w-full items-center justify-between px-3">
										<h4 className="text-sm font-semibold">{department.name}</h4>
									</div>
								)}
								<div className="flex items-center gap-1">
									<AddPositionDialog departmentId={department.id} />
									<EditDepartmentDialog department={department} />
									<RemoveDepartmentDialog departmentId={department.id} />
								</div>
							</div>
							<CollapsibleContent className="space-y-2">
								{department.positions.length > 0 &&
									department.positions.map((position) => {
										return (
											<div
												key={`position-${position.id}`}
												className="flex items-center justify-between rounded-md border px-4 py-3 font-mono text-sm"
											>
												<span>{position.name}</span>
												<div className="flex items-center gap-1">
													<EditPositionDialog departmentId={department.id} position={position} />
													<RemovePositionDialog
														departmentId={department.id}
														positionId={position.id}
													/>
												</div>
											</div>
										);
									})}
							</CollapsibleContent>
						</Collapsible>
					);
				})}
		</div>
	);
};

import { useEffect, useRef } from "react";

import {
	useRequestAccountRemoveMutation,
	useUpdateEmailAndPhoneMutation,
	useUpdatePasswordMutation,
} from "@/app/api/slices/userProfileApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { ChangePasswordForm } from "@/components/forms/ChangePasswordForm";
import { type FormType as UpdatePasswordFormType } from "@/components/forms/ChangePasswordForm/formSchema";
import { ProfileForm } from "@/components/forms/ProfileForm";
import { type FormType as UpdateEmailAndPhoneFormType } from "@/components/forms/ProfileForm/formSchema";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useJoyride } from "@/contexts/JoyrideContext";
import { joyride_redirect_5_index } from "@/hocs/JoyrideWrapper";
import { useAuth } from "@/hooks/useAuth";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const ProfilePage = () => {
	const auth = useAuth();
	const role = auth?.user?.role.name;
	const { toast } = useToast();

	const [updatePasswordMutation, { isLoading: isUpdatingPassword }] = useUpdatePasswordMutation();
	const [updateEmailAndPhoneMutation, { isLoading: isUpdatingProfile }] =
		useUpdateEmailAndPhoneMutation();
	const [requestAccountRemoveMutation, { isLoading: isSendingRequest }] =
		useRequestAccountRemoveMutation();

	const { run, startJoyride, stepIndex } = useJoyride();
	const changePasswordFormRef = useRef(null);
	useEffect(() => {
		if (!run && changePasswordFormRef.current && stepIndex === joyride_redirect_5_index) {
			setTimeout(() => {
				startJoyride();
			}, 400);
		}
	}, [changePasswordFormRef.current]);

	const handlePasswordChange = async ({
		currentPassword,
		password,
		confirmPassword,
	}: UpdatePasswordFormType) => {
		await updatePasswordMutation({ currentPassword, password, confirmPassword })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Successfully changed password.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	const handleEmailAndPhoneChange = async ({ email, phone }: UpdateEmailAndPhoneFormType) => {
		await updateEmailAndPhoneMutation({ email, phone })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Successfully changed email and phone.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	const handleRequestAccountRemove = async () => {
		await requestAccountRemoveMutation({})
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Account removal request successfully sent.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<WhiteBox>
			<div id="change-password-form" ref={changePasswordFormRef}>
				<h1 className="mb-4 text-xl font-semibold md:text-2xl">Change Password</h1>
				<ChangePasswordForm onSubmit={handlePasswordChange} isLoading={isUpdatingPassword} />
			</div>
			{role === "manager" && (
				<>
					<div id="change-profile-form">
						<h2 className="mb-4 mt-4 text-xl font-semibold sm:mt-0 md:text-2xl">Profile</h2>
						<ProfileForm
							onSubmit={handleEmailAndPhoneChange}
							isLoading={isUpdatingProfile}
							defaultValues={{ email: auth.user?.email || "", phone: auth.user?.phone || "" }}
						/>
					</div>
					<div id="remove-account-button">
						<h2 className="mb-4 mt-4 text-xl font-semibold sm:mt-0 md:text-2xl">
							Request Account Remove
						</h2>
						<Button
							onClick={handleRequestAccountRemove}
							variant="destructive"
							className="mb-2"
							disabled={isSendingRequest}
						>
							<ButtonLoader isLoading={isSendingRequest} />
							Request Account Remove
						</Button>
					</div>
				</>
			)}
		</WhiteBox>
	);
};

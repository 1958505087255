import { type ColumnDef } from "@tanstack/react-table";
import { Calendar, File } from "lucide-react";
import { Link } from "react-router-dom";

import { EditCrewMemberDialog } from "@/components/dialogs/EditCrewMemberDialog";
import { RemoveCrewMemberDialog } from "@/components/dialogs/RemoveCrewMemberDialog";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { type User } from "@/types/User";

export const getColumns = (role: string, currentUserId: number): ColumnDef<User>[] => {
	return [
		{
			accessorKey: "name",
			accessorFn: (row) => `${row.firstName} ${row.lastName}`,
			header: "Name",
			cell: ({ row }) => {
				const firstName = row.original.firstName;
				const lastName = row.original.lastName;

				return <div className="capitalize">{`${firstName} ${lastName}`}</div>;
			},
		},
		{
			accessorKey: "contact",
			accessorFn: (row) => `${row.email} ${row.phone}`,
			header: "Contact",
			cell: ({ row }) => {
				const email = row.original.email;
				const phone = row.original.phone;

				return (
					<div className="flex flex-col lowercase">
						<a href={`mailto:${email}`}>{email}</a>
						<a href={`tel:+${phone}`}>{phone}</a>
					</div>
				);
			},
		},
		{
			accessorKey: "rotation",
			enableResizing: false,
			header: "Rotation",
			size: 208,
			minSize: 208,
			maxSize: 208,
			cell: ({ row }) => {
				const rotationPeriods = row.original?.rotationPeriods;

				if (!rotationPeriods || rotationPeriods?.length < 1) return "-";

				return (
					<Accordion type="single" collapsible className="w-full">
						<AccordionItem value="item-1" className="border-b-0 pb-0">
							<AccordionTrigger className="pb-3 pt-0">
								<div className="flex max-w-52 flex-col justify-start text-left">
									<span>{rotationPeriods[0].rotation}</span>
									<span>
										{rotationPeriods[0].start} &mdash; {rotationPeriods[0].end}
									</span>
								</div>
							</AccordionTrigger>
							<AccordionContent className="pb-3">
								{rotationPeriods.map((period, index) => {
									if (index === 0) return null;

									return (
										<div
											key={`${period.start}-${period.end}`}
											className="flex max-w-52 flex-col last:mt-2"
										>
											{/* <span>{period.rotation}</span> */}
											<span>
												{period.start} &mdash; {period.end}
											</span>
										</div>
									);
								})}
							</AccordionContent>
						</AccordionItem>
					</Accordion>
				);
			},
		},
		{
			accessorKey: "department",
			accessorFn: (row) => row?.department?.name,
			header: "Department",
			cell: ({ row }) => {
				const departmentName = row.original?.department?.name;

				return <div>{departmentName || "-"}</div>;
			},
		},
		{
			accessorKey: "position",
			header: "Position",
			cell: ({ row }) => {
				const positionName = row.original?.position?.name;

				return <div>{positionName || "-"}</div>;
			},
		},
		{
			accessorKey: "homeAirport",
			header: "Home Airport",
			cell: ({ row }) => <div>{row.getValue("homeAirport")}</div>,
		},
		{
			accessorKey: "isOnboard",
			header: "Is Onboard?",
			cell: ({ row }) => {
				const isOnboard = row.getValue("isOnboard");

				return <div className="font-medium">{isOnboard ? "Yes" : "No"}</div>;
			},
		},
		{
			accessorKey: "leaveBalance",
			header: "Leave Balance",
			cell: ({ row }) => {
				const leaveBalanceType = row.original?.vessel?.leave_balance_type;
				const leaveBalance1 = row.original?.leave_balance_1;
				const leaveBalance2 = row.original?.leave_balance_2;

				if (leaveBalanceType === 1) {
					return <div className="font-medium">{leaveBalance1}</div>;
				}

				if (leaveBalanceType === 2) {
					return <div className="font-medium">{leaveBalance2}</div>;
				}

				if (leaveBalanceType === 3) {
					return (
						<div className="flex flex-col">
							<div className="font-medium">{leaveBalance1}</div>
							<div className="font-medium">{leaveBalance2}</div>
						</div>
					);
				}

				return <div className="font-medium">-</div>;
			},
		},
		{
			accessorKey: "rotationPartner",
			header: "Partner",
			cell: ({ row }) => <div>{row.getValue("rotationPartner") || "-"}</div>,
		},
		{
			id: "actions",
			header: () => <div>Actions</div>,
			cell: ({ row }) => {
				const vesselId = row.original?.vessel?.id;
				const userId = row.original.id;
				const shouldShowDocsButton = role !== "admin";
				const isDemoAccount = row.original?.isDemoAccount;
				const isOwnAccount = userId === currentUserId;

				return (
					<div className="flex justify-center gap-2">
						<EditCrewMemberDialog userId={userId} user={{ ...row.original }} />
						{role === "admin" ? (
							<Button
								className="crew-member-rotation-calendar-button"
								variant="outline"
								size="icon"
								asChild
								style={{ background: "#FB8500" }}
							>
								<Link to={`/vessel/${vesselId}/crew-members/${userId}/rotation-calendar`}>
									<Calendar color="#fff" />
								</Link>
							</Button>
						) : (
							<Button
								className="crew-member-rotation-calendar-button"
								variant="outline"
								size="icon"
								asChild
								style={{ background: "#FB8500" }}
							>
								<Link to={`/crew-members/${userId}/rotation-calendar`}>
									<Calendar color="#fff" />
								</Link>
							</Button>
						)}
						<RemoveCrewMemberDialog userId={userId} disabled={isDemoAccount || isOwnAccount} />
						{shouldShowDocsButton && (
							<Button
								className="crew-member-docs-button"
								variant="outline"
								size="icon"
								asChild
								style={{ background: "#8ECAE6" }}
							>
								<Link to={`/crew-members/${userId}/documents`}>
									<File color="#fff" />
								</Link>
							</Button>
						)}
					</div>
				);
			},
		},
	];
};

import { RequestAcceptDialog } from "@/components/dialogs/RequestAcceptDialog";
import { RequestDeclineDialog } from "@/components/dialogs/RequestDeclineDialog";
import { RequestHideDialog } from "@/components/dialogs/RequestHideDialog";
import { type UserRequest } from "@/types/UserRequest";
import { formatDate } from "@/utils/formatDate";

type Props = {
	request: UserRequest;
	index: number;
};

export const RowItem = ({ request, index }: Props) => {
	const {
		id,
		createdAt,
		description,
		note,
		createdBy: { firstName, lastName },
		status,
	} = request;

	return (
		<div>
			<div className="flex justify-between">
				<h3 className="text-xl font-semibold">Request #{index}</h3>
				<span>{formatDate(createdAt)}</span>
			</div>
			<div className="mb-2 border-t border-black"></div>
			<div className="flex">
				<div className="grow">
					<div className="mb-2 flex gap-4">
						<span className="w-24 font-semibold">Created by</span>
						<span>
							{firstName} {lastName}
						</span>
					</div>
					<div className="mb-2 flex gap-4">
						<span className="w-24 font-semibold">Description</span>
						<span>{description}</span>
					</div>
					{note && (
						<div className="flex gap-4">
							<span className="w-24 font-semibold">Note</span>
							<span>{note}</span>
						</div>
					)}
				</div>
				<div className="ml-1 flex flex-col justify-end gap-1">
					{status === "pending" && (
						<>
							<RequestAcceptDialog request={request} />
							<RequestDeclineDialog request={request} />
						</>
					)}
					{(status === "accepted" || status === "declined") && <RequestHideDialog requestId={id} />}
				</div>
			</div>
		</div>
	);
};

import { useGetSuggestionsQuery } from "@/app/api/slices/admin/adminSuggestionsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { AdminSuggestionsTable } from "@/components/tables/AdminSuggestionsTable";

export const AdminSuggestionsPage = () => {
	const { data: suggestionsResponse, isLoading, isFetching } = useGetSuggestionsQuery({});

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<WhiteBox>
			<AdminSuggestionsTable data={suggestionsResponse.data} />
		</WhiteBox>
	);
};

import { ArchiveRestore } from "lucide-react";
import { useState } from "react";

import { useRestoreSuggestionMutation } from "@/app/api/slices/admin/adminSuggestionsApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { type SuggestionReporter } from "@/types/Suggestions";
import { handleServerErrors } from "@/utils/handleServerErrors";

type Props = {
	suggestionId: number;
	reporter: SuggestionReporter;
	suggestion: { title: string; description: string };
};

export const RestoreSuggestionDialog = ({ suggestionId, reporter, suggestion }: Props) => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [restoreSuggestionMutation, { isLoading }] = useRestoreSuggestionMutation();

	const handleRestoreSuggestion = async () => {
		await restoreSuggestionMutation({ suggestionId })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully restored suggestion.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" size="icon" style={{ background: "#2055A3" }}>
					<ArchiveRestore color="#fff" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Restore Suggestion</DialogTitle>
				</DialogHeader>
				<div>
					<div className="flex flex-col space-y-1.5">
						<div className="flex flex-col space-y-2">
							<div className="grid grid-cols-1 items-center gap-1">
								<div className="whitespace-nowrap font-semibold">Manager</div>
								<div>{reporter.firstName + " " + reporter.lastName}</div>
							</div>
							<div className="grid grid-cols-1 items-center gap-1">
								<div className="whitespace-nowrap font-semibold">Title</div>
								<div>{suggestion.title}</div>
							</div>
							<div className="grid grid-cols-1 items-center gap-1">
								<div className="whitespace-nowrap font-semibold">Description</div>
								<div>{suggestion.description}</div>
							</div>
						</div>
					</div>
				</div>
				<DialogFooter>
					<div className="flex w-full justify-between">
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<Button onClick={handleRestoreSuggestion} disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Restore
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

import * as z from "zod";

import { emailZodRule, firstNameZodRule, lastNameZodRule, phoneZodRule } from "@/lib/zod";

export const formSchema = z.object({
	firstName: firstNameZodRule,
	lastName: lastNameZodRule,
	role: z.string().min(1, { message: "Role is required" }),
	email: emailZodRule,
	phone: phoneZodRule,
	nationality: z.string().min(1, { message: "Nationality is required" }),
	homeAirport: z.string().min(1, { message: "Home airport is required" }),
	department: z.string().min(1, { message: "Department is required" }),
	position: z.string().min(1, { message: "Position is required" }),
});

export type FormType = z.infer<typeof formSchema>;

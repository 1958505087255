import { User } from "lucide-react";
import { useParams } from "react-router-dom";

import { useGetCrewMemberQuery } from "@/app/api/slices/crewMembersApiSlice";
import { WhiteBox } from "@/components/WhiteBox";
import { Skeleton } from "@/components/ui/skeleton";
import { useAuth } from "@/hooks/useAuth";

export const UserInfoBar = () => {
	const auth = useAuth();
	const params = useParams();
	const vesselId = auth?.user?.vessel?.id || params.vesselId;

	const {
		data: response,
		isLoading,
		isFetching,
	} = useGetCrewMemberQuery({ vesselId, userId: params.userId });

	return (
		<WhiteBox className="flex items-center gap-4 py-2">
			<div className="flex h-8 w-8 items-center">
				<User />
			</div>

			{isLoading || isFetching ? (
				<div className="flex flex-wrap items-center gap-x-4 gap-y-1 sm:flex-nowrap">
					<Skeleton className="h-4 w-[180px]" />
					<Skeleton className="h-4 w-[180px]" />
					<Skeleton className="h-4 w-[180px]" />
				</div>
			) : (
				<div className="flex flex-wrap items-center gap-x-4 gap-y-1 text-sm sm:flex-nowrap sm:text-base">
					<div>
						<span className="font-semibold">Crew member: </span>
						{`${response?.data?.firstName} ${response?.data?.lastName}` || "-"}
					</div>
					<div>
						<span className="font-semibold">Department: </span>
						{response?.data?.department?.name || "-"}
					</div>
					<div>
						<span className="font-semibold">Position: </span>
						{response?.data?.position?.name || "-"}
					</div>
				</div>
			)}
		</WhiteBox>
	);
};

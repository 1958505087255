import { useEffect } from "react";

import {
	useGetStripePaymentUrlQuery,
	useGetStripePortalUrlQuery,
	useGetSubscriptionDetailsQuery,
} from "@/app/api/slices/manager/paymentsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { useAuth } from "@/hooks/useAuth";
import { AlertTriangle } from "lucide-react";

export const PaymentsPage = () => {
	const auth = useAuth();
	const vesselId = auth?.user?.vessel?.id;
	const isDemoAccount = auth?.user?.isFromDemoAccount;

	const { data: response, isLoading } = useGetSubscriptionDetailsQuery({ vesselId });

	const isActive = response?.data?.isActive === 1;

	const { data: stripePortalResponse, isLoading: isLoadingStripePortal } =
		useGetStripePortalUrlQuery({ vesselId }, { skip: !isActive || isDemoAccount });
	const { data: stripePaymentResponse, isLoading: isLoadingStripePayment } =
		useGetStripePaymentUrlQuery({ vesselId }, { skip: isActive || isDemoAccount });

	useEffect(() => {
		if (stripePortalResponse) {
			window.location.href = stripePortalResponse.url;
		}

		if (stripePaymentResponse) {
			window.location.href = stripePaymentResponse.url;
		}
	}, [stripePortalResponse, stripePaymentResponse]);

	if (isDemoAccount) {
		return (
			<WhiteBox>
				<h1 className="text-xl font-semibold md:text-2xl">Payments</h1>
				<div className="mt-4 inline-block rounded-md bg-yellow-500 p-4 text-white">
					<p className="flex items-center font-semibold">
						<AlertTriangle className="mr-2 h-6 w-6" />
						You are using a demo account. Payments are disabled.
					</p>
				</div>
			</WhiteBox>
		);
	}

	if (isLoading || isLoadingStripePortal || isLoadingStripePayment) return <InLayoutLoader />;

	return (
		<WhiteBox>
			<h1 className="text-xl font-semibold md:text-2xl">Payments</h1>
			<p className="mt-2">Redirecting to Stripe portal...</p>
		</WhiteBox>
	);
};

import { createSlice } from "@reduxjs/toolkit";

import { appConfigApiSlice } from "@/app/api/slices/appConfigApiSlice";
import { type RootState } from "@/app/store";

type ConfigState = {
	rotationTypes: string[] | null;
	brandingData: {
		meta: string;
		domain: string;
		logoUrl: string;
		backgroundColor: string;
		topBarColor: string;
		buttonColor: string;
	} | null;
};

const slice = createSlice({
	name: "config",
	initialState: { rotationTypes: null } as ConfigState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			appConfigApiSlice.endpoints.getAppConfig.matchFulfilled,
			(state, { payload }) => {
				state.rotationTypes = payload.data.rotationTypes;
				state.brandingData = payload.data.brandingData;
			},
		);
	},
});

export default slice.reducer;

export const selectConfig = (state: RootState) => state.config;

import { useNavigate } from "react-router-dom";

import { useAddAdminArticleMutation } from "@/app/api/slices/admin/adminKnowledgeBaseApiSlice";
import { WhiteBox } from "@/components/WhiteBox";
import { KnowledgeBaseAdminArticleUpsertForm } from "@/components/forms/KnowledgeBaseAdminArticleUpsertForm";
import { type FormType } from "@/components/forms/KnowledgeBaseAdminArticleUpsertForm/formSchema";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const AdminAddArticlePage = () => {
	const { toast } = useToast();
	const navigate = useNavigate();

	const [addArticleMutation, { isLoading: isAddingArticle }] = useAddAdminArticleMutation();

	const handleAddArticle = async (data: FormType) => {
		await addArticleMutation({ data })
			.unwrap()
			.then(() =>
				navigate("/knowledge-base", {
					state: {
						toast: {
							variant: "success",
							title: "Successfully added article.",
						},
					},
				}),
			)
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<WhiteBox>
			<h1 className="mb-4 text-xl font-semibold md:text-2xl">Add Article</h1>
			<KnowledgeBaseAdminArticleUpsertForm
				onSubmit={handleAddArticle}
				isLoading={isAddingArticle}
			/>
		</WhiteBox>
	);
};

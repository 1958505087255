import { apiSlice } from "@/app/api/apiSlice";

export const knowledgeBaseApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVesselSections: builder.query({
			query: ({ vesselId }) => `/api/vessel/${vesselId}/knowledge-base/sections`,
			providesTags: ["ManagerKnowledgeBaseSections"],
		}),
		addVesselSection: builder.mutation({
			query: ({ vesselId, name }) => ({
				url: `/api/vessel/${vesselId}/knowledge-base/sections`,
				method: "POST",
				body: { name },
			}),
			invalidatesTags: ["ManagerKnowledgeBaseSections"],
		}),
		updateVesselSection: builder.mutation({
			query: ({ vesselId, name, sectionId }) => ({
				url: `/api/vessel/${vesselId}/knowledge-base/sections/${sectionId}`,
				method: "PUT",
				body: { name },
			}),
			invalidatesTags: ["ManagerKnowledgeBaseSections"],
		}),
		removeVesselSection: builder.mutation({
			query: ({ vesselId, sectionId }) => ({
				url: `/api/vessel/${vesselId}/knowledge-base/sections/${sectionId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["ManagerKnowledgeBaseSections"],
		}),
		getVesselArticles: builder.query({
			query: ({ vesselId }) => `/api/vessel/${vesselId}/knowledge-base/articles`,
			providesTags: ["ManagerKnowledgeBaseArticles"],
		}),
		getVesselArticle: builder.query({
			query: ({ vesselId, articleId }) =>
				`/api/vessel/${vesselId}/knowledge-base/articles/${articleId}`,
			providesTags: (_result, _error, id) => [{ type: "ManagerKnowledgeBaseArticles", id }],
		}),
		addVesselArticle: builder.mutation({
			query: ({ vesselId, data }) => ({
				url: `/api/vessel/${vesselId}/knowledge-base/articles`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["ManagerKnowledgeBaseArticles", "ManagerKnowledgeBaseSections"],
		}),
		updateVesselArticle: builder.mutation({
			query: ({ vesselId, data, articleId }) => ({
				url: `/api/vessel/${vesselId}/knowledge-base/articles/${articleId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["ManagerKnowledgeBaseArticles", "ManagerKnowledgeBaseSections"],
		}),
		removeVesselArticle: builder.mutation({
			query: ({ vesselId, articleId }) => ({
				url: `/api/vessel/${vesselId}/knowledge-base/articles/${articleId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["ManagerKnowledgeBaseArticles", "ManagerKnowledgeBaseSections"],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetVesselSectionsQuery,
	useAddVesselSectionMutation,
	useUpdateVesselSectionMutation,
	useRemoveVesselSectionMutation,
	useGetVesselArticlesQuery,
	useGetVesselArticleQuery,
	useAddVesselArticleMutation,
	useUpdateVesselArticleMutation,
	useRemoveVesselArticleMutation,
} = knowledgeBaseApiSlice;

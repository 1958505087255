import * as z from "zod";

import { docDescriptionZodRule, docTitleZodRule } from "@/lib/zod";

export const formSchema = z
	.object({
		title: docTitleZodRule,
		description: docDescriptionZodRule,
		filesToKeep: z.any().optional(),
		newFiles: z.any().optional(),
	})
	.refine(
		({ filesToKeep, newFiles }) =>
			(filesToKeep && filesToKeep.length > 0) || (newFiles && newFiles.length > 0),
		{
			message: "At least one file is required",
			path: ["filesToKeep"],
		},
	)
	.refine(
		({ filesToKeep, newFiles }) =>
			(filesToKeep && filesToKeep.length > 0) || (newFiles && newFiles.length > 0),
		{
			message: "At least one file is required",
			path: ["newFiles"],
		},
	);

export type FormType = z.infer<typeof formSchema>;

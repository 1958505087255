import { useGetSubscriptionTiersQuery } from "@/app/api/slices/admin/subscriptionsApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { AddSubscriptionTierDialog } from "@/components/dialogs/AddSubscriptionTierDialog";
import { SubscriptionsSettingsTable } from "@/components/tables/SubscriptionsSettingsTable";

export const SubscriptionsSettingsPage = () => {
	const { data: response, isLoading, isFetching } = useGetSubscriptionTiersQuery({});

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<WhiteBox>
			<div className="mb-4 flex items-center justify-between">
				<AddSubscriptionTierDialog />
			</div>
			<SubscriptionsSettingsTable data={response?.data} />
		</WhiteBox>
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Download } from "lucide-react";
import { format } from "date-fns";
import { generateCells } from "@/components/rotation-calendar/utils";
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { EditCalendarDocumentsDialog } from "@/components/dialogs/EditCalendarDocumentsDialog";
import { useParams } from "react-router-dom";
import { RemoveCrewMemberDocumentsDialog } from "@/components/dialogs/RemoveCrewMemberDocumentsDialog";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle
} from "@/components/ui/dialog";

import { type CalendarPartInterface, type RotationInterface } from "@/types/Calendar";
import { legend, prepareDate, getEmptyDaysCount, getEmptyFirstStartDate } from "@/utils/calendarUtils";

type Props = { 
	isPartner: undefined | boolean,
	rotation: undefined | null | string,
	calendarData: RotationInterface[] | undefined | null,
	pageHandler: (page: {dir: string, currentPage: number | undefined}) => void,
	page: number | undefined,
	maxPages: number,
	perPage: number,
	perPageHandler: (value: string) => void,
	isLoading: boolean,
	showDayKindModalHandler: ({startDate}:{startDate: Date}) => void,
	rotations: undefined | null | RotationCalendarRotations[],
	documents: any,
	rotationPeriodType: string | undefined
};

export const Calendar = ({ rotationPeriodType, documents, isPartner, rotation, isLoading, pageHandler, page, maxPages, perPage, perPageHandler, showDayKindModalHandler, rotations, calendarData }: Props) => {
	const params = useParams();
	const userId = params.user_id;

	const [transformedData, setTransformedData] = useState<CalendarPartInterface[] | []>([]);

	const [showDocumentModal, setShowDocumentModal] = useState(false);
	const [document, setDocument] = useState<any>(null);

	const openDocumentModal = (data: any) => {
		if(!documents) return;

		const findDocBatchData = documents.find((doc: any) => doc.id === data);
		setDocument(findDocBatchData);
		setShowDocumentModal(true);
	}

	const onCloseDocumentModal = () => {
		setDocument(null);
		setShowDocumentModal(false);
	}

	const rotationsDatesRanges = calendarData?.map((rotation) => {
		const preparedDateRanges: {onboard?: {start_date: string, end_date: string}, offboard?: {start_date: string, end_date: string}} = {};

		if(rotation.onboard?.start_date && rotation.onboard?.end_date) {
			preparedDateRanges.onboard = {
				start_date: prepareDate(rotation.onboard.start_date, 'start_date'),
				end_date: prepareDate(rotation.onboard.end_date, 'end_date')
			}
		}
		if(rotation.offboard?.start_date && rotation.offboard?.end_date) {
			preparedDateRanges.offboard = {
				start_date: prepareDate(rotation.offboard.start_date, 'start_date'),
				end_date: prepareDate(rotation.offboard.end_date, 'end_date')
			}
		}
		return preparedDateRanges;
	})

	useEffect(() => {
		if(Array.isArray(calendarData) && calendarData.length) {
			const mergedRotations = calendarData.reduce((acc: CalendarPartInterface[] | [], rotation: RotationInterface ) => {
				return [...acc, ...rotation.calendar]
			}, []);

			if(mergedRotations.length !== 0) {
				const firstDateInData = new Date(mergedRotations[0]?.start_date)?.getDay();
				const emptyDaysCount = getEmptyDaysCount(firstDateInData);
				const emptyFirstStartDate = getEmptyFirstStartDate(mergedRotations[0].start_date, emptyDaysCount);
		
				if(emptyDaysCount !== 0) {
					const firstRecordInArray = {
						type: "empty_day",
						start_date: format(emptyFirstStartDate, "yyyy-MM-dd"),
						duration: emptyDaysCount
					}
					setTransformedData([firstRecordInArray, ...mergedRotations])
				} else {
					setTransformedData(mergedRotations)
				}
			} else {
				setTransformedData([]);
			}
		}
	}, [calendarData])

	return (
		<div className="w-full relative p-1" style={{ minWidth: 500 }}>

			<Dialog open={showDocumentModal} onOpenChange={onCloseDocumentModal} defaultOpen={false}>
				<DialogContent className="sm:max-w-[500px]">
					<DialogHeader>
						<DialogTitle>Document</DialogTitle>
					</DialogHeader>

					<hr />

					{document && (
						<div className="space-y-2">
								<div className="flex items-center justify-start space-x-4 sm:justify-between">
									
									<div
										className="flex flex-col h-auto w-full flex-wrap "
									>
										<h4 className="text-left font-semibold sm:w-auto">
											Title: {document.title}
										</h4>

										<div className="text-sm flex flex-col sm:flex-nowrap sm:justify-center">
											<span className="hidden font-normal sm:inline-block">
												<span className="font-semibold">Created at:</span>{" "}
												{format(document.createdAt, "yyyy-MM-dd")}
											</span>
											<span className="font-normal">
												<span className="font-semibold">Updated at:</span>{" "}
												{format(document.updatedAt, "yyyy-MM-dd")}
											</span>
										</div>
									</div>
									
									<div className="flex items-center gap-1">
										<RemoveCrewMemberDocumentsDialog documentId={document.id} onSuccessCallback={onCloseDocumentModal}/>
										<EditCalendarDocumentsDialog userId={userId} document={document} onSuccessCallback={onCloseDocumentModal}/>
									</div>
								</div>

								{ document.end_date && (
									<div className="">
										<h3 className="font-semibold">Date Range:</h3>
										<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
											<span>{format(document.start_date, "yyyy-MM-dd")} &ndash; {format(document.end_date || document.start_date, "yyyy-MM-dd")}</span>
										</div>
									</div>
								)}
								
								<div className="">
									<h3 className="font-semibold">Documents:</h3>
									<ul className="list-inside list-decimal">
										{document.files.map((file: {name: string, uploadedBy: string, url: string}) => {
											return (
												<li
													key={file.name}
													className="my-1 flex items-center justify-between rounded-sm bg-gray-100 pl-2"
												>
													<span
														className="truncate text-sm"
														title={`${file.name} &ndash; uploaded by ${file.uploadedBy}`}
													>
														{file.name} &ndash; uploaded by {file.uploadedBy}
													</span>
													<div>
														<Button
															variant="outline"
															size="icon"
															asChild
															style={{ background: "#4CAF50", width: "28px", height: "28px" }}
														>
															<a className="flex items-center justify-center" href={file.url}>
																<Download color="#fff" size={20} />
															</a>
														</Button>
													</div>
												</li>
											);
										})}
									</ul>
								</div>
								<div className="">
									<h3 className="font-semibold">Description:</h3>
									<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
										<span>{document.description}</span>
									</div>
								</div>
						</div>
					)}
				</DialogContent>
			</Dialog>


			<div className="flex mb-3">
				<div className="grow flex justify-between">
					<Button disabled={(page ? (page <= 1) : true) || isLoading} onClick={ () => pageHandler({dir: "prev", currentPage: page})}>
						Previous
					</Button>

					{ (rotations && rotations.length > 0) && (
						<div>
							{rotations.map((rotation: RotationCalendarRotations) => (
								<Button key={rotation.id} className="me-1">
									{new Date(rotation.onboard.start_date).toLocaleDateString("en-GB", { day: "numeric", month: "short"})}
								</Button>
							))}
						</div>
					)}

					{ !rotations && (
						<Button disabled={page === 1} onClick={ () => pageHandler({dir: "prev", currentPage: 2})}>
							Show Current Rotation
						</Button>
					)}

					<Button disabled={(page ? (page >= maxPages) : true) || isLoading} onClick={ () => pageHandler({dir: "next", currentPage: page})}>
						Next
					</Button>
				</div>
			</div>
			<div className="flex flex-wrap">
				<div className="flex grow me-3 mb-3">
					{legend.map((item) => (
						<div className="flex items-center justify-content me-3" key={item.type}>
							<div className={`w-4 h-4 me-1 border rounded-md ${item.color}`}></div>
							<label className="text-xs text-nowrap ">{item.name}</label>
						</div>
					))}
					
				</div>
				<div className="mb-3">
					<div className="flex items-center">
						<label className="text-xs lg:text-sm text-nowrap me-3">Rotations per page</label>
						<Select onValueChange={perPageHandler} value={String(perPage)}>
							<SelectTrigger className="text-xs lg:text-sm">
								<SelectValue />
							</SelectTrigger>
							<SelectContent>
								<SelectGroup >
									{["2","3","5"].map((rotations_count) => (
										<SelectItem className="text-xs lg:text-sm" key={rotations_count} value={String(rotations_count)} defaultChecked={rotations_count === String(perPage)}>{rotations_count}</SelectItem>
									))}
								</SelectGroup>
							</SelectContent>
						</Select>
					</div>
				</div>
			</div>

			<div className="grid grid-cols-12 gap-1 md:gap-2 font-medium">
				<div className="py-2 rounded-md border border-gray-300 text-center col-span-2 text-xs lg:text-sm">
					{ ["weeks", "months"].includes(rotationPeriodType || "") ? "Rotation" : "Week" }
				</div>
				<div className="py-2 rounded-md border border-gray-300 text-center col-span-3 text-xs lg:text-sm">Month - Year</div>
				<div className="py-2 rounded-md border border-gray-300 text-center text-xs lg:text-sm">Mon</div>
				<div className="py-2 rounded-md border border-gray-300 text-center text-xs lg:text-sm">Tue</div>
				<div className="py-2 rounded-md border border-gray-300 text-center text-xs lg:text-sm">Wed</div>
				<div className="py-2 rounded-md border border-gray-300 text-center text-xs lg:text-sm">Thu</div>
				<div className="py-2 rounded-md border border-gray-300 text-center text-xs lg:text-sm">Fri</div>
				<div className="py-2 rounded-md border border-gray-300 text-center text-xs lg:text-sm">Sat</div>
				<div className="py-2 rounded-md border border-gray-300 text-center text-xs lg:text-sm">Sun</div>
			</div>
			<div className="mt-2 grid grid-cols-12 gap-1 md:gap-2 ctrl__calendar">
				{
					generateCells({
						page, 
						data: transformedData, 
						rotationsDatesRanges, 
						showDayKindModalHandler, 
						rotation,
						isPartner,
						rotationPeriodType,
						
						documents,
						openDocumentModal
					})
				}
			</div>
		</div>
	);
};

interface ExceptionInterface {
	day: string;
	type: string;
}
interface RotationCalendarRotations {
	id: number;
	onboard: {
		start_date: string;
		end_date: string;
		exceptions: ExceptionInterface[];
	};
}


import { useState } from "react";

import { useAddVesselMutation } from "@/app/api/slices/admin/vesselsApiSlice";
import { VesselUpsertForm } from "@/components/forms/VesselUpsertForm";
import { type FormType } from "@/components/forms/VesselUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const AddVesselDialog = () => {
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addVesselMutation, { isLoading }] = useAddVesselMutation();

	const handleAddVessel = async (data: FormType) => {
		await addVesselMutation({ data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added vessel.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button className="uppercase">Add vessel</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Add Vessel</DialogTitle>
				</DialogHeader>
				<VesselUpsertForm onSubmit={handleAddVessel} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

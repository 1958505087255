import { apiSlice } from "@/app/api/apiSlice";

export const magicLinksApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMagicLinks: builder.query({
			query: () => `/api/magic-links`,
			providesTags: ["MagicLinks"],
		}),
		createMagicLink: builder.mutation({
			query: ({ data }) => ({
				url: `/api/magic-links`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["MagicLinks", "Vessels"]),
		}),
		extendMagicLinkValidity: builder.mutation({
			query: ({ magicLinkId }) => ({
				url: `/api/magic-links/${magicLinkId}/extend-validity`,
				method: "PUT",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["MagicLinks"]),
		}),
		turnOffMagicLink: builder.mutation({
			query: ({ magicLinkId }) => ({
				url: `/api/magic-links/${magicLinkId}/turn-off`,
				method: "PUT",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["MagicLinks"]),
		}),
		reseedDemoAccount: builder.mutation({
			query: ({ magicLinkId }) => ({
				url: `/api/magic-links/${magicLinkId}/reseed`,
				method: "POST",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["MagicLinks"]),
		}),
		removeMagicLink: builder.mutation({
			query: ({ magicLinkId }) => ({
				url: `/api/magic-links/${magicLinkId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, error) => (error ? [] : ["MagicLinks"]),
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetMagicLinksQuery,
	useCreateMagicLinkMutation,
	useExtendMagicLinkValidityMutation,
	useTurnOffMagicLinkMutation,
	useReseedDemoAccountMutation,
	useRemoveMagicLinkMutation,
} = magicLinksApiSlice;

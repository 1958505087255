import { type ColumnDef } from "@tanstack/react-table";

import { SuggestionVoteButtons } from "@/components/SuggestionVoteButtons";
import { type ManagerSuggestion, type SuggestionVotes } from "@/types/Suggestions";

export const columns: ColumnDef<ManagerSuggestion>[] = [
	{
		accessorKey: "publicTitle",
		header: "Title",
		cell: ({ row }) => <div>{row.getValue("publicTitle")}</div>,
	},
	{
		accessorKey: "publicDescription",
		header: "Description",
		cell: ({ row }) => <div>{row.getValue("publicDescription")}</div>,
	},
	{
		accessorKey: "votes",
		header: "Total Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.total || "-"}</div>;
		},
	},
	{
		accessorKey: "votes",
		header: "Up Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.up || "-"}</div>;
		},
	},
	{
		accessorKey: "votes",
		header: "Down Votes",
		cell: ({ row }) => {
			const votes: SuggestionVotes = row.getValue("votes");

			return <div>{votes?.down || "-"}</div>;
		},
	},
	{
		id: "actions",
		header: () => <div>Actions</div>,
		cell: ({ row }) => {
			const suggestionId = row.original.id;

			return <SuggestionVoteButtons suggestionId={suggestionId} />;
		},
	},
];

import { apiSlice } from "@/app/api/apiSlice";

export const knowledgeBaseApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAdminSections: builder.query({
			query: ({ params }) => `/api/knowledge-base/sections${params ? params : ""}`,
			providesTags: ["AdminKnowledgeBaseSections"],
		}),
		addAdminSection: builder.mutation({
			query: ({ name }) => ({
				url: `/api/knowledge-base/sections`,
				method: "POST",
				body: { name },
			}),
			invalidatesTags: ["AdminKnowledgeBaseSections"],
		}),
		updateAdminSection: builder.mutation({
			query: ({ name, sectionId }) => ({
				url: `/api/knowledge-base/sections/${sectionId}`,
				method: "PUT",
				body: { name },
			}),
			invalidatesTags: ["AdminKnowledgeBaseSections"],
		}),
		removeAdminSection: builder.mutation({
			query: ({ sectionId }) => ({
				url: `/api/knowledge-base/sections/${sectionId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["AdminKnowledgeBaseSections"],
		}),
		getAdminArticles: builder.query({
			query: () => `/api/knowledge-base/articles`,
			providesTags: ["AdminKnowledgeBaseArticles"],
		}),
		getAdminArticle: builder.query({
			query: ({ articleId }) => `/api/knowledge-base/articles/${articleId}`,
			providesTags: (_result, _error, { articleId }) => [
				{ type: "AdminKnowledgeBaseArticles", id: articleId },
			],
		}),
		addAdminArticle: builder.mutation({
			query: ({ data }) => ({
				url: `/api/knowledge-base/articles`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["AdminKnowledgeBaseArticles", "AdminKnowledgeBaseSections"],
		}),
		updateAdminArticle: builder.mutation({
			query: ({ data, articleId }) => ({
				url: `/api/knowledge-base/articles/${articleId}`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["AdminKnowledgeBaseArticles", "AdminKnowledgeBaseSections"],
		}),
		removeAdminArticle: builder.mutation({
			query: ({ articleId }) => ({
				url: `/api/knowledge-base/articles/${articleId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["AdminKnowledgeBaseArticles", "AdminKnowledgeBaseSections"],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetAdminSectionsQuery,
	useAddAdminSectionMutation,
	useUpdateAdminSectionMutation,
	useRemoveAdminSectionMutation,
	useGetAdminArticlesQuery,
	useGetAdminArticleQuery,
	useAddAdminArticleMutation,
	useUpdateAdminArticleMutation,
	useRemoveAdminArticleMutation,
} = knowledgeBaseApiSlice;

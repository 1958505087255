import { Link } from "react-router-dom";

import { useForgotPasswordMutation } from "@/app/api/slices/authApiSlice";
import { Logo } from "@/components/Logo";
import { ForgotPasswordForm } from "@/components/forms/ForgotPasswordForm";
import { type FormType } from "@/components/forms/ForgotPasswordForm/formSchema";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const ForgotPasswordPage = () => {
	const { toast } = useToast();
	const [forgotPasswordMutation, { isLoading }] = useForgotPasswordMutation();

	const handleForgotPassword = async ({ email }: FormType) => {
		await forgotPasswordMutation({ email })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title:
						"Check your email for a link to reset your password. The link will expire in 1 hour.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<div className="flex min-h-screen items-center justify-center">
			<Card className="w-[600px]">
				<CardHeader>
					<div className="mb-4 flex justify-center">
						<Logo />
					</div>
					<CardTitle className="text-center">Password Forget</CardTitle>
					<CardDescription className="text-center">
						Please enter the email address you’d like your password reset information sent to.
					</CardDescription>
				</CardHeader>
				<CardContent>
					<ForgotPasswordForm onSubmit={handleForgotPassword} isLoading={isLoading} />
				</CardContent>
				<CardFooter className="flex justify-center">
					<Link to="/login" className="text-sm text-blue-800">
						Back to login
					</Link>
				</CardFooter>
			</Card>
		</div>
	);
};

type Props = {
	positionsStats: { name: string; onboard: number; offboard: number }[];
};

export const DepartmentStatistics = ({ positionsStats }: Props) => {
	return (
		<>
			{positionsStats.map((position) => {
				return (
					<div key={position.name} className="flex justify-between">
						<span>{position.name}</span>
						<span className="vessel-departments-statistics-value">
							{position.onboard}/{position.offboard}
						</span>
					</div>
				);
			})}
		</>
	);
};

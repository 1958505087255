import { useAuth } from "@/hooks/useAuth";
import { AdminEditArticlePage } from "@/pages/admin/AdminEditArticlePage";
import { ManagerEditArticlePage } from "@/pages/manager/ManagerEditArticlePage";

export const EditArticlePage = () => {
	const auth = useAuth();
	const user = auth?.user;
	const isAdmin = user?.role.name === "admin";

	return isAdmin ? <AdminEditArticlePage /> : <ManagerEditArticlePage />;
};

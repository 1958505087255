import OneSignal from "react-onesignal";
import { useNavigate } from "react-router-dom";

import { useLoginMutation } from "@/app/api/slices/authApiSlice";
import { Logo } from "@/components/Logo";
import { LoginForm } from "@/components/forms/LoginForm";
import { type FormType } from "@/components/forms/LoginForm/formSchema";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const AdminLoginPage = () => {
	const navigate = useNavigate();
	const { toast } = useToast();
	const [loginMutation, { isLoading }] = useLoginMutation();

	const handleLogin = async ({ email, password }: FormType) => {
		await loginMutation({ email, password, loginType: "admin" })
			.unwrap()
			.then((payload) => {
				const loggedUserId = String(payload.data.user.id);
				const loggedUserEmail = payload.data.user.email;
				OneSignal.login(loggedUserId);
				OneSignal.User.addEmail(loggedUserEmail);

				navigate("/vessels");
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<div className="flex min-h-screen items-center justify-center">
			<Card className="w-[600px]">
				<CardHeader>
					<div className="mb-4 flex justify-center">
						<Logo />
					</div>
					<CardTitle className="text-center">Admin Login</CardTitle>
				</CardHeader>
				<CardContent>
					<LoginForm onSubmit={handleLogin} isLoading={isLoading} />
				</CardContent>
			</Card>
		</div>
	);
};

import { HelpCircle } from "lucide-react";

import { Button } from "@/components/ui/button";
import { useJoyride } from "@/contexts/JoyrideContext";

export const JoyrideStartButton = () => {
	const { resetJoyride } = useJoyride();

	const handleJoyrideStart = () => {
		resetJoyride();
	};

	return (
		<Button onClick={handleJoyrideStart} size="icon" className="relative hidden xl:inline-flex">
			<HelpCircle />
		</Button>
	);
};

import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { selectAuthStatus } from "@/app/slices/authSlice";
import { FullscreenLoader } from "@/components/FullscreenLoader";
import { useAuth } from "@/hooks/useAuth";

export function ProtectedOutlet() {
	const auth = useAuth();
	const location = useLocation();
	const authStatus = useSelector(selectAuthStatus);

	if (authStatus === "idle" || authStatus === "loading") {
		return <FullscreenLoader />;
	}

	const user = auth?.user;
	const isAuth = user?.role.name === "manager" || user?.role.name === "admin";

	return user && isAuth ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
}

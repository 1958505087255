import * as React from "react";
import * as ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";

import { store } from "@/app/store.ts";
import { JoyrideProvider } from "@/contexts/JoyrideContext.tsx";
import { NotificationBarProvider } from "@/contexts/NotificationBarContext.tsx";
import { initSentry } from "@/lib/sentry.ts";

import { App } from "./App.tsx";
import "./index.css";

const tagManagerArgs = {
	gtmId: import.meta.env.VITE_GTM_ID,
};

if (
	import.meta.env.PROD &&
	(!import.meta.env.VITE_IS_TEST || import.meta.env.VITE_IS_TEST === "false")
) {
	TagManager.initialize(tagManagerArgs);
}

initSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<Provider store={store}>
			<JoyrideProvider>
				<NotificationBarProvider>
					<App />
				</NotificationBarProvider>
			</JoyrideProvider>
		</Provider>
	</React.StrictMode>,
);

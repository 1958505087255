import { useState } from "react";
import { useParams } from "react-router-dom";

import { useAddManagerMutation } from "@/app/api/slices/admin/managersApiSlice";
import { UserUpsertForm } from "@/components/forms/UserUpsertForm";
import { type FormType } from "@/components/forms/UserUpsertForm/formSchema";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const AddManagerDialog = () => {
	const { vesselId } = useParams();
	const { toast } = useToast();
	const [open, setOpen] = useState(false);
	const [addManagerMutation, { isLoading }] = useAddManagerMutation();

	const handleAddManager = async (data: FormType) => {
		await addManagerMutation({ vesselId, data })
			.unwrap()
			.then((_payload) => {
				setOpen(false);
				toast({
					variant: "success",
					title: "Successfully added manager.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button className="uppercase">Add manager</Button>
			</DialogTrigger>
			<DialogContent className="max-h-full sm:max-w-[500px]">
				<DialogHeader>
					<DialogTitle>Add Manager</DialogTitle>
				</DialogHeader>
				<UserUpsertForm hardcodedRole="manager" onSubmit={handleAddManager} isLoading={isLoading} />
			</DialogContent>
		</Dialog>
	);
};

import notFoundIllustration from "@/assets/not-found.svg";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/hooks/useAuth";
import { Link, useRouteError } from "react-router-dom";

type RouteError = {
	status?: number;
	statusText?: string;
	message?: string;
};

export const ErrorPage = () => {
	const auth = useAuth();
	const role = auth?.user?.role.name;
	const error = useRouteError() as RouteError;

	console.error("NotFoundPage -> Error occurred:", error);

	return (
		<div className="magic-pattern flex h-full items-center justify-center bg-ground">
			<div className="mb-6 w-full max-w-screen-lg p-8 text-center">
				<div className="flex items-center justify-center ">
					<img src={notFoundIllustration} alt="Not Found Illustration" className="w-full" />
				</div>
				<div className="mx-auto mt-6 rounded-lg bg-white p-6 text-center">
					{error?.status === 404 ? (
						<>
							<h1 className="text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl ">
								Oops! Page not found.
							</h1>
							<p className="mb-4 mt-2 text-lg text-gray-600">
								We can&apos;t seem to find the page you&apos;re looking for.
							</p>
						</>
					) : (
						<>
							{error?.statusText && (
								<h1 className="text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl ">
									{error.statusText}
								</h1>
							)}
							{error?.message && <p className="mb-4 mt-2 text-lg text-gray-600">{error.message}</p>}
						</>
					)}
					{!role && (
						<Button asChild>
							<Link to="/login">Go back to login</Link>
						</Button>
					)}
					{role === "manager" && (
						<Button asChild>
							<Link to="/">Go back to dashboard</Link>
						</Button>
					)}
					{role === "admin" && (
						<Button asChild>
							<Link to="/vessels">Go back to vessels</Link>
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

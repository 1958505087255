import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ChevronsUpDown } from "lucide-react";

import positionCreatorChangeDayType from "@/assets/joyride/position-change-day-type-v2.png";

export const positionsSteps = [
	// 15 - POSITIONS
	{
		target: "#position-calendar-page",
		content: (
			<div>
				<h1 className="text-xl font-semibold">Welcome to the position calendar.</h1>
				<p>
					This interactive calendar allows to view the current and upcoming rotations and introduce
					manual changes, ensuring you and your crew members are always up to date. Any manual
					changes will be automatically updated in the mobile app.
				</p>
			</div>
		),
		disableBeacon: true,
		placement: "center",
	},
	// 16 - POSITIONS
	{
		target: "#position-calendar-department-select",
		content: (
			<div>
				<p>You can select department here.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 17 - POSITIONS
	{
		target: "#position-calendar-position-select",
		content: (
			<div>
				<p>Once department is selected you&apos;ll be able to choose the position.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 18 - POSITIONS
	{
		target: "#position-calendar-report",
		content: (
			<div>
				<p>You will be able to download the report here.</p>
			</div>
		),
		disableBeacon: true,
		placement: "auto",
	},
	// 19 - POSITIONS
	{
		target: "#position-calendar-page",
		content: (
			<div>
				<p>
					Once position is selected you&apos;ll be able to expand the calendar to see all weeks in
					the rotation.
				</p>
				<div className="mt-2 rounded-lg bg-gray-300 p-2">
					<Collapsible open={false} className="space-y-2">
						<div className={`flex items-center justify-between space-x-4 rounded-md bg-white`}>
							<CollapsibleTrigger asChild>
								<Button
									variant="ghost"
									size="sm"
									className="flex w-full cursor-default items-center justify-center p-0"
								>
									<ChevronsUpDown className="h-4 w-4" />
									<span className="sr-only">Toggle</span>
								</Button>
							</CollapsibleTrigger>
						</div>
						<CollapsibleContent className="space-y-2"></CollapsibleContent>
					</Collapsible>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 20 - POSITIONS
	{
		target: "#position-calendar-page",
		content: (
			<div>
				<p>Go to the next/previous rotation, number of the rotation on one view.</p>
				<div className="mt-4 flex flex-col items-start space-y-2">
					<div>
						<Button>Previous</Button> &mdash; Go to the previous rotation
					</div>
					<div>
						<Button>Next</Button> &mdash; Go to the next rotation
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 21 - POSITIONS
	{
		target: "#position-calendar-page",
		content: (
			<div>
				<p>
					Use these buttons to introduce manual changes of the crew member „change day” in the
					calendar.
				</p>
				<p>You simply have to press on the button and assign a new date from the calendar.</p>
				<p>Change day is the day when a crew member is either going home or joining the boat.</p>
				<div className="mt-4 flex items-center justify-center gap-2">
					<div>
						<Button className="cursor-default">1 May</Button>
					</div>
					<div>
						<Button className="cursor-default">21 Aug</Button>
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 22 - POSITIONS
	{
		target: "#position-calendar-page",
		content: (
			<div>
				<p>Colour code for specific days.</p>
				<div className="mt-4">
					<div className="flex grow justify-center">
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-blue-200"></div>
							<label className="text-nowrap text-xs ">Onboard</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-gray-300"></div>
							<label className="text-nowrap text-xs ">Travel</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-green-300"></div>
							<label className="text-nowrap text-xs ">Change</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-pink-400"></div>
							<label className="text-nowrap text-xs ">Rotational Leave</label>
						</div>
						<div className="justify-content me-3 flex items-center">
							<div className="me-1 h-4 w-4 rounded-md border bg-yellow-200"></div>
							<label className="text-nowrap text-xs ">Other day</label>
						</div>
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 23 - POSITIONS
	{
		target: "#position-calendar-page",
		content: (
			<div>
				<p>You&apos;ll be able to click on the day to change it&apos;s kind.</p>
				<p>
					The letters you see are the crew&apos;s initials, once hovered you&apos;ll see full info.
				</p>
				<div className="mx-auto mt-4 w-36 rounded-lg bg-gray-300 p-2">
					<div className="flex cursor-default flex-col items-center justify-center rounded-md border bg-white font-medium">
						6
						<div className="min-h-4 w-full border-t bg-blue-200">
							<div className="text-center text-xs">AP (4)</div>
						</div>
						<div className="min-h-4 w-full border-t bg-gray-100">
							<div className="text-center text-xs">DH (5)</div>
						</div>
					</div>
				</div>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
	// 24 - POSITIONS
	{
		target: "#position-calendar-page",
		content: (
			<div>
				<p>Once clicked on a day, you&apos;ll see dialog like this.</p>
				<p>Here you can change the day type, choose a time frame and add related documents.</p>
				<div className="flex justify-center py-4">
					<img className="rounded-lg" src={positionCreatorChangeDayType} alt="" />
				</div>
				<p className="text-left">
					<b>End Date</b> - choose this date if you want to pick more then one day
				</p>
				<p className="text-left">
					<b>Day Kind</b> - choose day type from the dropdown list
				</p>
				<p className="text-left">
					<b>Crew Member</b> - choose crew member from the dropdown list
				</p>
			</div>
		),
		disableBeacon: false,
		placement: "center",
	},
];

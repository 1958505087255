import { useParams } from "react-router-dom";

import {
	useGetVesselBrandingQuery,
	useUpdateVesselBrandingMutation,
} from "@/app/api/slices/admin/vesselBrandingApiSlice";
import { InLayoutLoader } from "@/components/InLayoutLoader";
import { WhiteBox } from "@/components/WhiteBox";
import { BrandingForm } from "@/components/forms/BrandingForm";
import { type FormType } from "@/components/forms/BrandingForm/formSchema";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

export const VesselBrandingPage = () => {
	const { toast } = useToast();
	const { vesselId } = useParams();
	const [updateBrandingMutation, { isLoading: isUpdating }] = useUpdateVesselBrandingMutation();
	const {
		data: brandingResponse,
		isLoading: isLoading,
		isFetching: isFetching,
	} = useGetVesselBrandingQuery({ vesselId });

	const handleBrandingUpdate = async (data: FormType) => {
		const formData = new FormData();

		for (const item in data) {
			// @ts-expect-error to-do: fix this
			const value = data[item];

			if (!value) continue;

			formData.append(item, value);
		}

		await updateBrandingMutation({ vesselId, data: formData })
			.unwrap()
			.then((_payload) => {
				toast({
					variant: "success",
					title: "Successfully updated branding.",
				});
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	if (isLoading || isFetching) return <InLayoutLoader />;

	return (
		<WhiteBox className="mt-4">
			<h1 className="mb-4 text-xl font-semibold md:text-2xl">Vessel Branding</h1>
			<BrandingForm
				onSubmit={handleBrandingUpdate}
				isLoading={isUpdating}
				defaultValues={{
					meta: brandingResponse.data?.meta,
					domain: brandingResponse.data?.domain,
					backgroundColor: brandingResponse.data?.backgroundColor,
					topBarColor: brandingResponse.data?.topBarColor,
					buttonColor: brandingResponse.data?.buttonColor,
					logo: null,
				}}
				logoUrl={brandingResponse.data?.logoUrl}
			/>
		</WhiteBox>
	);
};

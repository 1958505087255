import OneSignal from "react-onesignal";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useLoginMutation, useSetPasswordMutation } from "@/app/api/slices/authApiSlice";
import { Logo } from "@/components/Logo";
import { SetPasswordForm } from "@/components/forms/SetPasswordForm";
import { type FormType } from "@/components/forms/SetPasswordForm/formSchema";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { handleServerErrors } from "@/utils/handleServerErrors";

const WILL_LOGIN =
	"Your password has been set successfully. You will now be logged in with your new password.";
const WILL_NOT_LOGIN =
	"Your password has been set successfully. You can now log in to your account.";

export const SetPasswordPage = () => {
	const { toast } = useToast();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [loginMutation, { isLoading: isLoginLoading }] = useLoginMutation();
	const [setPasswordMutation, { isLoading }] = useSetPasswordMutation();

	const token = searchParams.get("token");
	const email = searchParams.get("email");

	const handleSetPassword = async ({
		password,
		confirmPassword,
		regulationsAcceptance,
	}: FormType) => {
		await setPasswordMutation({ token, email, password, confirmPassword, regulationsAcceptance })
			.unwrap()
			.then(async (payload) => {
				const user = payload?.data?.user;
				const userRole = user?.role?.name;

				toast({
					variant: "success",
					title: userRole === "manager" || userRole === "admin" ? WILL_LOGIN : WILL_NOT_LOGIN,
				});

				if (user && userRole && (userRole === "manager" || userRole === "admin")) {
					await loginMutation({ email, password, loginType: userRole })
						.unwrap()
						.then((payload) => {
							const loggedUserId = String(payload.data.user.id);
							const loggedUserEmail = payload.data.user.email;
							OneSignal.login(loggedUserId);
							OneSignal.User.addEmail(loggedUserEmail);

							navigate("/");
						})
						.catch((error) => handleServerErrors(error, toast));
				}
			})
			.catch((error) => handleServerErrors(error, toast));
	};

	return (
		<div className="flex min-h-screen items-center justify-center">
			<Card className="w-[600px]">
				<CardHeader>
					<div className="mb-4 flex justify-center">
						<Logo />
					</div>
					<CardTitle className="text-center">Set Password</CardTitle>
					<CardDescription className="text-center">
						Please enter a password below to secure your account.
					</CardDescription>
				</CardHeader>
				<CardContent>
					<SetPasswordForm onSubmit={handleSetPassword} isLoading={isLoading || isLoginLoading} />
				</CardContent>
			</Card>
		</div>
	);
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useGetSubscriptionTiersQuery } from "@/app/api/slices/admin/subscriptionsApiSlice";
import { ButtonLoader } from "@/components/ButtonLoader";
import { Button } from "@/components/ui/button";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { type FormProps } from "@/types/Form";

import { Skeleton } from "@/components/ui/skeleton";
import { type SubscriptionTier } from "@/types/Subscription";
import { formSchema, type FormType } from "./formSchema";

export const ManagerPaymentDetailsForm = ({
	onSubmit,
	isLoading,
	defaultValues = {
		// isActive: "",
		subscriptionTierId: "",
		// crewMembersLimit: 0,
		// paymentUntil: "",
	},
}: FormProps<FormType>) => {
	const { data: response, isLoading: isLoadingSubscriptionTiers } = useGetSubscriptionTiersQuery(
		{},
	);

	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
		defaultValues: defaultValues,
	});
	const { handleSubmit, control } = form;

	return (
		<Form {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid w-full items-center gap-4">
					{/* <div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="isActive"
							render={({ field }) => (
								<FormItem>
									<div className="grid items-center grid-cols-1 gap-3 sm:grid-cols-2">
										<FormLabel className="whitespace-nowrap">Active</FormLabel>
										<FormControl>
											<Select onValueChange={field.onChange} defaultValue={field.value}>
												<SelectTrigger>
													<SelectValue placeholder="Select Active" />
												</SelectTrigger>
												<SelectContent>
													<SelectGroup>
														<SelectItem value="yes">Yes</SelectItem>
														<SelectItem value="no">No</SelectItem>
													</SelectGroup>
												</SelectContent>
											</Select>
										</FormControl>
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div> */}
					<div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="subscriptionTierId"
							render={({ field }) => (
								<FormItem>
									<div className="grid grid-cols-1 items-center gap-3 sm:grid-cols-2">
										<div className="flex items-center justify-start gap-2">
											<FormLabel className="whitespace-nowrap">Subscription Tier</FormLabel>
											<FormMessage />
										</div>
										<FormControl>
											{isLoadingSubscriptionTiers ? (
												<Skeleton className="h-10 w-full rounded-md" />
											) : (
												<Select onValueChange={field.onChange} defaultValue={field.value}>
													<SelectTrigger>
														<SelectValue placeholder="Select Tier" />
													</SelectTrigger>
													<SelectContent>
														<SelectGroup>
															{response?.data?.map((tier: SubscriptionTier) => {
																return (
																	<SelectItem key={tier.type} value={String(tier.id)}>
																		{tier.type}
																	</SelectItem>
																);
															})}
														</SelectGroup>
													</SelectContent>
												</Select>
											)}
										</FormControl>
									</div>
								</FormItem>
							)}
						/>
					</div>
					{/* <div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="crewMembersLimit"
							render={({ field }) => (
								<FormItem>
									<div className="grid items-center grid-cols-1 gap-3 sm:grid-cols-2">
										<FormLabel className="flex flex-col gap-1">
											<span className="whitespace-nowrap">Crew Members Limit</span>
											<span className="text-xs text-gray-500">
												Crew members limit can be manually adjusted. It will overwrite selected
												subscription tier limit.
											</span>
										</FormLabel>
										<FormControl>
											<Input placeholder="" type="number" {...field} />
										</FormControl>
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div> */}
					{/* <div className="flex flex-col space-y-1.5">
						<FormField
							control={control}
							name="paymentUntil"
							render={({ field }) => (
								<FormItem>
									<div className="grid items-center grid-cols-1 gap-3 sm:grid-cols-2">
										<FormLabel className="flex flex-col gap-1">
											<span className="whitespace-nowrap">Payment until</span>
											<span className="text-xs text-gray-500">Day of month.</span>
										</FormLabel>
										<FormControl>
											<Input placeholder="" type="number" {...field} />
										</FormControl>
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div> */}
					<div className="flex justify-end">
						<Button type="submit" disabled={isLoading}>
							<ButtonLoader isLoading={isLoading} />
							Save
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};

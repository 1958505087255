import { MoveRight } from "lucide-react";

type Props = {
	day: string;
};

export const MonthChange = ({day}: Props) => {
    const clickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        e.preventDefault();
        e.stopPropagation();
    }

	return (
        <>
            { (day === "1") && (
                <div className="ctrl__cell-day-change absolute -left-3 md:-left-4 top-1/2 -translate-y-1/2" onClick={clickHandler}>
                    <MoveRight className="w-4 l-4 md:w-6 md:h-6" />
                </div>
            )}		
        </>	
	);
};

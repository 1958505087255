import { useConfig } from "@/hooks/useConfig";
import { useEffect } from "react";

export function useBranding() {
	const { brandingData } = useConfig();

	useEffect(() => {
		// Retrieve stored values
		const storedBackground = localStorage.getItem("background-color");
		const storedTopBar = localStorage.getItem("top-bar-color");
		const storedButton = localStorage.getItem("button-color");
		const storedMeta = localStorage.getItem("meta-title");

		// set background color from storage
		if (storedBackground) {
			document.documentElement.style.setProperty("--main-background", storedBackground);
		}
		// set top bar color from storage
		if (storedTopBar) {
			document.documentElement.style.setProperty("--topBar", storedTopBar);
		}
		// set top bar color from storage
		if (storedButton) {
			document.documentElement.style.setProperty("--primary", storedButton);
		}
		// set meta title from storage
		if (storedMeta) {
			document.title = storedMeta;
		}

		// set background color from config and overwrite storage
		if (brandingData?.backgroundColor) {
			const backgroundColorValue = extractRGBAasString(brandingData.backgroundColor);
			document.documentElement.style.setProperty("--main-background", backgroundColorValue);
			localStorage.setItem("background-color", backgroundColorValue);
		}
		// set top bar color from config and overwrite storage
		if (brandingData?.topBarColor) {
			const topBarColorValue = extractRGBAasString(brandingData.topBarColor);
			document.documentElement.style.setProperty("--topBar", topBarColorValue);
			localStorage.setItem("top-bar-color", topBarColorValue);
		}
		// set buttons color from config and overwrite storage
		if (brandingData?.buttonColor) {
			const buttonColorValue = extractRGBAasString(brandingData.buttonColor);
			document.documentElement.style.setProperty("--primary", buttonColorValue);
			localStorage.setItem("button-color", buttonColorValue);
		}
		// set meta title from config and overwrite storage
		if (brandingData?.meta) {
			document.title = brandingData.meta;
			localStorage.setItem("meta-title", brandingData.meta);
		}

		// remove stored values if branding data is null
		if (brandingData === null && (storedBackground || storedTopBar || storedButton || storedMeta)) {
			document.documentElement.style.removeProperty("--main-background");
			document.documentElement.style.removeProperty("--topBar");
			document.documentElement.style.removeProperty("--primary");
			document.title = "Rotat Yachts";

			localStorage.removeItem("background-color");
			localStorage.removeItem("top-bar-color");
			localStorage.removeItem("button-color");
			localStorage.removeItem("meta-title");
		}
	}, [brandingData]);
}

function extractRGBAasString(rgbaString: string) {
	const regex = /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d+)?\)/;
	const matches = rgbaString.match(regex);
	if (matches) {
		const [, r, g, b] = matches;
		return `${r}, ${g}, ${b}`;
	}
	return "";
}

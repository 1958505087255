import * as z from "zod";

// USERS
export const emailZodRule = z
	.string()
	.min(5, { message: "Email must contain at least 5 characters" })
	.email({ message: "Invalid email address" });

export const phoneZodRule = z.string().min(6, { message: "Phone must contain at least 6 numbers" });

export const passwordZodRule = z
	.string()
	.min(6, { message: "Password must contain at least 6 characters" });

export const firstNameZodRule = z
	.string()
	.min(1, { message: "First name must contain at least 1 character" });

export const lastNameZodRule = z
	.string()
	.min(1, { message: "Last name must contain at least 1 character" });

// VESSELS
export const vesselNameZodRule = z
	.string()
	.min(3, { message: "Vessel name must contain at least 3 characters" });

export const vesselImoZodRule = z
	.string()
	.min(3, { message: "Vessel IMO must contain at least 3 characters" });

// DOCUMENTS
export const docTitleZodRule = z
	.string()
	.min(3, { message: "Title must contain at least 3 characters" });

export const docDescriptionZodRule = z
	.string()
	.min(3, { message: "Description must contain at least 3 characters" });

// DEPARTMENTS
export const departmentNameZodRule = z
	.string()
	.min(3, { message: "Department name must contain at least 3 characters" });

// POSITIONS
export const positionNameZodRule = z
	.string()
	.min(3, { message: "Position name must contain at least 3 characters" });

// KNOWLEDGE BASE
export const kbTitleZodRule = z
	.string()
	.min(3, { message: "Title must contain at least 3 characters" });

export const kbDescriptionZodRule = z
	.string()
	.min(3, { message: "Description must contain at least 3 characters" });

export const kbSectionZodRule = z.string().min(1, { message: "Section is required" });

export const kbContentZodRule = z.string().min(1, { message: "Content is required" });

// KNOWLEDGE BASE SECTIONS
export const kbSectionNameZodRule = z
	.string()
	.min(3, { message: "Section name must contain at least 3 characters" });

// SUGGESTIONS
export const suggestionTitleZodRule = z
	.string()
	.min(3, { message: "Title must contain at least 3 characters" });

export const suggestionDescriptionZodRule = z
	.string()
	.min(3, { message: "Description must contain at least 3 characters" });

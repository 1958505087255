/* eslint-disable @typescript-eslint/no-explicit-any */
export function handleServerErrors(error: any, toast: any) {
	if (error?.data?.errors) {
		Object.values(error.data.errors).forEach((err) => {
			// @ts-expect-error to-do
			err.forEach((e) => {
				toast({
					variant: "destructive",
					title: e,
				});
			});
		});
		return;
	}

	toast({
		variant: "destructive",
		title: error?.data?.message,
	});
}

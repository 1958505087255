import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { AdminKnowledgeBasePage } from "@/pages/admin/AdminKnowledgeBasePage";
import { ManagerKnowledgeBasePage } from "@/pages/manager/ManagerKnowledgeBasePage";

export const KnowledgeBasePage = () => {
	const { state } = useLocation();
	const { toast } = useToast();
	const auth = useAuth();

	useEffect(() => {
		if (state?.toast) {
			toast(state.toast);
		}
	}, [state, toast]);

	const user = auth?.user;
	const isAdmin = user?.role.name === "admin";

	return isAdmin ? <AdminKnowledgeBasePage /> : <ManagerKnowledgeBasePage />;
};

import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "@/app/api/apiSlice";
import authReducer from "@/app/slices/authSlice";
import configReducer from "@/app/slices/configSlice";

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		auth: authReducer,
		config: configReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
	devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { useEffect } from "react";
import OneSignal from "react-onesignal";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { useGetAppConfigQuery } from "@/app/api/slices/appConfigApiSlice";
import { authApiSlice } from "@/app/api/slices/authApiSlice";
import { FullscreenLoader } from "@/components/FullscreenLoader";
import { useBranding } from "@/hooks/useBranding";
import { runOneSignal } from "@/lib/oneSignal";
import { router } from "@/lib/router";

type PayloadType = { isSuccess: boolean; data?: { data: { id: number; email: string } } };

export function App() {
	useBranding();

	const { isLoading: isLoadingConfig } = useGetAppConfigQuery({});
	const dispatch = useDispatch();

	useEffect(() => {
		runOneSignal();
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		dispatch(authApiSlice.endpoints.getCurrentUser.initiate({}) as any).then(
			(payload: PayloadType) => {
				if (payload.isSuccess === true && payload.data) {
					const loggedUserId = String(payload.data.data.id);
					const loggedUserEmail = payload.data.data.email;
					OneSignal.login(loggedUserId);
					OneSignal.User.addEmail(loggedUserEmail);
				}
			},
		);
	}, [dispatch]);

	useEffect(() => {
		if (import.meta.env.VITE_IS_TEST) {
			const existingMetaTag = document.querySelector('meta[name="robots"]');

			if (!existingMetaTag) {
				// Create a meta element
				const metaTag = document.createElement("meta");
				metaTag.setAttribute("name", "robots");
				metaTag.setAttribute("content", "noindex");

				// Append the meta element to the head of the document
				document.head.appendChild(metaTag);
			}
		}
	}, []);

	if (isLoadingConfig) {
		return <FullscreenLoader />;
	}

	return (
		<>
			<RouterProvider router={router} />
		</>
	);
}
